import { FormFieldSetting } from "../form-field/formfield-setting.model";
import { JoinCondition } from "../join-condition/join-condition.model";
import { Mall } from "../mall/mall.model";
import { Award } from "../models/award";
import { GameSetting } from "../models/game-setting";
import { PriceRange } from "../models/price-range";
import { User } from "../user/user.model";

export class LoyaltyCampaignLinked {
    id: number;
    name: string;
    code: string;
    status: boolean;
    sequence: number;

}

export class Campaign {

    id: number;
    name: string;
    code: string;;
    description: string;
    isLoyaltyGame: boolean;
    loyaltyGameExchangeEnable: boolean;
    priceRanges: PriceRange[];
    awards: Award[];
    fromDate: Date;
    toDate: Date;
    status: boolean;
    users: User[];
    malls: Mall[];


    inputFields: FormFieldSetting[];
    joinConditions: JoinCondition[];
    gameSetting: GameSetting;
    awardLimitEnable: boolean;
    awardLimitNumber: number;
    awardLimitByField: string;
    awardLimitPerDay: boolean;
    awardLimitOnCampaign: boolean;
    gameAwardReceiveInGame: boolean;
    gameCollectProfileOnReceive: boolean;

    isGame: boolean;
    gameType: string;
    gameCollectProfile: boolean;

    constructor() {
        this.id = null;
        this.name = "";
        this.description = "";
        this.priceRanges = [];
        this.joinConditions = [];
        this.awards = [];
        this.fromDate = new Date();
        this.toDate = new Date();
        this.status = true;
        this.users = [];
        this.malls = [];
        this.inputFields = [];
        this.awardLimitEnable = false;
        this.awardLimitNumber = 0;
        this.awardLimitPerDay = false;
        this.awardLimitOnCampaign = false;
        this.gameSetting = new GameSetting();
        this.awardLimitByField = 'phone';
        this.gameType = '';
    }
}