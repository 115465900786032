import { apiService } from '../api/api.service';
import { FormFieldSetting } from './formfield-setting.model';
import { FormField } from './formfield.model';
class FormFieldService {
    async getData(filters): Promise<FormField[]> {
        let result = await apiService.get("form-field", { ...filters });
        if (!apiService.generalProcessDataResponse(result)) return [];
        return result.data;
    }

    compareFormField(ff1: FormField | FormFieldSetting, ff2: FormField | FormFieldSetting) {
        return ff1.id - ff2.id
    }
}
export default new FormFieldService();