import { AwardMallSetting } from "./award-mall-setting";

export enum AWardType {
    VOUCHER = "VOUCHER", ITEM = "ITEM"
}
export class Award {
    itempId: number;
    id: number;
    name: string;
    value: string;
    type: AWardType;
    isLoyaltyStamp: boolean;
    prefix: string;
    awardLimitNumber: number;
    awardLimitPerDay: boolean;
    awardLimitOnCampaign: boolean;
    awardLimitNumberInCampaign: number;
    status: boolean;
    image: string;
    settings: AwardMallSetting[] = []
    constructor() {
        this.itempId = Math.random() * 1000000;

        this.id = null;
        this.name = "";
        this.value = "";
        this.awardLimitNumber = 0;
        this.awardLimitNumberInCampaign = 0;
        this.awardLimitPerDay = false;
        this.type = AWardType.VOUCHER;
        this.isLoyaltyStamp = false;
        this.prefix = "";
        this.awardLimitOnCampaign = false;
        this.status = true;
        this.settings = [];
    }

}