import { Button, Card, Form, Input, Modal } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import React from 'react';
import { Mall } from '~/services/mall/mall.model';


export interface IFormModalProps extends FormComponentProps {
    visible: boolean;
    // closeCallback: () => void;
    onCancel: () => void;
    onCreate: () => void;
    mall: Mall;
}

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};

class MallFormModal extends React.Component<IFormModalProps> {
    formRef: any;
    state = {
        loading: false,
        mall: new Mall()
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (err) return;
            this.props.onCreate();
            //this.props.closeCallback();
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        let isCreateNew = !(this.props.mall && this.props.mall.id);
        let title = (!isCreateNew ? "Cập nhật chi nhánh" : "Tạo chi nhánh").toUpperCase();
        return (
            <Modal width="800px" onOk={this.props.onCreate} footer={null} onCancel={this.props.onCancel} visible={this.props.visible}  >
                <div >
                    <Card title={title} bordered={true} style={{ margin: "20px" }}>
                        <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                            <Form.Item label="Tên chi nhánh">
                                {getFieldDecorator('name', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Nhập tên chi nhánh',
                                        },
                                    ],
                                })(<Input />)}
                            </Form.Item>
                            <Form.Item label="Địa chỉ">
                                {getFieldDecorator('address', {})(<Input autoComplete="false" />)}
                            </Form.Item>
                            <Form.Item label="Mô tả">
                                {getFieldDecorator('description', {})(<Input autoComplete="false" />)}
                            </Form.Item>
                    
                            <Form.Item {...tailFormItemLayout}>
                                <Button type="primary" htmlType="submit">
                                    {title}
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </div >
            </Modal>
        );
    }
}

const MallForm = Form.create<IFormModalProps>({
    onFieldsChange(props, changedFields) {
        // props.onChange(changedFields);
    },
    mapPropsToFields(props) {
        const { mall } = props;
        const result = {};
        Object.keys(mall).forEach(key => {
            const field = Form.createFormField({
                value: mall[key]
            })
            Object.assign(result, {
                [key]: field
            })
        })
        return result;
    },
    onValuesChange(_, values) {
    }
})(MallFormModal);
export default MallForm;