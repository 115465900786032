import React, { Component } from 'react';
import { Button, Card, Divider, Form, Input, Row, Switch } from "antd"
import { Col } from "antd/es/grid"
import { useEffect, useState } from "react"
import UploadFile from "~/components/upload-file"
import { LoyaltyGameFrame } from "~/services/loyalty-game/loyalty-game.model"
import "./style.css";

export interface LoyaltyGameFrameComponentProps {
    frame: LoyaltyGameFrame,
    setFrame: (frame: LoyaltyGameFrame, index: number) => void,
    index: number,
    deleteByIndex: (index: number) => void
}
export const LoyaltyGameFrameComponent = (props: LoyaltyGameFrameComponentProps) => {
    const [frame, setFrame] = useState<LoyaltyGameFrame>(new LoyaltyGameFrame());
    useEffect(() => {
        setFrame(props.frame);
    }, [props.frame]);

    return <>
        <Row style={{border: "solid 1px #CCC", borderRadius: "3px", marginBottom: "5px"}}>
            <Col span={6} style={{ padding: "10px", }}>
                <UploadFile showUploadList={false} multiple={false} uploadCallback={(fullUrl) => {
                    frame.url = fullUrl;
                    setFrame({...frame} as LoyaltyGameFrame);
                }} ></UploadFile>
                <img src={frame.url} alt="" width={100} height={100} />
            </Col>
            <Col span={6} style={{ padding: "10px", verticalAlign: "middle" }}>
                <Form.Item label="Thứ tự hiển thị" style={{lineHeight: "inherit"}}>
                    <Input type="number" value={frame.display_index} onChange={(event) => {
                        frame.display_index = Number(event.target.value);
                        setFrame({...frame} as LoyaltyGameFrame);
                        props.setFrame({...frame} as LoyaltyGameFrame, props.index );
                    }} />
                </Form.Item>
                <Form.Item label="Hiển thị" style={{lineHeight: "inherit"}}>
                    <Switch checked={frame.status} onChange={(checked) => {
                        frame.status = checked;
                        setFrame({...frame} as LoyaltyGameFrame);
                        props.setFrame({...frame} as LoyaltyGameFrame, props.index );
                    } } />
                </Form.Item>
            </Col>
            <Col span={6} style={{ padding: "10px", verticalAlign: "middle" }}>
            </Col>
            <Button style={{position: "absolute", right: "10px", top: "10px"}}
                onClick={() => props.deleteByIndex(props.index)}
            > Xóa </Button>
        </Row>
    </>
}