import { ajax, AjaxResponse, AjaxRequest } from 'rxjs/ajax'
import { Observable, Subscriber } from 'rxjs'

export interface IHeader {
    [key: string]: string
}
export interface IRequestOptions {
    user?: string;
    async?: boolean;
    headers?: IHeader,
    timeout?: number;
    password?: string;
    hasContent?: boolean;
    crossDomain?: boolean;
    withCredentials?: boolean;
    createXHR?: () => XMLHttpRequest;
    progressSubscriber?: Subscriber<any>;
    responseType?: string;

}
export interface IRequest extends IRequestOptions {
    method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
    url?: string;
    body?: any;
}
export interface IResponse<T extends any> extends AjaxResponse {
    response: T
}
export class HttpClient {
    private baseUrl: string
    private options: IRequestOptions
    constructor(baseurl: string, options: IRequestOptions) {
        this.baseUrl = baseurl
        this.options = options
    }
    get<T>(url: string, body?: any ): Observable<IResponse<T>> {
        const options: IRequest = {
            ...this.options,
            method: 'GET',
            url: (this.baseUrl + url),
            body: body
        }
        return ajax(options)
    }
    post<T>(url: string, body?: any): Observable<IResponse<T>> {
        const options: IRequest = {
            ...this.options,
            method: 'POST',
            url: (this.baseUrl + url),
            body: body
        }
        return ajax(options)
    }
    put<T>(url: string, body?: any): Observable<IResponse<T>> {
        const options: IRequest = {
            ...this.options,
            method: 'PUT',
            url: (this.baseUrl + url),
            body: body
        }
        return ajax(options)
    }
    patch<T>(url: string, body?: any): Observable<IResponse<T>> {
        const options: IRequest = {
            ...this.options,
            method: 'PATCH',
            url: (this.baseUrl + url),
            body: body
        }
        return ajax(options)
    }
    delete<T>(url: string, body?: any): Observable<IResponse<T>> {
        const options: IRequest = {
            ...this.options,
            method: 'DELETE',
            url: (this.baseUrl + url),
            body: body
        }
        return ajax(options)
    }
    getJSON<T>(url: string, headers?: Object): Observable<T> {
        return ajax.getJSON(this.get + url, headers || this.options.headers);
    }
}

