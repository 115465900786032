import React, { useCallback, useRef } from "react";
import { Divider } from 'antd';
import QRCode from "react-qr-code";
import useGlobalContext from '~/services/global-context';
// import { ColorPickerComponent } from '@syncfusion/ej2-react-inputs';
export interface IGameInfotabProps {
    code: string;
    name: string;
    gameType: string;
}
const formItemLayout = {
    labelCol: {
        // xs: { span: 24 },
        sm: { span: 12 },
        lg: { span: 12 },
    },
    wrapperCol: {
        // xs: { span: 24 },
        sm: { span: 12 },
        lg: { span: 12 },
    },
};

// gameBackground = "";
// resultBackground = "";
// gameBackgroundColor = "";
// resultBackgroundColor = "";

// areaSettings: AreaSetting[];
// }

function downloadBlob(blob, filename) {
    const objectUrl = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = objectUrl;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setTimeout(() => URL.revokeObjectURL(objectUrl), 5000);
}

export const GameInfoTab = (props: IGameInfotabProps) => {

    let svgRef: any = useRef();
    let svgLinkGameRef: any = useRef();
    const { gameConfig } = useGlobalContext();
    const downloadSVGQRScan = useCallback(() => {
        try {
            if (!svgRef) svgRef = {}
            const svg = svgRef && svgRef.current && svgRef.current["innerHTML"] || "";
            const blob = new Blob([svg], { type: "image/svg+xml" });
            downloadBlob(blob, `${props.name}-QR-SCAN.svg`);
        } catch (error) {

        }
    }, [props]);
    const downloadSVGQRLinkGame = useCallback(() => {
        try {
            if (!svgLinkGameRef) svgLinkGameRef = {}
            const svg = svgLinkGameRef && svgLinkGameRef.current && svgLinkGameRef.current["innerHTML"] || "";
            const blob = new Blob([svg], { type: "image/svg+xml" });
            downloadBlob(blob, `${props.name}-QR-Link.svg`);
        } catch (error) {

        }
    }, [props]);

    
    return !props.code ?
        <div style={{ textAlign: "center" }}>
            <h3>Chương game trình chưa được cấu hình hoàn thành, vui lòng lưu chương trình trước.</h3>
        </div>
        : (<div style={{ minHeight: "400px" }}>

            <Divider orientation="left">QR quét lấy quà tặng </Divider>
            <div style={{ textAlign: "center" }}>
                <div ref={svgRef} >
                    <QRCode allowFullScreen={true} value={props.code} size={200}></QRCode>
                </div>
                <span style={{ border: "solid 1px #ccc", padding: "3px", margin: "2px", borderRadius: " 4px", cursor:"pointer" }} onClick={downloadSVGQRScan}>Download</span>
            </div>
            <Divider orientation="left">QR Link chương trình</Divider>
            <div style={{ textAlign: "center" }}>
                <p>Link: {gameConfig.TOUCH_GAME_BASE_URL + "?code=" + props.code} </p>
                <div ref={svgLinkGameRef} >
                    <QRCode allowFullScreen={true} value={gameConfig.TOUCH_GAME_BASE_URL + "?code=" + props.code} size={200}></QRCode>
                </div>
                <span style={{ border: "solid 1px #ccc", padding: "3px", margin: "2px", borderRadius: " 4px", cursor:"pointer" }} onClick={downloadSVGQRLinkGame}>Download</span>
            </div>

        </div >);
}
