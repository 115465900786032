import { ThemeType } from 'antd/lib/icon';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import DashboardPage from '~/pages/app/dashboard.page';
import UserPage from '~/pages/users/user.page';
import MallPage from '~/pages/malls/mall.page';
import CampaignPage from '~/pages/campaigns/campaign.page';
import VoucherTypePage from '~/pages/voucher-type/voucher-type.page';
import ProfilePage from '~/pages/profiles/profile.page';
import DevicePage from '~/pages/devices/device.page';
import PrivacyPolicyPage from '~/pages/privacy-policy/privacy-policy.page';
import VersionSettingPage from '~/pages/version-setting/version-setting.page';
import AppParamsPage from '~/pages/app-params/app-params.page';
import LoyaltyPage from '~/pages/loyalty-game/loyalty-game.page';
import LoyaltyPageReport from '~/pages/loyalty-game/loyalty-game-report.page';
interface IBaseRouter {
    path: string,
    title: string,
    roles?: string[],
    icon?: {
        type: string,
        theme?:ThemeType
    }
}

export interface ISubRouter extends IBaseRouter {
    component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

export interface IRouter extends IBaseRouter {
    subs: ISubRouter[]
}
export type TRouter = IRouter | ISubRouter
const appRouter: TRouter [] = [
    {
        path: '/',
        title: 'Thống kê',
        component: ProfilePage,
        icon: {
            type: 'dashboard'
        }
    },
    {
        path: '/campaign/list',
        title: 'Chương trình',
        roles:['manager','admin'],
        component: CampaignPage,
        icon: {
            type: 'shop'
        }
    },
    {
        path: '/user/list',
        title: 'Quản lý User',
        roles:['manager','admin'],
        component: UserPage,
        icon: {
            type: 'user'
        }
    },
    {
        path: '/mall/list',
        title: 'Quản lý Chi nhánh',
        roles:['manager','admin'],
        component: MallPage,
        icon: {
            type: 'shop'
        }
    },
    {
        path: '/device/list',
        title: 'DS Thiết bị',
        roles:['manager','admin'],
        component: DevicePage,
        icon: {
            type: 'desktop'
        }
    },
    {
        path: '/privacy-policy',
        title: 'Chính sách bảo mật',
        roles:['manager','admin'],
        component: PrivacyPolicyPage,
        icon: {
            type: 'desktop'
        }
    },
    {
        path: '/app-params',
        title: 'Tham số hệ thống',
        roles:['manager','admin'],
        component: AppParamsPage,
        icon: {
            type: 'desktop'
        }
    },
    {
        path: '/loyalty-game/tich-tem-rinh-qua-cho-be',
        title: 'Game loyalty',
        roles:['manager','admin'],
        component: LoyaltyPage,
        icon: {
            type: 'desktop'
        }
    },
    {
        path: '/loyalty-game-report',
        title: 'Report Game Loyalty',
        roles:['manager','admin'],
        component: LoyaltyPageReport,
        icon: {
            type: 'desktop'
        }
    },
    {
        path: '/loyalty-game/truy-tim-vi-than-tha-dieu-uoc',
        title: 'Truy Tìm Vị Thần',
        roles:['manager','admin'],
        component: LoyaltyPage,
        icon: {
            type: 'desktop'
        }
    },
    
    {
        path: '/version-setting',
        title: 'Quản lý phiên bản',
        roles:['manager','admin'],
        component: VersionSettingPage,
        icon: {
            type: 'desktop'
        }
    },
    // {
    //     path: '/vouchertype/list',
    //     title: 'Quản lý giải thưởng',
    //     roles:['manager','admin'],
    //     component: VoucherTypePage,
    //     icon: {
    //         type: 'ordered-list'
    //     }
    // },

    // {
    //     path: 'user/list',
    //     title: 'Quản lý User',
    //     component: TablePage,
    //     icon: {
    //         type: 'user'
    //     }
        // subs: [
        //     {
        //         path: '/list',
        //         title: 'Danh sách User',
        //         component: TablePage,
        //         icon: {
        //             type: 'profile'
        //         },
        //     },
        //     {
        //         path: '/menu',
        //         title: 'menu',
        //         component: MenuPage,
        //         icon: {
        //             type: 'medium'
        //         },
        //     }
        // ]
    // }
]
export default appRouter