import { apiService } from '../api/api.service';
import { Profile } from './profile.model';


class ProfileService {
    async getProfiles(filterInfo) {
        let result = await apiService.get("profile/list", {
            ...filterInfo,
            fromDate: filterInfo.createdDate[0].unix()*1000,
            toDate: filterInfo.createdDate[1].unix()*1000,
            pageSize: 10,
            isBrowser:true
        });
        if (!apiService.generalProcessDataResponse(result)) return [];
        return result.data;
    }

    async exportProfile(filterInfo) {
        let result = await apiService.post("profile/export", {
            ...filterInfo,
            fromDate: filterInfo.createdDate[0].unix()*1000,
            toDate: filterInfo.createdDate[1].unix()*1000,
            pageSize: 10
        });
        apiService.generalProcessDataResponse(result);
        let tempLink = document.createElement('a');
        tempLink.href = result.data;
        tempLink.click();
    }

    async save(profile: Profile) {
        let result = await apiService.post("profile/save", profile);
        //if (!apiService.generalProcessDataResponse(result)) return null;
        return result;
    }
}
export default new ProfileService();