import { Mall } from "./mall/mall.model";
import mallService from "./mall/mall.service";
import { useStore } from 'react-context-hook'
import { useEffect } from "react";
import { GameConfig } from "./models/game-config";
import { APP_PARRAMS } from "~/common/enums/const";
import appParamsService from "./app-params/app-params.service";

const useGlobalContext = () => {
    const [malls, setMalls] = useStore<Mall[]>('malls', []);
    const [gameConfig, setGameConfig] = useStore<typeof GameConfig>('gameConfig', GameConfig);
    useEffect(() => {
        mallService.getData().then(list => {
            setMalls(list);
        });;
        appParamsService.getDataByGroupAndCode(null, APP_PARRAMS.GROUP.GAME_CONFIG).then(list => {
            let touchGameBaseUrl = list.find(item => item.code = APP_PARRAMS.CODE.GAME_TOUCH_BASE_URL);
            if(touchGameBaseUrl) gameConfig.TOUCH_GAME_BASE_URL = touchGameBaseUrl.value;
            setGameConfig(gameConfig);
        })

    },[] )
    
    return { malls, setMalls, gameConfig }
}

export default useGlobalContext;