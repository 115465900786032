import { apiService, IApiResponse } from "../api/api.service";
import { Mall } from "./mall.model";

class MallService {
    async getData() {
        let result = await apiService.get("mall/list", { pageIndex: 1, pageSize: 999999 });
        if (!apiService.generalProcessDataResponse(result)) return [];
        return result.data.list;
    }

    async delete(id: number) {
        let result = await apiService.put("mall", { id: id, isDeleted: true });
        if (!apiService.generalProcessDataResponse(result)) return false;
        return true;
    }
    async unDelete(id: number) {
        let result = await apiService.put("mall", { id: id, is_deleted: false });
        if (!apiService.generalProcessDataResponse(result)) return false;
        return true;
    }
   
    async save(mall:Mall){
        let result = await apiService.put("mall", mall);
        if (!apiService.generalProcessDataResponse(result)) return null;
        return result;
    }
}
export default new MallService();