import { EMODULES, RequestPath, ROLES } from '~/common/enums/const';

import { apiService } from '../api/api.service';
import { UserSession } from '../models/user-session';
import history from '~/common/utils/browser-history';
import { Role } from '../models/role';

class Authenticate {
    static async login(userLogin) {
        userLogin.module = EMODULES.ADMIN;
        return await apiService.post(RequestPath.login, userLogin);
    }
    static async logout() {
        localStorage.removeItem("current-session");
    }
    public static storeUserSession(userSession: UserSession) {
        localStorage.setItem("current-session", JSON.stringify(userSession));
    }
    public static getUserSessionToken() {
        let userSession = this.getUserSession();
        if (!userSession) return "";
        return userSession.token;
    }
    public static getUserSession(): UserSession {
        try {
            if (!localStorage.getItem("current-session")) return null;
            let userSession = new UserSession;
            return Object.assign(userSession, JSON.parse(localStorage.getItem("current-session")));
        } catch (error) {
            console.log('Get current user login error', error);
            return new UserSession();
        }

    }

    public static isAdmin() {
        try {
            return Authenticate.getUserSession().roles.
                findIndex(item => { return (item.name == ROLES.Manager.name || item.name == ROLES.Admin.name ) }) >= 0;
        } catch (error) {
            return false;
        }
    }

    public static isAllow(roles: Role[]) {
        try {
            for (const role of roles) {
                if (Authenticate.getUserSession().roles.findIndex(item => item.name == role.name)) return true;
            }
            return fail;
        } catch (error) {
            return false;
        }
    }

    public static checkAuthenticate(): boolean {
        try {
            return Authenticate.getUserSession() != null;

        } catch (error) {
            return false;
        }

    }
}

export default Authenticate;