import { Col, Divider, Form, Switch, Table } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import React, { useCallback, useEffect, useState } from 'react';
import { AreaSetting, GameSetting } from '~/services/models/game-setting';
import { SketchPicker, ChromePicker } from 'react-color';
import { ButtonColorPicker } from '~/components/button-color-picker';
import UploadFile from '~/components/upload-file';
import { Row } from 'antd/es/grid';
import { LoyaltyCampaignLinked } from '~/services/campaign/campaign.model';
import campaignService from '~/services/campaign/campaign.service';
import Column from 'antd/lib/table/Column';

// import { ColorPickerComponent } from '@syncfusion/ej2-react-inputs';
export interface ILoyaltyGameSettingProps {
    campaignId: number;
    loyaltyGameExchangeEnable: boolean;
    setLoyaltyGameExchangeEnable: (loyaltyGameExchangeEnable: boolean) => void;
}
const formItemLayout = {
    labelCol: {
        // xs: { span: 24 },
        sm: { span: 12 },
        lg: { span: 12 },
    },
    wrapperCol: {
        // xs: { span: 24 },
        sm: { span: 12 },
        lg: { span: 12 },
    },
};

// gameBackground = "";
// resultBackground = "";
// gameBackgroundColor = "";
// resultBackgroundColor = "";

// areaSettings: AreaSetting[];
// }

export const LoyaltyGameSettingTab = (props: ILoyaltyGameSettingProps) => {
    useEffect(() => {
        console.log({ props })
        campaignService.getListCampaignLoyaltyLinked(props.campaignId).then(linkedList => {
            setLinkedList(linkedList);
        })
    }, [props.campaignId]);
    let [loyaltyGameExchangeEnable, setloyaltyGameExchangeEnable] = useState(false);
    let [linkedList, setLinkedList] = useState<LoyaltyCampaignLinked[]>([]);
    useEffect(() => {
        let loyaltyGameExchangeEnable = props.loyaltyGameExchangeEnable;
        setloyaltyGameExchangeEnable(loyaltyGameExchangeEnable);
    }, [props.loyaltyGameExchangeEnable]);

    return (<div style={{ minHeight: "400px" }}>
        <Row>
            <Col span={24}>Cho phép đổi quà:  <Switch checked={loyaltyGameExchangeEnable}
                onChange={(checked) => {
                    props.setLoyaltyGameExchangeEnable(checked);
                    setloyaltyGameExchangeEnable(checked)
                }}
            /> </Col>
        </Row>
        <Row>
            <Table
                showHeader={true}
                size="small"
                style={{ marginTop: "20px" }}
                //expandedRowRender={record => <p style={{ margin: 0 }}>{record.description}</p>}
                dataSource={linkedList}
            >
                <Column title="Danh sách chương trình liên kết" dataIndex="name" key="name" ></Column>
            </Table>
        </Row>
    </div >)
}
