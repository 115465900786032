import { User } from "../user/user.model";
import { Mall } from "../mall/mall.model";
import { Award } from "../models/award";
import { FormField } from "./formfield.model";

export class FormFieldSetting {
    id: number;
    campaignId: number;
    name: string;
    code: string;
    isShow = true;
    isRequire = false;
    isExport = true;
    checkLength = false;
    maxLength:number;
    minLength:number;

    
    
    private static listDefault = {
        'name': 'Tên khách hàng',
        'phone': 'Số điện thoại',
        'passport': 'Số CMND/CCCD',
        'memberCode': 'Mã thẻ thành viên',
        'billNumbers': 'Số hoá đơn',
        'priceRanges': 'Giá trị hoá đơn',
        // 'note': 'Ghi chú',
        'signature': 'Chữ ký',
        'joinCondition': 'Điều kiện tham gia',
        'orderImages': 'Hình ảnh liên quan',
        'dynamic': 'Trường linh hoạt',

    }

    static getNameByField(fieldName: string): string {
        if (this.listDefault[fieldName]) return this.listDefault[fieldName];
        return fieldName;
    }

    constructor(formField: FormField, campaignId?: number) {
        // this.id = formField.id;
        this.campaignId = campaignId;
        this.name = formField.name;
        this.code = formField.code;
        
        this.isShow = campaignId ? false : formField.isShow;
        this.isRequire = campaignId ? false : formField.isRequire;
        this.checkLength = formField.checkLength;
        this.maxLength = formField.maxLength;
        this.minLength = formField.minLength;
        if (this.isShow && this.isRequire) this.name += " *";
    }

}