import { Card, DatePicker, Form, Modal, Tabs, Select, Icon, Popover, Row, Col, Button, Input, Radio } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { InputNumber } from 'antd';

import React from 'react';
import { PriceRange } from '~/services/models/price-range';
import { numberWithCommas } from '~/common/utils/helper';



export interface IFormModalProps extends FormComponentProps {
    visible: boolean;
    // closeCallback: () => void;
    onCreate: (PriceRange) => void;
    item: PriceRange;
    onClose: (self: any) => void,
}


const formItemLayout = {
    labelCol: {
        // xs: { span: 24 },
        // sm: { span: 8 },
        lg: { span: 8 },
    },
    wrapperCol: {
        // xs: { span: 24 },
        // sm: { span: 16 },
        lg: { span: 16 },
    },
};


class PriceRangeModal extends React.Component<IFormModalProps> {
    state = {
        loading: false,
        item: new PriceRange()
    }

    // constructor(props: IFormModalProps) {
    //     super(props)
    // }

    getItemFromForm(): PriceRange {
        let priceRange = new PriceRange();
        let values = this.props.form.getFieldsValue(Object.getOwnPropertyNames(priceRange))
        Object.assign(priceRange, values);
        priceRange.id = this.props.item.id;
        return priceRange;
    }

    generateDisplayName(){
        let  fromValue = this.props.form.getFieldValue("fromValue");
        let toValue =  this.props.form.getFieldValue("toValue");
        if(fromValue && toValue){
            this.props.form.setFieldsValue({name: numberWithCommas(fromValue) + " - "+ numberWithCommas(toValue)})
        }
    }

    handleSubmit = () => {
        this.props.form.validateFields(async (err, values) => {
            let item = this.getItemFromForm();
            this.props.onCreate(item);
        })

    };


    render() {
        const { getFieldDecorator } = this.props.form;
        let item = this.getItemFromForm();
        //let showLimitField = item.PriceRangeLimitNumber > 0 ? 'inherit' : 'none';
        let showLimitField = 'inherit';
        let modalTitle = (!item.id ? "Thêm" : "Cập nhật") + " phạm vi giá trị hóa đơn";
        return (
            <Modal width="500px" style={{ maxWidth: "96%", top: "20px" }}
                onOk={this.props.onCreate} footer={null}
                onCancel={() => this.props.onClose(this)}
                visible={this.props.visible}  >
                <div >
                    <Form labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }} onSubmit={this.handleSubmit.bind(this)}>
                        <Card title={modalTitle}>
                            <Row>
                                
                                <Form.Item style={{ marginBottom: "5px" }} label="Từ giá trị:">
                                    {getFieldDecorator('fromValue', {})(
                                        <InputNumber
                                            min={0} style={{ width: "100%" }}
                                            formatter={value => `$ ${numberWithCommas(value)}`}
                                            onKeyUp={this.generateDisplayName.bind(this)}
                                            parser={value =>  value.replace(/\$\s?|(\.*)/g, '').replace(/\$\s?|(,*)/g, '')} />)}
                                </Form.Item>
                                <Form.Item style={{ marginBottom: "5px" }} label="Đến giá trị:">
                                    {getFieldDecorator('toValue', {
                                        rules: [
                                            {
                                                validator: async (rule, value) => {
                                                    const { form } = this.props;
                                                    if (value && value < form.getFieldValue('fromValue')) {
                                                        throw new Error('Giá trị đến phải lớn hơn giá trị từ');
                                                    }
                                                }
                                            }
                                        ],
                                    })(
                                        <InputNumber
                                            min={0} style={{ width: "100%" }}
                                            onKeyUp={this.generateDisplayName.bind(this)}
                                            formatter={value => `$ ${numberWithCommas(value)}`}
                                            parser={value =>  value.replace(/\$\s?|(\.*)/g, '').replace(/\$\s?|(,*)/g, '')} />)}
                                </Form.Item>
                                <Form.Item style={{ marginBottom: "5px" }} label="Tên hiển thị">
                                    {getFieldDecorator('name')(<Input readOnly={true} />)}
                                </Form.Item>
                            </Row>
                            <Row style={{ textAlign: "center" }}>
                                <Col span={4}><Button style={{ marginTop: "10px" }} type="primary" onClick={this.handleSubmit.bind(this)} >{modalTitle}</Button></Col>
                            </Row>
                        </Card>
                    </Form>
                </div >
            </Modal >
        );
    }
}

const PriceRangeForm = Form.create<IFormModalProps>({
    onFieldsChange() {
        // props.onChange(changedFields);
    },
    mapPropsToFields(props) {
        const { item } = props;
        const result = {};
        Object.keys(item).forEach(key => {
            const field = Form.createFormField({
                value: item[key]
            })
            Object.assign(result, {
                [key]: field
            })
        })
        return result;
    },
    onValuesChange(_) {
    }
})(PriceRangeModal);

export default PriceRangeForm;