import React from 'react';
import { Upload, message, Button, Icon } from 'antd';

import { BaseApiUrl } from "~/services";
import Authenticate from "~/services/authenticate/authenticate";
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile as UF } from 'antd/lib/upload/interface';

const UploadFile = ({ uploadCallback, multiple=false, showUploadList=false }: { uploadCallback: (url: string) => void, multiple: boolean, showUploadList: boolean }) => {
    const props = {
        showUploadList,
        multiple,
        name: 'file',
        action: BaseApiUrl + 'file/uploads',
        headers: {
            authorization: Authenticate.getUserSessionToken(),
        },
        onChange(info: UploadChangeParam<UF<any>>) {
            try{
                let fullUrl = info.file.response.data[0].fullUrl;
                uploadCallback(fullUrl);
            }catch(e){
                
            }
        
        },
    };

    return (<div>
        <Upload {...props} >
            <Button>
                <Icon type="upload" /> Upload
            </Button>
        </Upload>
    </div>);
}

export default UploadFile;
