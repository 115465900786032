import { Button, Card, Form, Input, Modal, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import React from 'react';
import { Profile } from '~/services/profile/profile.model';
import { Campaign } from '~/services/campaign/campaign.model';
import profileService from '~/services/profile/profile.service';
import { HttpStatus } from '~/common/enums/const';
import { toast } from 'react-toastify';

const { Option } = Select;
export interface IFormModalProps extends FormComponentProps {
    visible: boolean;
    // closeCallback: () => void;
    onCancel: () => void;
    onCreate: () => void;
    profile: Profile;
    campaign: Campaign;
    voucherTypes: any[]
}

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};

class ProfileFormModal extends React.Component<IFormModalProps> {
    formRef: any;
    state = {
        loading: false,
        profile: new Profile()
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (err) return;
            values.id = this.props.profile.id;
            //values.vouchers = values.vouchersEdit.join(",");
            //let awards = values.awards.map(item => {return {id:item}});
            //values.awards = awards;
            values.billNumbers = values.billNumbersEdit.join(",");
            values.mallId = this.props.profile.mall.id;
            values.campaignId = this.props.profile.campaign.id;
            delete values.vouchers;
            delete values.awards;

            profileService.save(values).then(result => {
                if (result && result.code == HttpStatus.OK) {
                    toast.success("Cập nhật profile thành công");
                    this.props.onCreate();
                }
                else {
                    toast.error(result && result.message || "Cập nhật không thành công, vui lòng thử lại");
                }
            })
            //this.props.closeCallback();
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;

        let title = "Cập nhật profile".toUpperCase();
        const priceSelect = [];
        this.props.campaign.priceRanges.forEach((item: any) => {
            priceSelect.push(<Option value={item.value} key={item.value} >{item.name} </Option>);
        });
        let voucherType = this.props.voucherTypes.find(item => item.id == this.props.profile.voucherTypeId)
        let voucherTypeId = voucherType && voucherType.id || "";
        return (
            <Modal width="800px" onOk={this.props.onCreate} footer={null} onCancel={this.props.onCancel} visible={this.props.visible}  >
                <div >
                    <Card title={title} bordered={true} style={{ margin: "20px" }}>
                        <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                            <Form.Item label="Tên khách hàng">
                                {getFieldDecorator('name', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Nhập Tên khách hàng',
                                        },
                                    ],
                                })(<Input />)}
                            </Form.Item>
                            <Form.Item label="Mã thẻ thành viên">
                                {getFieldDecorator('memberCode', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Nhập mã thẻ thành viên',
                                        },
                                        {
                                            len: 14,
                                            message: 'Nhập mã bao gồm 14 ký tự',
                                        },
                                    ],
                                })(<Input />)}
                            </Form.Item>
                            <Form.Item label="Số điện thoại">
                                {getFieldDecorator('phone', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Nhập số điện thoại',
                                        }
                                    ],
                                })(<Input />)}
                            </Form.Item>
                            <Form.Item label="CMND">
                                {getFieldDecorator('passport', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Nhập CMND',
                                        },
                                        {
                                            min: 9,
                                            message: 'CMND tối thiểu 9 tứ tự',
                                        },
                                        {
                                            max: 12,
                                            message: 'CMND tối đa 12 tứ tự',
                                        },
                                    ],
                                })(<Input />)}
                            </Form.Item>
                            <Form.Item label="Số hóa đơn">
                                {getFieldDecorator('billNumbersEdit', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Nhập Số hóa đơn',
                                        },
                                    ],
                                })(<Select mode="tags" onInputKeyDown={(e) => {
                                    if (e.keyCode == 13) e.preventDefault()
                                }} />)}
                            </Form.Item>

                            <Form.Item label="Trị giá hóa đơn">
                                {getFieldDecorator('priceRanges', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Chọn trị giá hóa đơn',
                                        },
                                    ],
                                })(<Select mode="default"  >
                                    {priceSelect}
                                </Select>)}
                            </Form.Item>
                            {/* <Form.Item label="Loại giải thưởng">
                                {getFieldDecorator('awards', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Chọn giải thưởng',
                                        },
                                    ],
                                })(<Select mode="multiple" >
                                    {this.props.campaign.awards.map((item: any) => {
                                        return <Option value={item.id} key={item.value} >{item.name} </Option>;
                                    })}
                                </Select>)}
                            </Form.Item>
                            <Form.Item label="Voucher">
                                {getFieldDecorator('vouchersEdit', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Nhập voucher',
                                        }
                                    ],
                                })(<Select mode="tags" onSelect={(value: string) => {
                                    
                                }} onInputKeyDown={(e) => {
                                    if (e.keyCode == 13) {
                                        let value = e.target["value"];
                                        if(value.lenght !== 15){
                                            toast.warn("Voucher bao gồm 15 ký tự");
                                            e.target["value"] = "";
                                            //return false
                                        }
                                        e.preventDefault();
                                        //return true;
                                    }
                                }} />)}
                            </Form.Item> */}
                            <Form.Item {...tailFormItemLayout}>
                                <Button type="primary" onClick={(e) => { this.handleSubmit(e); }}>
                                    {title}
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </div >
            </Modal>
        );
    }
}

const ProfileForm = Form.create<IFormModalProps>({
    onFieldsChange(props, changedFields) {
        // props.onChange(changedFields);
    },
    mapPropsToFields(props) {
        const { profile } = props;
        const result = {};
        Object.keys(profile).forEach(key => {
            const field = Form.createFormField({
                value: profile[key]
            })
            Object.assign(result, {
                [key]: field
            })
        })

        // let awards = Form.createFormField({
        //     value: profile.awards.map(aw => aw.id)
        // })
        // Object.assign(result, {
        //     awards:awards
        // })
        return result;
    },
    onValuesChange(_, values) {
    }
})(ProfileFormModal);
export default ProfileForm;