import { apiService, IApiResponse } from "../api/api.service";
import { AppParams } from "./app-params.model";

class AppParamsService {
    async getData() {
        let result = await apiService.get("app-params/list", { pageIndex: 1, pageSize: 999999 });
        if (!apiService.generalProcessDataResponse(result)) return [];
        return result.data;
    }

    async getDataByGroupAndCode(code, group) {
        let result = await apiService.get("app-params", { code, group });
        if (!apiService.generalProcessDataResponse(result)) return [];
        return result.data;
    }
;
    async update(appParams: AppParams){
        let result = await apiService.put("app-params", appParams);
        if (!apiService.generalProcessDataResponse(result)) return null;
        return result;
    }
}
export default new AppParamsService();