import { apiService, IApiResponse } from "../api/api.service";
import { VoucherType } from "./voucher-type.model";

class VoucherTypeService {
    async getData() {
        let result = await apiService.get("voucher-type/list", { pageIndex: 1, pageSize: 999999 });
        if (!apiService.generalProcessDataResponse(result)) return [];
        return result.data.list;
    }

    async delete(id: number) {
        let result = await apiService.put("voucher-type", { id: id, isDeleted: true });
        if (!apiService.generalProcessDataResponse(result)) return false;
        return true;
    }
    async unDelete(id: number) {
        let result = await apiService.put("voucher-type", { id: id, is_deleted: false });
        if (!apiService.generalProcessDataResponse(result)) return false;
        return true;
    }
   
    async save(vouchertype:VoucherType){
        let result = await apiService.put("voucher-type", vouchertype);
        if (!apiService.generalProcessDataResponse(result)) return null;
        return result;
    }
}
export default new VoucherTypeService();