import 'react-toastify/dist/ReactToastify.css';

import { ConfigProvider } from 'antd';
import VN from 'antd/es/locale/vi_VN';
import React, { Component } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import history from '~/common/utils/browser-history';
import AppLayout from '~/layouts/app.layout';
import LoginPage from '~/pages/auth/signin.page';
import { PrivateRoute } from '~/routers/private.router';
import Authenticate from '~/services/authenticate/authenticate';
import "~/App.css";
import { withStore} from 'react-context-hook'

const initialState = { }

const storeConfig = {
  listener: (state, key, prevValue, nextValue) => {
      // console.log(`the key "${key}" changed in the store`)
      // console.log('the old value is', prevValue)
      // console.log('the current value is', nextValue)
      // console.log('the state is', state)
  },
  logging: true //process.env.NODE_ENV !== 'production'
}

interface Props {

}

class App extends Component<Props> {

  componentDidMount() {

  }

  checkAuthen = () => {
    if (localStorage.getItem('token')) {
      return true
    }
    return false
  }

  render() {
    return (
      <ConfigProvider locale={VN}>
        <div>
          <div>
            <Router history={history}>
              <Switch>
                <Route path='/login' history={history} exact component={LoginPage} />
                <PrivateRoute path="/" history={history} isSignedIn={Authenticate.checkAuthenticate} component={AppLayout} />
              </Switch>
            </Router>
          </div>
          <ToastContainer autoClose={3000}></ToastContainer>
        </div>
      </ConfigProvider>
    )
  }
}
export default withStore(App, initialState, storeConfig)
// export default App;
