import { apiService, IApiResponse } from '../api/api.service';
import { User } from './user.model';

class UserService {
    async getAllUser() {
        let result = await apiService.get("user/list", { pageIndex: 1, pageSize: 999999 });
        if (!apiService.generalProcessDataResponse(result)) return [];
        return result.data.list;
    }

    async getUserPermission(userId: number) {
        let result = await apiService.get("user/user-permission", { userId });
        if (!apiService.generalProcessDataResponse(result)) return [];
        return result.data;
    }

    async active(userId: number) {
        let result = await apiService.put("user", { id: userId, isActive: true });
        if (!apiService.generalProcessDataResponse(result)) return false;
        return true;
    }
    async inActive(userId: number) {
        let result = await apiService.put("user", { id: userId, isActive: false });
        if (!apiService.generalProcessDataResponse(result)) return false;
        return true;
    }
    async save(user:User){
        let result = await apiService.put("user", user);
        if (!apiService.generalProcessDataResponse(result)) return null;
        return result;
    }

    async changePassword(changePasswordData) {
        let result = await apiService.post("user/change-password", changePasswordData);
        // if (!apiService.generalProcessDataResponse(result)) return null;
        return result;
    }
}
export default new UserService();