import React, { Component } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
interface PrivateRouteProps extends RouteProps {
  component: any;
  history:any,
  isSignedIn:  () => boolean ;
  roles?: object[]
}

export class PrivateRoute extends Component<PrivateRouteProps>{
  
  render() {
    const { component: Component, isSignedIn, roles, ...rest } = this.props;
    const check = isSignedIn();
    return (
      <Route
        {...rest}
        render={(routeProps) =>
          check ? (
            <Component {...routeProps} />
          ) : (
              <Redirect
                to={{
                  pathname: '/login',
                  state: { from: routeProps.location }
                }}
              />
            )
        }
      />
    );
  }
  
  componentWillUnmount(){

  }
};

 
