import { apiService } from '../api/api.service';
import { CampaignResourceBackupModel } from './campaign-resource-backup.model';

class CampaignService {
    async getDetail(campaignId: number): Promise<CampaignResourceBackupModel> {
        let result = await apiService.get("campaign-resource-backup/detail", {  campaignId });
        if (!apiService.generalProcessDataResponse(result)) new CampaignResourceBackupModel();
        return result.data;
    }
    
    async compress(campaignId: number): Promise<CampaignResourceBackupModel> {
        let result = await apiService.get("campaign-resource-backup/compress", {  campaignId });
        if (!apiService.generalProcessDataResponse(result)) new CampaignResourceBackupModel();
        return result.data;
    }
    
    async downloadCompressFile(campaignId: number): Promise<{fileUrl: string, backupData: CampaignResourceBackupModel }> {
        let result = await apiService.get("campaign-resource-backup/download-compress-file", {  campaignId });
        if (!apiService.generalProcessDataResponse(result)) new CampaignResourceBackupModel();
        return result.data;
    }
    async deleteCompressFile(campaignId: number): Promise<CampaignResourceBackupModel> {
        let result = await apiService.get("campaign-resource-backup/delete-compress-file", {  campaignId });
        if (!apiService.generalProcessDataResponse(result)) new CampaignResourceBackupModel();
        return result.data;
    }
    async deleteResource(campaignId: number): Promise<CampaignResourceBackupModel> {
        let result = await apiService.get("campaign-resource-backup/delete-resource", {  campaignId });
        if (!apiService.generalProcessDataResponse(result)) new CampaignResourceBackupModel();
        return result.data;
    }
    
}

export default new CampaignService();