import { Button, Card, Checkbox, Form, Input, Modal, Row, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import React from 'react';
import { Profile } from '~/services/profile/profile.model';
import { Campaign } from '~/services/campaign/campaign.model';
import profileService from '~/services/profile/profile.service';
import { HttpStatus } from '~/common/enums/const';
import { toast } from 'react-toastify';
import { FormFieldSetting } from '~/services/form-field/formfield-setting.model';
import { Col } from 'antd/es/grid';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { FormField } from '~/services/form-field/formfield.model';
import formfieldService from '~/services/form-field/formfield.service';

const { Option } = Select;
export interface IFormModalProps extends FormComponentProps {
    visible: boolean;
    // closeCallback: () => void;
    onCancel: () => void;
    onExport: (selectedItems: FormFieldSetting[]) => void;
    campaign: Campaign;
}

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};

class ExportOptionFormModal extends React.Component<IFormModalProps> {
    formRef: any;
    state = {
        loading: false,
        profile: new Profile(),
        selectedItems: []
    }
    onCheckedChange(e: CheckboxChangeEvent) {
        let selectedItems = this.state.selectedItems;
        selectedItems.forEach(item => {
            if(item.code == e.target.name) item.isExport = e.target.checked
        })
        this.setState({ selectedItems });
    }

    componentWillReceiveProps(nextProp) {
        let selectedItems: FormFieldSetting[] = nextProp.campaign && nextProp.campaign.inputFields || [];
        selectedItems = selectedItems.sort(formfieldService.compareFormField);
        let isPriceRangeShow = selectedItems.filter( ff => ff.code === 'priceRanges' && ff.isShow ).length > 0;
        if (selectedItems.length > 0 && selectedItems.findIndex(item => item.code == "note") == -1) {
            // selectedItems.push({ code: "signature", name: "Chữ ký", isExport: true } as FormFieldSetting)
            selectedItems.push({ code: "note", name: "Ghi chú", isExport: true, isShow: true } as FormFieldSetting);
            if(isPriceRangeShow){
                selectedItems.push({ code: "orderPrices", name: "Danh sách giá hóa đơn", isExport: true, isShow: true } as FormFieldSetting)
                selectedItems.push({ code: "orderTotalPrice", name: "Tổng giá trị hóa đơn", isExport: true, isShow: true } as FormFieldSetting)  
            }
            
        }
        selectedItems.forEach(f => { f.isExport = f.isShow });
        this.setState( { selectedItems });
    }
    // static componentWillReceiveProps(props, state){
    //     let selectedItems: FormFieldSetting[] = props.campaign && props.campaign.inputFields || [];
    //     if (selectedItems.length > 0 && selectedItems.findIndex(item => item.code == "signature") == -1) {
    //         selectedItems.push({ code: "signature", name: "Chữ ký", isExport: true } as FormFieldSetting)
    //     }
    //     selectedItems.forEach(f => { f.isExport = true });
    //     return {selectedItems}
    // }

    render() {
        const { getFieldDecorator } = this.props.form;
        let title = "Chọn trường export".toUpperCase();
        return (
            <Modal width="600px" onOk={() => this.props.onExport(this.state.selectedItems)} onCancel={this.props.onCancel}
                visible={this.props.visible}
                okText="Xuất excel"
                title={title}
                cancelText="Đóng"
            >
                <div >
                    <Row gutter={24}>
                        {this.state.selectedItems.filter(item => item.isShow ).map(field =>
                            <Col className="gutter-row" span={8} key={field.code}>
                                <div className="gutter-box"><Checkbox name={field.code} checked={field.isExport} defaultChecked={true}
                                    onChange={this.onCheckedChange.bind(this)} >{field.name.replace("*", "").trim()}</Checkbox></div>
                            </Col>
                        )}
                    </Row>
                    {/* <Row>
                            <Button onClick={()=>this.props.onExport(this.selectedItems)} type="primary" style={{ float: "right" }} icon="export"> Xuất excel </Button>
                        </Row> */}
                </div >
            </Modal>
        );
    }
}

const ExportOptionForm = Form.create<IFormModalProps>({
    onFieldsChange(props, changedFields) {

    },
    mapPropsToFields(props) {

    },
    onValuesChange(_, values) {
    }
})(ExportOptionFormModal);
export default ExportOptionForm;