import { Campaign } from '../campaign/campaign.model';
import { Mall } from '../mall/mall.model';
import { Award } from '../models/award';

export class Profile {

    id: number;
    name: string;
    phone: string;
    priceRanges: string;
    voucherTypeId: number;
    mall: Mall;
    campaign: Campaign;
    passport: string;
    billNumbers: string;
    memberCode: string;
    vouchers: string;
    note: string;
    signature: string;
    awards: Award[];
    constructor(){
        this.billNumbers = "";
        this.awards = [];
        this.priceRanges = "";
    }
}