
import React, { useCallback, useEffect, useRef, useState } from 'react';
import QRCode from 'react-qr-code';

function downloadBlob(blob, filename) {
    const objectUrl = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = objectUrl;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setTimeout(() => URL.revokeObjectURL(objectUrl), 5000);
}


export const AwardQrImage = (props: { code: string, name: string }) => {

    let svgRef: any = useRef();
    let svgLinkGameRef: any = useRef();
    const downloadSVGQRScan = useCallback(() => {
        try {
            if (!svgRef) svgRef = {}
            const svg = svgRef && svgRef.current && svgRef.current["innerHTML"] || "";
            const blob = new Blob([svg], { type: "image/svg+xml" });
            downloadBlob(blob, `${props.name}-QR-SCAN.svg`);
        } catch (error) {

        }
    }, [props]);

    const downloadSVGQRLinkGame = useCallback(() => {
        try {
            if (!svgLinkGameRef) svgLinkGameRef = {}
            const svg = svgLinkGameRef && svgLinkGameRef.current && svgLinkGameRef.current["innerHTML"] || "";
            const blob = new Blob([svg], { type: "image/svg+xml" });
            downloadBlob(blob, `${props.name}-QR-Link.svg`);
        } catch (error) {

        }
    }, [props]);


    return !props.code ?
        <></>
        : (<div style={{ minHeight: "300px" }}>
            <div ref={svgLinkGameRef} >
                <QRCode allowFullScreen={true} value={props.code} size={200}></QRCode>
            </div>
            <span style={{ border: "solid 1px #ccc", padding: "3px", margin: "2px", borderRadius: " 4px", cursor: "pointer" }} onClick={downloadSVGQRLinkGame}>Download</span>
        </div >);
}
