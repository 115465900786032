import { Button, Icon, Input, Popconfirm, Table, Tooltip } from 'antd';
import Column from 'antd/lib/table/Column';
import React from 'react';
import Highlighter from 'react-highlight-words';
import { toast } from 'react-toastify';
import { HttpStatus } from '~/common/enums/const';
import { Mall } from '~/services/mall/mall.model';
import mallService from '~/services/mall/mall.service';
import { User } from '~/services/user/user.model';

import MallForm from './mall.modal';

interface IActiveBtnProps {
    is_deleted: any;
    activeFn: any,
    inActiveFn: any,
    itemId: number

}

function ActiveBtn(props: IActiveBtnProps) {

    if (!props.is_deleted) {
        return <Tooltip placement="leftTop" title="Xóa thông tin">
            <Popconfirm title={(<span>Bạn có chắc chắn muốn xóa chi nhánh?<br />Nếu bạn xóa chi nhánh, bạn sẽ <br />không thể xem thống kê của chi nhánh này. </span>)} onConfirm={() => { props.inActiveFn(props.itemId) }}>
                <Button data-user-id={props.itemId} style={{ cursor: "pointer" }} size="small" type="danger"><Icon style={{ cursor: "pointer" }} type="lock" /></Button>
            </Popconfirm>
        </Tooltip>
        return null;
    }
    else {
        return <Tooltip placement="leftTop" title="Mở khóa chi nhánh">
            <Popconfirm title="Bạn có chắc chắn muốn sử dụng chi nhánh trở lại?" onConfirm={() => { props.activeFn(props.itemId) }}>
                <Button style={{ cursor: "pointer" }} size="small" type="primary"><Icon type="unlock" /></Button>
            </Popconfirm>
        </Tooltip>
    }
}


export default class MallPage extends React.Component<{ history: any }, {}> {
    formRef: any;
    state = {
        tableLoading: true,
        data: [],
        formInfo: {
            item: new Mall(),
            isModalShow: false
        },
        filteredInfo: {
            searchedColumn: '',
            searchText: '',
            name: [],
            description: []
        }
    }
    searchInput: Input;

    handleClearSearch() {
        this.setState({
            filteredInfo: {
                searchedColumn: '',
                searchText: '',
                name: [],
                description: []
            }
        });
    }

    deleteItem(itemId) {
        let index = this.state.data.findIndex((item) => item.id === itemId);
        if (index >= 0) {
            let items = this.state.data;
            let item = items[index];
            mallService.delete(item.id).then(result => {
                if (!result) {
                    toast.info("Xóa chi nhánh không thành công, vui lòng thử lại");
                    return;
                }
                this.loadListData();
            })

        }
    }
    unDeleteItem(itemId) {
        //unuse
        // let index = this.state.data.findIndex((item) => item.id === itemId);
        // if (index >= 0) {
        //     let items = this.state.data;
        //     let item = items[index];
        //     mallService.unDelete(item.id).then(result => {
        //         if (!result) { toast.info("Xóa mall không thành công, vui lòng thử lại") }
        //         item.is_deleted = false;
        //         items[index] = item;
        //         this.setState({ data: items });
        //     })

        // }
    }

    openModalDetail(item) {
        this.setState({
            formInfo: {
                item: item,
                isModalShow: true

            }
        })
    }
    createItem() {
        this.setState({
            formInfo: {
                isModalShow: true,
                item: new User()
            }
        })
    }

    componentWillMount() {
        this.loadListData();

    }

    loadListData() {
        mallService.getData().then(datas => {
            let data = datas.map(item => { item.key = item.id; return item; })
            this.setState({ data, tableLoading: false });
        })
    }

    saveFormRef = (formRef: any) => {
        this.formRef = formRef;
    };

    onChange(pagination, filters, sorter, extra) {
        this.setState({ filteredInfo: filters })
    }

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    updateUserInList(user: User) {
        let users = this.state.data.map(item => {
            if (item.id === user.id) return user;
            return item;
        })
        users = users.map(item => { item.key = item.id; return item; });
        this.setState({ users });
    }

    handleSave() {
        this.formRef.validateFieldsAndScroll(async (err, values) => {
            let mall = values;
            if (this.state.formInfo.item.id) mall.id = this.state.formInfo.item.id;
            mallService.save(mall).then(result => {
                if (result.code === HttpStatus.OK) {
                    this.loadListData();
                    if (mall.id) {

                        toast.success("Cập nhật chi nhánh thành công");
                    } else {
                        toast.success("Tạo chi nhánh thành công");
                    }
                    setTimeout(() => {
                        this.setState({
                            formInfo: { item: new User(), isUserModalShow: false }
                        })
                    }, 200);
                }
                else {
                    toast.info(result.message);
                }
            }).catch(error => {

            })
        })

    }

    render() {

        const { state } = this;
        let filteredInfo = this.state.filteredInfo;
        return (
            <div className="content">
                <div className="row"> <h2 style={{ textAlign: "center", textTransform: "uppercase" }}>Danh sách chi nhánh</h2> </div>

                <div className="row">
                    <Button onClick={(e) => { this.createItem(); }} type="primary"> <Icon type="user-add" />Thêm chi nhánh </Button>
                    <Button style={{ float: "right" }} onClick={(e) => { this.handleClearSearch(); }} type="primary"> <Icon type="close-square" /> Xóa điều kiện filter </Button>
                </div>
                <br />
                <div style={{ overflowX: "scroll" }}>
                    <Table
                        showHeader={true}
                        size="small"
                        style={{ }}
                        onChange={this.onChange.bind(this)}
                        loading={this.state.tableLoading}
                        //expandedRowRender={record => <p style={{ margin: 0 }}>{record.description}</p>}
                        dataSource={this.state.data}
                    >
                        <Column title="ID" width={"50px"} dataIndex="id" key="id" sortOrder="descend" defaultSortOrder="descend" ></Column>
                        <Column title="Tên" dataIndex="name" key="name" filteredValue={filteredInfo.name} {...this.getColumnSearchProps('name', "Tên")}></Column>
                        <Column title="Mô tả" dataIndex="description" key="description" filteredValue={filteredInfo.description} {...this.getColumnSearchProps('description', "Mô tả")}></Column>

                        <Column title="" dataIndex="action" key="action" render={(text, record) => (
                            <div>
                                <Tooltip placement="top" title="Thông tin chi tiết">
                                    <Button style={{ marginRight: "10px" }} onClick={(e) => { this.openModalDetail(record) }} size="small" type="default"><Icon type="edit" /></Button>
                                </Tooltip>

                                <ActiveBtn
                                    is_deleted={record["is_delete"]}
                                    inActiveFn={this.deleteItem.bind(this)}
                                    activeFn={this.deleteItem.bind(this)}
                                    itemId={record["id"]} />
                            </div>
                        )} >
                        </Column>
                    </Table>
                </div>
                <MallForm
                    // wrappedComponentRef={this.saveFormRef}
                    ref={this.saveFormRef}
                    visible={this.state.formInfo.isModalShow}
                    onCancel={() =>
                        this.setState({
                            formInfo: { item: new User(), isUserModalShow: false }
                        })
                    }
                    onCreate={() => { this.handleSave() }}
                    mall={this.state.formInfo.item}
                ></MallForm>
            </div>
        );
    }



    getColumnSearchProps = (dataIndex, displayText = "") => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${displayText != "" ? displayText : dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
            </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
            </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text => (
            (this.state.filteredInfo.searchedColumn === dataIndex) ?
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.filteredInfo.searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
                : text
        ),
    });
}
