import { Button, Icon, Input, Popconfirm, Table, Tooltip } from 'antd';
import Column from 'antd/lib/table/Column';
import React from 'react';
import Highlighter from 'react-highlight-words';
import { toast } from 'react-toastify';
import { HttpStatus } from '~/common/enums/const';
import { User } from '~/services/user/user.model';
import appVersionSettingService from '~/services/version-setting/version-setting.service';
import { AppVersionSetting } from '~/services/version-setting/version-setting.model';
import AppVersionSettingForm from './version-setting.modal';
import moment from 'moment';


interface IActiveBtnProps {
    isDeleted: any;
    activeFn: any,
    inActiveFn: any,
    itemId: number

}

function ActiveBtn(props: IActiveBtnProps) {

    if (!props.isDeleted) {
        return <Tooltip placement="leftTop" title="Xóa thông tin">
            <Popconfirm title={(<span>Bạn có chắc chắn muốn xóa phiên bản?<br />Nếu bạn xóa phiên bản, bạn sẽ <br />không thể xem thống kê của phiên bản này. </span>)} onConfirm={() => { props.inActiveFn(props.itemId) }}>
                <Button data-user-id={props.itemId} style={{ cursor: "pointer" }} size="small" type="danger"><Icon style={{ cursor: "pointer" }} type="lock" /></Button>
            </Popconfirm>
        </Tooltip>
        return null;
    }
    else {
        return <Tooltip placement="leftTop" title="Mở khóa phiên bản">
            <Popconfirm title="Bạn có chắc chắn muốn sử dụng phiên bản trở lại?" onConfirm={() => { props.activeFn(props.itemId) }}>
                <Button style={{ cursor: "pointer" }} size="small" type="primary"><Icon type="unlock" /></Button>
            </Popconfirm>
        </Tooltip>
    }
}


export default class VersionSettingPage extends React.Component<{ history: any }, {}> {
    formRef: any;
    state = {
        tableLoading: true,
        data: [],
        formInfo: {
            item: new AppVersionSetting(),
            isModalShow: false
        },
        filteredInfo: {
            searchedColumn: '',
            searchText: '',
            appVersionName: [],
            description: []
        }
    }
    searchInput: Input;

    handleClearSearch() {
        this.setState({
            filteredInfo: {
                searchedColumn: '',
                searchText: '',
                appVersionName: [],
                description: []
            }
        });
    }

    deleteItem(itemId) {
        let index = this.state.data.findIndex((item) => item.id === itemId);
        if (index >= 0) {
            let items = this.state.data;
            let item = items[index];
            appVersionSettingService.delete(item.id).then(result => {
                if (!result) {
                    toast.info("Xóa phiên bản không thành công, vui lòng thử lại");
                    return;
                }
                this.loadListData();
            })

        }
    }
    unDeleteItem(itemId) {
        //unuse
        // let index = this.state.data.findIndex((item) => item.id === itemId);
        // if (index >= 0) {
        //     let items = this.state.data;
        //     let item = items[index];
        //     appVersionSettingService.unDelete(item.id).then(result => {
        //         if (!result) { toast.info("Xóa mall không thành công, vui lòng thử lại") }
        //         item.isDeleted = false;
        //         items[index] = item;
        //         this.setState({ data: items });
        //     })

        // }
    }

    openModalDetail(item) {
        item["releaseDate"] = item["releaseDate"] ? moment(item["releaseDate"]) : moment();
        this.setState({
            formInfo: {
                item: item,
                isModalShow: true

            }
        })
    }


    componentWillMount() {
        this.loadListData();

    }

    loadListData() {
        appVersionSettingService.getData().then(datas => {
            let data = datas && datas.map(item => { item.key = item.id; return item; })
            this.setState({ data, tableLoading: false });
        })
    }

    saveFormRef = (formRef: any) => {
        this.formRef = formRef;
    };

    onChange(pagination, filters, sorter, extra) {
        this.setState({ filteredInfo: filters })
    }

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    createItem() {
        this.setState({
            formInfo: {
                isModalShow: true,
                item: new AppVersionSetting()
            }
        })
    }

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    updateUserInList(user: User) {
        let users = this.state.data.map(item => {
            if (item.id === user.id) return user;
            return item;
        })
        users = users.map(item => { item.key = item.id; return item; });
        this.setState({ users });
    }

    handleSave() {
        this.formRef.validateFieldsAndScroll(async (err, values) => {
            let appVersionSetting = values;
            if (this.state.formInfo.item.id) appVersionSetting.id = this.state.formInfo.item.id;
            appVersionSetting['description'] = AppVersionSetting.getDescription();
            appVersionSettingService.save(appVersionSetting).then(result => {
                if (result.code === HttpStatus.OK) {
                    this.loadListData();
                    if (appVersionSetting.id) {

                        toast.success("Cập nhật phiên bản thành công");
                    } else {
                        toast.success("Tạo phiên bản thành công");
                    }
                    setTimeout(() => {
                        this.setState({
                            formInfo: { item: new User(), isUserModalShow: false }
                        })
                    }, 200);
                }
                else {
                    toast.info(result.message);
                }
            }).catch(error => {

            })
        })

    }

    render() {

        const { state } = this;
        let filteredInfo = this.state.filteredInfo;
        return (
            <div className="content">
                <div className="row"> <h2 style={{ textAlign: "center", textTransform: "uppercase" }}>Danh sách phiên bản</h2> </div>

                <div className="row">
                    <Button onClick={(e) => { this.createItem(); }} type="primary"> <Icon type="user-add" />Thêm phiên bản </Button>
                    {/* <Button style={{ float: "right" }} onClick={(e) => { this.handleClearSearch(); }} type="primary"> <Icon type="close-square" /> Xóa điều kiện filter </Button> */}
                </div>
                <br />
                <div style={{ overflowX: "scroll" }}>
                    <Table
                        showHeader={true}
                        size="small"
                        style={{ minWidth: "800px" }}
                        onChange={this.onChange.bind(this)}
                        loading={this.state.tableLoading}
                        //expandedRowRender={record => <p style={{ margin: 0 }}>{record.description}</p>}
                        dataSource={this.state.data}
                    >
                        <Column title="ID" width={"50px"} dataIndex="id" key="id" sortOrder="descend" defaultSortOrder="descend" ></Column>
                        <Column title="Tên phiên bản" dataIndex="appVersionName" key="appVersionName" ></Column>
                        <Column title="Số phiên bản" dataIndex="appVersionNumber" key="appVersionNumber" ></Column>
                        <Column title="Bắt buộc update" render={(text, record) => <span>{record["forceUpdateFlag"] ? "TRUE" : "FALSE"}</span>} key="forceUpdateFlag"  ></Column>
                        <Column title="Ngày phát hành" key="releaseDate"
                            render={(text, record) => <span>{moment(record["releaseDate"]).format("DD-MM-YYYY HH:mm")}</span>} ></Column>
                        <Column title="Nội dung phát hành" dataIndex="releaseNote" key="releaseNote" ></Column>
                        {/* <Column title="Mô tả chi tiết" dataIndex="description" key="description" ></Column> */}

                        <Column title="" width={100} dataIndex="action" key="action" render={(text, record) => (
                            <div>
                                <Tooltip placement="top" title="Thông tin chi tiết">
                                    <Button style={{ marginRight: "10px" }} onClick={(e) => { this.openModalDetail(record) }} size="small" type="default"><Icon type="edit" /></Button>
                                </Tooltip>

                                <ActiveBtn
                                    isDeleted={record["is_delete"]}
                                    inActiveFn={this.deleteItem.bind(this)}
                                    activeFn={this.deleteItem.bind(this)}
                                    itemId={record["id"]} />
                            </div>
                        )} >
                        </Column>
                    </Table>
                </div>
                <AppVersionSettingForm
                    // wrappedComponentRef={this.saveFormRef}
                    ref={this.saveFormRef}
                    visible={this.state.formInfo.isModalShow}
                    onCancel={() =>
                        this.setState({
                            formInfo: { item: new User(), isUserModalShow: false }
                        })
                    }
                    onCreate={() => { this.handleSave() }}
                    appVersionSetting={this.state.formInfo.item}
                ></AppVersionSettingForm>
            </div>
        );
    }



    getColumnSearchProps = (dataIndex, displayText = "") => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${displayText != "" ? displayText : dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text => (
            (this.state.filteredInfo.searchedColumn === dataIndex) ?
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.filteredInfo.searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
                : text
        ),
    });
}
