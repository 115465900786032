import { Card, DatePicker, Form, Modal, Tabs, Select, Icon, Popover, Row, Col, Button, Input, Radio } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { InputNumber } from 'antd';

import React from 'react';
import { numberWithCommas } from '~/common/utils/helper';
import { JoinCondition } from '~/services/join-condition/join-condition.model';



export interface IFormModalProps extends FormComponentProps {
    visible: boolean;
    // closeCallback: () => void;
    onCreate: (JoinCondition) => void;
    item: JoinCondition;
    onClose: (self: any) => void,
}


const formItemLayout = {
    labelCol: {
        // xs: { span: 24 },
        // sm: { span: 8 },
        lg: { span: 8 },
    },
    wrapperCol: {
        // xs: { span: 24 },
        // sm: { span: 16 },
        lg: { span: 16 },
    },
};


class JoinConditionModal extends React.Component<IFormModalProps> {
    state = {
        loading: false,
        item: new JoinCondition(null)
    }

    // constructor(props: IFormModalProps) {
    //     super(props)
    // }

    getItemFromForm(): JoinCondition {
        let joinCondition = new JoinCondition(null);
        let values = this.props.form.getFieldsValue(Object.getOwnPropertyNames(joinCondition))
        Object.assign(joinCondition, values);
        joinCondition.id = this.props.item.id;
        return joinCondition;
    }

    handleSubmit = () => {
        this.props.form.validateFields(async (err, values) => {
            if (err) return;
            let item = this.getItemFromForm();
            this.props.onCreate(item);
        })

    };


    render() {
        const { getFieldDecorator } = this.props.form;
        let item = this.getItemFromForm();
        //let showLimitField = item.JoinConditionLimitNumber > 0 ? 'inherit' : 'none';
        let showLimitField = 'inherit';
        let modalTitle = (!item.id ? "Thêm" : "Cập nhật") + " điều kiện tham gia";
        return (
            <Modal width="500px" style={{ maxWidth: "96%", top: "20px" }}
                onOk={this.props.onCreate} footer={null}
                onCancel={() => this.props.onClose(this)}
                visible={this.props.visible}  >
                <div >
                    <Form labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }} onSubmit={this.handleSubmit.bind(this)}>
                        <Card title={modalTitle}>
                            <Row>
                                <Form.Item style={{ marginBottom: "5px" }} label="Tên điều kiện ">
                                    {getFieldDecorator('content', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Nhập tên điều kiện',
                                            },
                                        ],
                                    })(<Input />)}
                                </Form.Item>
                            </Row>
                            <Row style={{ textAlign: "center" }}>
                                <Col span={4}><Button style={{ marginTop: "10px" }} type="primary" onClick={this.handleSubmit.bind(this)} >{modalTitle}</Button></Col>
                            </Row>
                        </Card>
                    </Form>
                </div >
            </Modal >
        );
    }
}

const JoinConditionForm = Form.create<IFormModalProps>({
    onFieldsChange() {
        // props.onChange(changedFields);
    },
    mapPropsToFields(props) {
        const { item } = props;
        const result = {};
        Object.keys(item).forEach(key => {
            const field = Form.createFormField({
                value: item[key]
            })
            Object.assign(result, {
                [key]: field
            })
        })
        return result;
    },
    onValuesChange(_) {
    }
})(JoinConditionModal);

export default JoinConditionForm;