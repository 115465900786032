

export class LoyaltyGameDetailAdminModel {
    id: number;
    name: string;
    code: string;
    status: boolean;
    exchangeNumberLimit: number;
    messageOnStop: string;
    loyaltyGameFrames: LoyaltyGameFrame[];
    linkedCampaigns: LoyaltyCampaignLinked[];
    constructor() {
        this.name = "";
        this.code = "";
        this.status = false;
        this.exchangeNumberLimit = 0;
        this.messageOnStop = "";
    }
}

export class LoyaltyGameFrame {
    id: number;
    loyaltyGameId: number;
    name: string;
    url: string;
    status: boolean;
    display_index: number;
    visible: boolean;
    constructor() {
        this.id = undefined;
        this.loyaltyGameId = 0;
        this.name = "";
        this.url = "";
        this.status = true;
        this.display_index = 10;
        this.visible = true;
    }
}

export class LoyaltyCampaignLinked {
    id?: number;
    campaignId: number;
    loyaltyGameId: number;
    sequence: number;
}