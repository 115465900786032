export const RequestPath = {
    login: "user/login"
}

export const HttpStatus = {
    OK: 200,
    BadRequest: 400,
    Unauthorization: 401,
    Forbidden: 403,
    InternalServerError: 500
}

export const ROLES = {
    Admin: { id: 1, name: "admin" },
    Manager: { id: 2, name: "manager" },
    Operation: { id: 3, name: "operation" },
    CS: { id: 4, name: "cs" },
    Agency: { id: 5, name: "agency" },
    PG: { id: 6, name: "bg" },
}

export const Status = {
    "Draft": 1,
    1: "Draft",
    "New": 2,
    2: "New",
    "WaitingForApproved": 3,
    3: "WaitingForApproved",
    "Approved": 4,
    4: "Approved",
    "Rejected": 5,
    5: "Rejected",
    "WaitingForPublished": 6,
    6: "WaitingForPublished",
    "Published": 7,
    7: "Published",
    "Expired": 8,
    8: "Expired",
    "Removed": 9,
    9: "Removed",
    "ViewDtailOnly": 10,
    10: "ViewDtailOnly",
    "Hide": 11,
    11: "Hide",
    "Distributed": 12,
    12: "Distributed",
    "Used": 13,
    13: "Used",
    "Active": 14,
    14: "Active",
    "Inactive": 15,
    15: "Inactive",
    "Completed": 16,
    16: "Completed",
    "Success": 17,
    17: "Success",
    "Unsuccess": 18,
    18: "Unsuccess",
    "Error": 19,
    19: "Error",
}
export const EMODULES = {
    ADMIN: 'admin',
    CLIENT: 'client'
}

export const GAME_TYPES = [
    { title: "Game Đơn Giản", key: "STANDARD" },
    { title: "Quét QR", key: "SCAN_QR" },
    { title: "Game Chạm", key: "TOUCHING" },
]

export const APP_PARRAMS = {
    CODE: {
        GAME_TOUCH_BASE_URL:"GAME_TOUCH_BASE_URL"
    },
    GROUP: {
        GAME_CONFIG: "GAME_CONFIG"
    }
}