export class GameSetting {
    gameBackground = "";
    resultBackground = "";
    gameBackgroundColor = "";
    resultBackgroundColor = "";
    formRegisterBackgroundColor = "";
    resultInfoTextColor = "";
    welcomeBackground = "";
    formRegisterBackground = "";
    showResultInfo = false;
    showConditionPopup = false;
    
    areaSettings: AreaSetting[] = [];

}

export class  AreaSetting {
    index: number;
    code: string;
    name: string;
    enable: boolean;
    image: string;
    constructor(index: number){
        this.index = index;
        this.code = "area" + index;
        this.name = "Điểm chạm - " + (index + 1);
        this.enable = true;
        this.image = "";
    }
}