export class CampaignResourceBackupModel {
    campaignId: number;
    resourceType: string;
    compressDate: Date;
    compressBy: number;
    compressByName: string;
    compressProcessStatus: string;
    downloadDate: Date;
    downloadBy: number;
    downloadByName: string;
    deletedDate: Date;
    deletedBy: number;
    deletedByName: string;
    deletedCompressBy: number;
    deletedCompressByName: string;
    deletedCompressDate: Date;


    constructor() {
        this.campaignId = 0;
        this.resourceType = '';
        this.compressBy = 0;
        this.compressByName = '';
        this.compressProcessStatus = '';
        this.downloadBy = 0;
        this.downloadByName = '';
        this.deletedBy = 0;
        this.deletedByName = '';
        this.deletedCompressBy = 0;
        this.deletedCompressByName = '';
    }
}