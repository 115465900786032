import { apiService } from '../api/api.service';
import { Campaign } from './campaign.model';
import formfieldService from '../form-field/formfield.service';
import { FormFieldSetting } from '../form-field/formfield-setting.model';
import { LoyaltyGameDetailAdminModel } from '../loyalty-game/loyalty-game.model';

class CampaignService {
    async getData(filters) {
        let result = await apiService.get("campaign/list/all", { ...filters, campaignType: "ALL" });
        if (!apiService.generalProcessDataResponse(result)) return [];
        return result.data;
    }
    
    async getListCampaignLoyaltyLinked(id) {
        let result = await apiService.get("campaign/loyalty-game/list-linked", { id });
        if (!apiService.generalProcessDataResponse(result)) return [];
        return result.data;
    }
    async getLoyaltyGameCampaign() {
        let result = await apiService.get("campaign/loyalty-game/list-all");
        if (!apiService.generalProcessDataResponse(result)) return [];
        return result.data;
    }

    async save(campaign:Campaign){
        let result = await apiService.post("campaign", campaign);
        return result;
    }

    async getDetail(id: number){
        let result = await apiService.get("campaign/detail", {id});
        return result.data;
    }
    
    async delete(id:number){
        let result = await apiService.post("campaign/delete", {id});
        return result;
    }

    async initFormField(campaign: Campaign){
        let listFormField = await formfieldService.getData({});
        if(!campaign.inputFields) campaign.inputFields = [];
        listFormField.forEach( formField => {
            let found = campaign.inputFields.filter( ff1 => ff1.code == formField.code );
            if(found.length == 0) {
                campaign.inputFields.push( new FormFieldSetting(formField, campaign.id)  )
            }
        } )
        
    }
}

export default new CampaignService();