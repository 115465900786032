import { Button, Card, Form, Input, Modal } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import React from 'react';
import { AppParams } from '~/services/app-params/app-params.model';
import { Mall } from '~/services/mall/mall.model';


export interface IFormModalProps extends FormComponentProps {
    visible: boolean;
    // closeCallback: () => void;
    onCancel: () => void;
    onCreate: () => void;
    appParams: AppParams;
}

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};

class AppParamsModal extends React.Component<IFormModalProps> {
    formRef: any;
    state = {
        loading: false,
        appParams: new AppParams()
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (err) return;
            this.props.onCreate();
            //this.props.closeCallback();
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        let isCreateNew = !(this.props.appParams && this.props.appParams.id);
        let title = (!isCreateNew ? "Cập nhật tham số" : "Tạo tham số").toUpperCase();
        return (
            <Modal width="800px" onOk={this.props.onCreate} footer={null} onCancel={this.props.onCancel} visible={this.props.visible}  >
                <div >
                    <Card title={title} bordered={true} style={{ margin: "20px" }}>
                        <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                            <Form.Item label="Tên tham số">
                                {getFieldDecorator('name')(<Input  readOnly={true}/>)}
                            </Form.Item>
                            <Form.Item label="Tên tham số">
                                {getFieldDecorator('group')(<Input  readOnly={true}/>)}
                            </Form.Item>
                            <Form.Item label="Tên tham số">
                                {getFieldDecorator('code')(<Input  readOnly={true}/>)}
                            </Form.Item>
                            <Form.Item label="Giá trị">
                                {getFieldDecorator('value', {})(<Input autoComplete="false" />)}
                            </Form.Item>
                    
                            <Form.Item {...tailFormItemLayout}>
                                <Button type="primary" htmlType="submit">
                                    {title}
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </div >
            </Modal>
        );
    }
}

const AppParamsForm = Form.create<IFormModalProps>({
    onFieldsChange(props, changedFields) {
        // props.onChange(changedFields);
    },
    mapPropsToFields(props) {
        const { appParams } = props;
        const result = {};
        Object.keys(appParams).forEach(key => {
            const field = Form.createFormField({
                value: appParams[key]
            })
            Object.assign(result, {
                [key]: field
            })
        })
        return result;
    },
    onValuesChange(_, values) {
    }
})(AppParamsModal);
export default AppParamsForm;