import { Button, Card, Form, Icon, Input, Modal } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { log } from 'console';
import JoditEditor from "jodit-react";
import React from 'react';
import { toast } from 'react-toastify';
import { Editor } from '~/components/Editor';
import { BaseApiUrl } from '~/services';
import Authenticate from '~/services/authenticate/authenticate';
import privacyPolicyService from '~/services/privacy-policy/privacy-policy.service';



export interface IFormModalProps extends FormComponentProps {
    visible: boolean;
    // closeCallback: () => void;
    onCancel: () => void;
    onCreated: (content) => void;
    content: string;
}

class PrivacyPolicyFormModal extends React.Component<any> {
    formRef: any;
    state = {
        loading: false,
        content: '',
        editContent: ''
    }
    contentEditor = '';
    handleSubmit = (e) => {
        e.preventDefault();
        if (!this.contentEditor) {
            toast.info("Vui lòng nhập nội dung chính sách");
            return;
        }

        privacyPolicyService.save(this.contentEditor)
            .then(response => {
                toast.success("Cập nhật chính sách thành công");
                this.props.onCreated(response)
            })
    };
    setContent(content) {
        this.contentEditor = content;
    }
    render() {
        return (
            <Modal width="1000px" style={{ maxWidth: "90%", maxHeight: "100hv", top: "2vh" }} onOk={this.props.onCreated} footer={null}
                onCancel={this.props.onCancel} visible={this.props.visible}
            >
                <div >
                    <Card title={"Cập nhật Chính sách bảo mật"} bordered={true}
                        extra={<Button title='Lưu chính sách' onClick={this.handleSubmit} type="primary"> <Icon type="save" /> Lưu chính sách </Button>}
                        style={{ margin: "0 20px", maxHeight: "90vh" }}
                    >
                        <Editor content={this.props.content} onContentChanged={this.setContent.bind(this)} />
                        {/* <JoditEditor
                            ref={null}
                            value={this.props.content}

                            config={{
                                toolbarStickyOffset: 0, globalFullSize: true, innerHeight: "60vh",
                                scrollY: true,
                                "buttons": ["bold", "italic", "underline", "strikethrough", "eraser", "ul", "ol", "indent", "outdent", "left", "font", "fontsize", "paragraph", "classSpan", "brush", "superscript", "subscript", "image", "video"],
                                uploader: {
                                    url: BaseApiUrl + 'file/uploads',
                                    format: 'form-data',
                                    // buildData: function (data) {
                                    //     console.log({data});
                                    // },
                                    headers: { "Authorization": Authenticate.getUserSessionToken() },
                                    isSuccess: function (resp) {
                                        return !resp.error;
                                    },
                                    getMessage: function (resp) {
                                        return resp.msg;
                                    },
                                    // process: function (resp) {
                                    //     return {
                                    //         files: resp.files || [],
                                    //         path: resp.path,
                                    //         baseurl: resp.baseurl,
                                    //         error: resp.error,
                                    //         msg: resp.msg
                                    //     };
                                    // },
                                    prepareData: function (formdata) {
                                        formdata["category"] = "policy";
                                        formdata["subCategory"] = "policy-content";
                                    },
                                    defaultHandlerSuccess: function (filesRes, resp) {
                                        console.log({filesRes, resp});
                                        for (const file of filesRes) {
                                            this["selection"].insertImage(file.fullUrl, null, 250);
                                        }
                                        
                                    },
                                    error: function (e) {
                                        // this.e.fire('errorMessage', [e.getMessage(), 'error', 4000]);
                                    }
                                }

                            }}
                            // tabIndex={1} // tabIndex of textarea
                            onBlur={newContent => this.setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                        // onBlur={newContent => { this.setState({ editContent: newContent }) }}
                        /> */}
                    </Card>
                </div >
            </Modal>
        );
    }
}

// const PrivacyPolicyForm = Form.create<IFormModalProps>({
//     onFieldsChange(props, changedFields) {
//         // props.onChange(changedFields);
//     },
//     mapPropsToFields(props) {
//         const { content } = props;
//         const result = { content };

//         return result;
//     },
//     onValuesChange(_, values) {
//     }
// })(PrivacyPolicyFormModal);
// export default PrivacyPolicyForm;

export default PrivacyPolicyFormModal;