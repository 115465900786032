import { apiService, IApiResponse } from "../api/api.service";

class MiniGameAwardReportService {
    async getData(awardId: number) {
        let result = await apiService.get("minigame/report-award", {awardId});
        if (!apiService.generalProcessDataResponse(result)) return [];
        return result.data;
    }
}


export default new MiniGameAwardReportService();