import { Button, Checkbox, Col, Divider, Form, Icon, Input, Modal, Radio, Select, Switch, Table, Tooltip } from 'antd';
import { Row } from 'antd/es/grid';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import Column from 'antd/lib/table/Column';
import React, { Component } from 'react';
import { Campaign } from '~/services/campaign/campaign.model';
import { FormField } from '~/services/form-field/formfield.model';
import { Mall } from '~/services/mall/mall.model';
import { Award, AWardType } from "~/services/models/award"
import AwardForm from './award.modal';
import { ProfileIdentifyFieldComponent } from './profile-identify-field';

const { Option } = Select;

export interface AwardTabContentModel {
    awardLimitEnable: boolean,
    awardLimitByField: string,
    awardLimitPerDay: number,
    awardLimitOnCampaign: number,
    formFields: FormField[]
    awards: Award[],

    campaign: Campaign
}

interface Props {
    campaignForm: WrappedFormUtils<any>,
    object: AwardTabContentModel, 
}

export class AwardTabContent extends Component<Props> {
    state = {
        showAwardModal: false,
        awardItem: new Award()
    }
    onAwardSave(award: Award) {
        award.value = award.name;
        let { campaign } = this.props.object;
        if (!award.id) {

            let index = campaign.awards.findIndex(item => item.itempId == award.itempId);
            if (index >= 0) campaign.awards[index] = award;
            else campaign.awards.push(award);
        }
        else {
            let index = campaign.awards.findIndex(item => item.id == award.id);
            if (index >= 0) campaign.awards[index] = award;
        }
        this.setState({ showAwardModal: false })
    }

    openModalAward = (award: Award) => {
        this.setState({ showAwardModal: true, awardItem: award });

    }

    handleAddAwards() {
        this.setState({ awardItem: new Award(), showAwardModal: true })
    }

    onAwardDelete(award: Award) {
        let item = this.props.object.campaign;
        let _this = this;
        Modal.confirm({
            title: `Bạn có muốn xóa giải thưởng: "${award.name}"?`,
            onOk() {
                let index = item.awards.findIndex(item => item.id == award.id);
                if (index >= 0) item.awards.splice(index, 1);
                _this.setState({})
            },
            onCancel() {
            },
        });
    }

    render(): React.ReactNode {
        const { getFieldDecorator, setFieldsValue } = this.props.campaignForm;
        let formFieldOptions = this.props.object.formFields.map(ff => <Option key={ff.id} value={ff.code} >{ff.name}</Option>);


        return (<div>
            <AwardForm
                onCreate={this.onAwardSave.bind(this)}
                campaignForm={this.props.campaignForm}
                item={this.state.awardItem}
                setItem={(awardItem) => {
                    this.setState({ ...this.state, awardItem })
                }}
                mallSelectedIds={this.props.campaignForm.getFieldValue("mallSelectedIds")}
                campaign={this.props.object.campaign}
                visible={this.state.showAwardModal}
                onClose={() => { this.setState({ showAwardModal: false }) }}
            />

            <Divider orientation="left">Trường phân biệt khách hàng</Divider>
            <Row style={{ display: "inline-flex", paddingLeft: "40px" }}>
            {getFieldDecorator('awardLimitByField')(<ProfileIdentifyFieldComponent
                formFieldOptions={formFieldOptions}
            />)}
               
            </Row>
            <Divider orientation="left">Giới hạn giải thưởng mỗi khách hàng</Divider>
            <Row >
                <Col span={6}>
                    <Form.Item label="Giới hạn" labelCol={{ sm: { span: 18 } }} wrapperCol={{ sm: { span: 6 } }}>
                        {getFieldDecorator('awardLimitEnable')(
                            <Switch checked={this.props.campaignForm.getFieldValue("awardLimitEnable")} />)}
                    </Form.Item>
                </Col>
                <Col span={18}>
                    <Row>
                        <div hidden={!this.props.campaignForm.getFieldValue('awardLimitEnable')} style={{ textAlign: "center" }}>
                            <label htmlFor="">Giá trị 0: Không giới hạn số lượng</label>
                            <Form.Item label="Số lượng trên ngày:" labelCol={{ sm: { span: 10 } }} wrapperCol={{ sm: { span: 8 } }} style={{ marginBottom: 0 }}>
                                {getFieldDecorator('awardLimitPerDay')(
                                    <Input min={0} max={1000} type="number" />)}
                            </Form.Item>

                            <Form.Item label="Số lượng trên Campaign:" labelCol={{ sm: { span: 10 } }} wrapperCol={{ sm: { span: 8 } }} style={{ marginBottom: 0 }}>
                                {getFieldDecorator('awardLimitOnCampaign')(
                                    <Input min={0} max={1000} type="number" />)}
                            </Form.Item>

                        </div>
                    </Row>
                </Col>


            </Row>

            <Row style={{ paddingTop: "15px" }}>
                <Divider orientation="left">Danh sách giải thưởng</Divider>
                <Table
                    showHeader={true}
                    pagination={false}
                    size="small"
                    dataSource={this.props.object.awards}
                    key="id"
                    rowKey="id"
                    scroll={{ x: true }}
                >
                    <Column title="Tên giải thưởng" width={"200px"} dataIndex="name" render={(text, record) => {
                        return (<Button onClick={() => { this.openModalAward(record as Award) }} style={{ textAlign: "left" }} type="link" block> {text} </Button>)
                    }} ></Column>
                    <Column align='center' title="Loại" width={"100px"} dataIndex="type" render={(text, item: Award) => {
                        if (item.type == AWardType.ITEM) return "Hiện vật";
                        if (item.type == AWardType.VOUCHER) return "Giải thưởng";
                        return "";
                    }} ></Column>
                    <Column align='center' title="Trạng thái" dataIndex="status" render={(text, item: Award) => {
                        return item.status ? <Icon type="check-square" style={{ color: "blue" }} /> : <Icon type="close-square" style={{ color: "red" }} />;
                    }} ></Column>
                    <Column align='center' title="Giới hạn số lượng" width={"70px"} dataIndex="awardLimitNumber" render={(text, item: Award) => {
                        if (!item.awardLimitNumber) return "Không giới hạn";
                        return item.awardLimitNumber;
                    }} ></Column>
                    <Column align='center' title="Giới hạn trên" width={"70px"} dataIndex="awardLimitPerDay" render={(text, item: Award) => {
                        return item.awardLimitNumber && item.awardLimitPerDay ? "Ngày" : "Chương Trình";
                    }} ></Column>
                    {/* <Column align='center' title="Trên Chương trình" width={"150px"} dataIndex="awardLimitOnCampaign" render={(text, item: Award) => {
                        if (item.awardLimitNumber && item.awardLimitOnCampaign) return (<Radio checked={true} />)
                        return "";
                    }} ></Column> */}
                    <Column align='center' title="Thao tác" width={"100px"} render={(text, item: Award) => {
                        return (
                            <div>
                                {/* <Icon style={{ cursor: "pointer", color: "blue", paddingRight: "5px" }} onClick={() => { this.openModalAward(item) } type="edit" /> */}
                                <Tooltip placement="top" title="Sửa giải thưởng">
                                    <Button style={{ marginRight: "10px" }} onClick={() => { this.openModalAward(item) }} size="small" type="default"><Icon type="edit" /></Button>
                                </Tooltip>
                                <Tooltip placement="top" title="Xóa giải thưởng">
                                    <Button style={{ marginRight: "10px" }} onClick={(e) => { this.onAwardDelete(item) }} size="small" type="danger"><Icon type="delete" /></Button>
                                </Tooltip>
                                {/* <Icon style={{ cursor: "pointer", color: "blue",  paddingRight: "5px" }} onClick={() => this.openModalAward(item)} type="edit" />
                                                        <Icon style={{ cursor: "pointer", color: "blue" }} onClick={() => this.onAwardDelete(item)} type="delete" /> */}
                            </div>

                        )
                    }} ></Column>
                </Table>
            </Row>
            <Row>
                <Button type="primary" style={{ float: "right", marginTop: "20px" }} onClick={this.handleAddAwards.bind(this)}>Thêm giải thưởng</Button>
            </Row>
        </div>)
    }
}
