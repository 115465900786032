import { Button, Checkbox, Icon, Input, Popconfirm, Table, Tag, Tooltip } from 'antd';
import Column from 'antd/lib/table/Column';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import React from 'react';
import Highlighter from 'react-highlight-words';
import { Campaign } from '~/services/campaign/campaign.model';
import campaignService from '~/services/campaign/campaign.service';
import mallService from '~/services/mall/mall.service';
import userService from '~/services/user/user.service';

import CampaignForm from './campaign.modal';
import formfieldService from '~/services/form-field/formfield.service';
import joinConditionService from '~/services/join-condition/join-condition.service';
import { GameSetting } from '~/services/models/game-setting';
import { User } from '~/services/user/user.model';

interface IActiveBtnProps {
    is_deleted: any;
    activeFn: any,
    inActiveFn: any,
    itemId: number

}

export default class CampaignPage extends React.Component<{ history: any }, {}> {
    formRef: any;
    campaignForm;
    state = {
        tableLoading: true,
        data: [],
        users: [],
        malls: [],
        formFields: [],
        total: 0,
        formInfo: {
            item: new Campaign(),
            tabIndexActive: "1",
            isModalShow: false
        },
        filteredInfo: {
            searchedColumn: '',
            searchText: '',
            name: [],
            description: []
        }
    }
    searchInput: Input;

    constructor(props) {
        super(props);
        this.campaignForm = React.createRef();
    }

    deleteItem(itemId) {
    }
    unDeleteItem(itemId) {
    }

    async openModalDetail(item) {
        let cloneObject = {};
        let record = await campaignService.getDetail(item.id);
        record.status = record.status === 1 || record.status === true;
        record.dateRange = [moment(record.fromDate).local(), moment(record.toDate, "YYYY-MM-DD").local()]
        if(record.id) record.joinConditions = await joinConditionService.getListByCampaign(record.id);
        if(!record.gameSetting){
            record.gameSetting = new GameSetting();
        }
        record.gameSetting.areaSettings = JSON.parse(record.gameSetting["areaSettingsStr"] || "[]");
        cloneObject = cloneDeep(record);
        cloneObject["mallSelectedIds"] = [...record.malls.map(item => item.id.toString())];
 
        if (!cloneObject["inputFields"] || cloneObject["inputFields"].length == 0) {
            campaignService.initFormField(cloneObject as Campaign);
        }
        // cloneObject["userSelectedIds"] = [...record.users.map(item => item.id.toString())];
        this.setState({
            formInfo: {
                item: cloneObject,
                isModalShow: true

            }
        })
    }
    createItem() {
        let newCampaign = new Campaign();
        campaignService.initFormField(newCampaign).then(() => {
            this.setState({
                formInfo: {
                    isModalShow: true,
                    item: newCampaign
                }
            })
        })

    }

    componentWillMount() {
        this.loadListData();
        this.loadListUser();
        this.loadListMall();
        this.loadListFormField();
    }

    loadListData(pageIndex = 1, filters = {}) {
        campaignService.getData({ pageIndex, name: this.state.filteredInfo.name }).then(datas => {
            if (!datas || datas.length === 0) return;
            let data = datas.list.map(item => {
                item.key = item.id;
                return item;
            })
            this.setState({ total: datas.total, data, tableLoading: false });
        })
    }


    loadListUser() {
        userService.getAllUser().then(datas => {
            let data: [] = datas.filter(item => item.isActive)
                .map(item => {
                    item.key = item.id.toString();
                    item.title = item.fullname
                    return item;
                })
            this.setState({ users: data });
        })
    }
    loadListMall() {
        mallService.getData().then(data => {
            data = data.map(item => {
                item.key = item.id.toString();
                item.title = item.name
                return item;
            })
            this.setState({ malls: data });
        })
    }
    loadListFormField() {
        formfieldService.getData({}).then(data => {
            this.setState({ formFields: data });
        })
    }

    saveFormRef = (formRef: any) => {
        this.formRef = formRef;
    };

    onChange(pagination, filters, sorter, extra) {
        this.setState({ filteredInfo: filters }, () => {
            this.loadListData(pagination.current);
        })
    }

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        if (clearFilters) clearFilters();
        this.setState({
            searchText: '', filteredInfo: {
                searchedColumn: '',
                searchText: '',
                name: [],
                description: []
            }
        }, () => {
            this.loadListData();
        });
    };

    deleteCampaign(campaign) {
        let state = this.state;
        state.tableLoading =  true;
        campaignService.delete(campaign.id).then(()=>{
        this.loadListData();
            state.tableLoading =  false;
        })
    }

    handleSaveComplete(campaign) {
        let index = (this.state.data.findIndex(item => item.id === campaign.id));
        if (index >= 0) {
            campaign.key = campaign.id;
            this.state.data[index] = campaign;
        } else {
            this.loadListData()
        }
        this.setState({
            formInfo: {
                isModalShow: false,
                item: new Campaign()
            }
        })
    }
    onClose = (child) => {
        setTimeout(() => {
            child.setState({ tabIndex: "1" });
        }, 1000);
        this.setState({ formInfo: { item: new Campaign(), isUserModalShow: false, tabIndexActive: "1" } });
    }
    render() {

        const { state } = this;
        let filteredInfo = this.state.filteredInfo;
        return (
            <div className="content">
                <div className="row"> <h2 style={{ textAlign: "center", textTransform: "uppercase" }}>Danh sách chương trình</h2> </div>

                <div className="row">
                    <Button onClick={(e) => { this.createItem(); }} type="primary"> <Icon type="user-add" />Thêm chương trình </Button>
                    <Button style={{ float: "right" }} onClick={(e) => { this.handleReset(null); }} type="primary"> <Icon type="close-square" /> Xóa điều kiện filter </Button>
                </div>
                <br />
                <div style={{overflow: "scroll"}}>
                    <Table
                        showHeader={true}
                        size="small"
                        style={{   minWidth:950 }}
                        pagination={{ total: this.state.total }}
                        onChange={this.onChange.bind(this)}
                        loading={this.state.tableLoading}
                        expandedRowRender={record => <p style={{ margin: 0 }}><div className="content" dangerouslySetInnerHTML={{__html:record.description}}></div></p>}
                        dataSource={this.state.data}
                    >
                        <Column title="ID" width={"50px"} dataIndex="id" key="id" sortOrder="descend" defaultSortOrder="descend" ></Column>
                        <Column title="Tên chương trình" dataIndex="name" key="name" filteredValue={filteredInfo.name} {...this.getColumnSearchProps('name', "Tên Campaign")}></Column>
                        <Column title="Chương trình loyalty" dataIndex="name" key="loyaltyGame"  render={(text, record) => ( <Checkbox  style={{cursor: ""}} disabled={true} checked={record["isLoyaltyGame"]} />)}></Column>
                        <Column title="Loại chương trình" dataIndex="name" key="isGame"  render={(text, record) => (record["isGame"] ? "Chương Trình Game" : "Chương Trình CRM")}></Column>
                        <Column title="Từ ngày" width={"100px"} dataIndex="fromDate" key="fromDate" render={(text, record) => { return moment(record["fromDate"]).local().format("DD-MM-YYYY") }} ></Column>
                        <Column title="Đến ngày" width={"100px"} dataIndex="toDate" key="toDate" render={(text, record) => { return moment(record["toDate"]).local().format("DD-MM-YYYY") }}></Column>
                        <Column title="Trạng thái" dataIndex="status" key="status" render={(text, record) => (
                            record["status"] ? <Tag color="#2db7f5">Published</Tag> : <Tag>UnPublished</Tag>
                        )} >
                        </Column>

                        <Column title="" dataIndex="action" key="action" render={(text, record) => (
                            <div>
                                <Tooltip placement="top" title="Thông tin chi tiết">
                                    <Button style={{ marginRight: "10px" }} onClick={(e) => { this.openModalDetail(record) }} size="small" type="default"><Icon type="edit" /></Button>
                                </Tooltip>

                                {/* <Tooltip placement="top" title="Xóa chương trình"> */}
                                    <Popconfirm title='Bạn có chắc chắn muốn xóa chương trình này?' onConfirm={() => { this.deleteCampaign(record) }}>
                                        <Button style={{ marginRight: "10px" }} size="small" type="danger"><Icon type="delete" /></Button>
                                    </Popconfirm>
                                {/* </Tooltip> */}
                            </div>
                        )} >
                        </Column>
                    </Table>
                </div>
                <CampaignForm
                    // wrappedComponentRef={this.saveFormRef}
                    ref={this.saveFormRef}
                    visible={this.state.formInfo.isModalShow}
                    onClose={this.onClose}
                    onCreate={(campaign) => { this.handleSaveComplete(campaign) }}
                    item={this.state.formInfo.item}
                    users={this.state.users}
                    malls={this.state.malls}
                    formFields={this.state.formFields}
                ></CampaignForm>
            </div>
        )
    }


    getColumnSearchProps = (dataIndex, displayText = "") => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${displayText != "" ? displayText : dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        // onFilter: (value, record) =>
        //     record[dataIndex]
        //         .toString()
        //         .toLowerCase()
        //         .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text => (
            (this.state.filteredInfo.searchedColumn === dataIndex) ?
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.filteredInfo.searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
                : text
        ),
    });
}
