import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Divider, Popconfirm, Row } from 'antd';
import useGlobalContext from '~/services/global-context';
import campaignService from "~/services/campaign-resource-backup/campaign.service";
import { CampaignResourceBackupModel } from "~/services/campaign-resource-backup/campaign-resource-backup.model";
import moment from "moment";

export interface ICampaignResourceBackupProps {
    code: string;
    id: number;
    toDate: Date;
}
const formItemLayout = {
    labelCol: {
        // xs: { span: 24 },
        sm: { span: 12 },
        lg: { span: 12 },
    },
    wrapperCol: {
        // xs: { span: 24 },
        sm: { span: 12 },
        lg: { span: 12 },
    },
};


function downloadFile(fileUrl) {

    const link = document.createElement("a");
    link.href = fileUrl;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setTimeout(() => URL.revokeObjectURL(fileUrl), 100);
}

export const CampaignResourceBackupTab = (props: ICampaignResourceBackupProps) => {
    let [campaignResource, setCampaignResource] = useState(new CampaignResourceBackupModel());
    useEffect(() => {
        if (!props.id) { setCampaignResource(new CampaignResourceBackupModel) }
        else {
            campaignService.getDetail(props.id).then(value => {
                if (value) setCampaignResource(value);
            });
        }
    }, [props.id]);

    let compress = useCallback((campaignId: number) => {
        campaignService.compress(campaignId).then(value => {
            if (value) setCampaignResource(value);
        });
    }, [props])
    let downloadCompressFile = useCallback((campaignId: number) => {
        campaignService.downloadCompressFile(campaignId).then(value => {
            if (value && value.fileUrl) {
                downloadFile(value.fileUrl)
            }
            if (value && value.backupData) setCampaignResource(value.backupData);
        });
    }, [props])

    let deleteResource = useCallback((campaignId: number) => {
        campaignService.deleteResource(campaignId).then(value => {
            if (value) setCampaignResource(value);
        });
    }, [props])

    let deleteCompressFile = useCallback((campaignId: number) => {
        campaignService.deleteCompressFile(campaignId).then(value => {
            if (value) setCampaignResource(value);
        });
    }, [props])

    let toDate = new Date(props.toDate);
    let campaignDone = toDate && new Date().getTime() > toDate.getTime();
    let isCompressed = !!campaignResource.compressBy;
    let isDownloaded = !!campaignResource.downloadBy;
    let isDeletedResource = !!campaignResource.deletedBy;
    let isDeletedCompress = !!campaignResource.deletedCompressBy;
    console.log({ campaignResource, isCompressed, isDeletedCompress });
    console.log({ isCompressed, isDeletedCompress, "result": !isCompressed && isDeletedCompress });
    return !campaignDone ?
        <div style={{ textAlign: "center" }}>
            <h3>Chương chưa hoàn thành, tính năng chỉ áp dụng cho các campaign đã chạy xong.</h3>
        </div>
        : (<div style={{ minHeight: "400px" }}>
            <Row>
                <Col span={6}><Button onClick={() => compress(props.id)} type="primary" disabled={isCompressed} > Tạo file Backup </Button></Col>
                <Col span={6}><Button onClick={() => downloadCompressFile(props.id)} type="primary" disabled={!isCompressed || isDeletedCompress} > Download file Backup </Button></Col>

                <Col span={6}>
                    <Popconfirm title="Bạn chắc chắn muốn xoá 'Hình ảnh hoá đơn' của chương trình? 
                    Hình ảnh sau khi xoá sẽ không thể khôi phục!" onConfirm={() => { deleteResource(props.id) }}>
                        <Button type="danger" disabled={!isCompressed || isDeletedResource} > Xoá resource </Button>
                    </Popconfirm>

                </Col>
                <Col span={6}>
                    <Popconfirm title="Bạn chắc chắn muốn xoá File backup? 
                    File sau khi xoá sẽ không thể khôi phục!" onConfirm={() => { deleteCompressFile(props.id) }}>
                        <Button type="danger" disabled={!isCompressed || !isDownloaded || isDeletedCompress} > Xoá file Backup </Button>
                    </Popconfirm>
                </Col>
            </Row>
            <Divider orientation="left">Thông tin thực hiện</Divider>
            <div>
                <Row>
                    <Col span={6}>
                        Thời gian backup:
                    </Col>
                    <Col span={6} style={{ fontWeight: "bold" }}>{!campaignResource.compressDate ?
                        "" : moment(campaignResource.compressDate).format("DD-MM-YYYY hh:mm:ss")}
                    </Col>
                    <Col span={6}>
                        Người backup:
                    </Col>
                    <Col span={6} style={{ fontWeight: "bold" }}>{campaignResource.compressByName}
                    </Col>
                </Row>
                <Row>
                    <Col span={6}>
                        Thời gian download:
                    </Col>
                    <Col span={6} style={{ fontWeight: "bold" }}>{!campaignResource.downloadDate ?
                        "" : moment(campaignResource.downloadDate).format("DD-MM-YYYY hh:mm:ss")}
                    </Col>
                    <Col span={6}>
                        Người download:
                    </Col>
                    <Col span={6} style={{ fontWeight: "bold" }}>{campaignResource.downloadByName}
                    </Col>
                </Row>
                <Row>
                    <Col span={6}>
                        Thời gian xoá resource:
                    </Col>
                    <Col span={6} style={{ fontWeight: "bold" }}>{!campaignResource.deletedDate ?
                        "" : moment(campaignResource.deletedDate).format("DD-MM-YYYY hh:mm:ss")}
                    </Col>
                    <Col span={6}>
                        Người xoá resource:
                    </Col>
                    <Col span={6} style={{ fontWeight: "bold" }}>{campaignResource.deletedByName}
                    </Col>
                </Row>
                <Row>
                    <Col span={6}>
                        Thời gian xoá file backup:
                    </Col>
                    <Col span={6} style={{ fontWeight: "bold" }}>{!campaignResource.deletedCompressDate ?
                        "" : moment(campaignResource.deletedCompressDate).format("DD-MM-YYYY hh:mm:ss")}
                    </Col>
                    <Col span={6}>
                        Người xoá file backup:
                    </Col>
                    <Col span={6} style={{ fontWeight: "bold" }}>{campaignResource.deletedCompressByName}
                    </Col>
                </Row>
            </div>



        </div >);
}
