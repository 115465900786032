import { apiService } from "../api/api.service";
import { AppVersionSetting } from "./version-setting.model";

class AppVersionSettingService {
    async getData() {
        let result = await apiService.get("app-version-setting/list", { pageIndex: 1, pageSize: 999999 });
        if (!apiService.generalProcessDataResponse(result)) return [];
        return result.data;
    }

    async delete(id: number) {
        let result = await apiService.put("app-version-setting", { id: id, isDeleted: true });
        if (!apiService.generalProcessDataResponse(result)) return false;
        return true;
    }
    async unDelete(id: number) {
        let result = await apiService.put("app-version-setting", { id: id, isDeleted: false });
        if (!apiService.generalProcessDataResponse(result)) return false;
        return true;
    }
   
    async save(appVersionSetting:AppVersionSetting){
        let result = await apiService.post("app-version-setting", appVersionSetting);
        if (!apiService.generalProcessDataResponse(result)) return null;
        return result;
    }
}
export default new AppVersionSettingService();