
export class Mall {
    id:number;
    name: string;
    address?: string;
    description?: string;
    is_deleted?: boolean;

    constructor(){
        this.id = null;
        this.name = "";
        this.description = "";
        this.is_deleted = false;
    }

}