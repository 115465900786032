import { Role } from "../models/role";
import { Mall } from "../mall/mall.model";

export class User {

    id:number;
    fullname: string;

    username: string;

    phone: string;

    permissions: {code: string, name: string}[];

    email: string;
    roles: Role[];
    malls: Mall[];
    isActive: boolean;

    constructor(){
        this.id = null;
        this.email = "";
        this.fullname = "";
        this.phone = "";
        this.malls = [];
        this.isActive = false;
        this.permissions = [];
    }
}