
export class AwardMallSetting {
    id: number;
    awardId: number
    mallId: number
    total: number
    totalPerDay: number
    rate: number

    constructor() {
        this.total = 0;
        this.totalPerDay = 0;
        this.rate = 0;
    }

}