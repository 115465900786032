import { Button, Form, Icon, DatePicker, Input, Popconfirm, Radio, Switch, Table, Tabs, Tooltip, Transfer, Col } from 'antd';
import { Row } from 'antd/es/grid';
import Column from 'antd/lib/table/Column';
import { TransferItem } from 'antd/lib/transfer';
import { log } from 'console';
import React from 'react';
import Highlighter from 'react-highlight-words';
import { toast } from 'react-toastify';
import { HttpStatus } from '~/common/enums/const';
import { Editor } from '~/components/Editor';
import campaignService from '~/services/campaign/campaign.service';
import { LoyaltyGameCampaignModel } from '~/services/loyalty-game/loyalty-game-campaign-model';
import { LoyaltyGameDetailAdminModel, LoyaltyGameFrame } from '~/services/loyalty-game/loyalty-game.model';
import loyaltyGameService from '~/services/loyalty-game/loyalty-game.service';
import { LoyaltyGameFrameComponent } from './loyalty-game-frame.component';
import { LoyaltyCampaignLinked } from '~/services/campaign/campaign.model';
import moment from 'moment';
import { RangePickerValue } from 'antd/lib/date-picker/interface';
import { useLocation, withRouter } from 'react-router-dom';
import history from '~/common/utils/browser-history';

const { RangePicker } = DatePicker;

const formItemLayout = {
    labelCol: {
        // xs: { span: 24 },
        sm: { span: 8 },
        lg: { span: 8 },
    },
    wrapperCol: {
        // xs: { span: 24 },
        sm: { span: 16 },
        lg: { span: 16 },
    },
};

const { TabPane } = Tabs;
const loyaltyGameCode = 'tich-tem-rinh-qua-cho-be';
export default class LoyaltyPageReport extends React.Component<{ history: any }, {}> {
    state = {
        checkinData: { data: [], total: 0 },
        exchangeData: { data: [], total: 0 },
        checkinFilters: {
            search: "",
            fromDate: moment().subtract(7, 'day'),
            toDate: moment()
        },
        exchangeFilters: {
            search: "",
            fromDate: moment().subtract(7, 'day'),
            toDate: moment()
        },
        tabIndex: "1",
        loading: false,
    }

    onDateCheckinFilterChange(dateRange: RangePickerValue) {
        let { checkinFilters } = this.state;
        checkinFilters.fromDate = dateRange[0];
        checkinFilters.toDate = dateRange[1];
        this.setState({ ...this.state, checkinFilters });
    }
    onDateExchangeFilterChange(dateRange: RangePickerValue) {
        let { exchangeFilters } = this.state;
        exchangeFilters.fromDate = dateRange[0];
        exchangeFilters.toDate = dateRange[1];
        this.setState({ ...this.state, exchangeFilters });
    }

    loadReportCheckin() {
        let { search, fromDate, toDate } = this.state.checkinFilters;

        loyaltyGameService.getReport({
            search,
            fromDate: fromDate.startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            toDate: toDate.endOf('day').format('YYYY-MM-DD HH:mm:ss')
        })
            .then(rs => {
                this.setState({ checkinData: rs.data })
                console.log(rs);

            })
    }
    loadExportCheckin() {
        let { search, fromDate, toDate } = this.state.checkinFilters;

        loyaltyGameService.getExportReport({
            search,
            fromDate: fromDate.startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            toDate: toDate.endOf('day').format('YYYY-MM-DD HH:mm:ss')
        })

    }

    loadReportExchange() {
        let { search, fromDate, toDate } = this.state.exchangeFilters;

        loyaltyGameService.getReportExchange({
            search,
            fromDate: fromDate.startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            toDate: toDate.endOf('day').format('YYYY-MM-DD HH:mm:ss')
        })
            .then(rs => {
                this.setState({ exchangeData: rs.data })

            })
    }
    loadExportExchange() {
        let { search, fromDate, toDate } = this.state.exchangeFilters;

        loyaltyGameService.getExportExchange({
            search,
            fromDate: fromDate.startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            toDate: toDate.endOf('day').format('YYYY-MM-DD HH:mm:ss')
        })

    }

    onTabActiveChange(tabIndex) {
        console.log("go to here 1", "history.location.pathname");

        this.setState({ tabIndex });
    };

    render() {
        const dateFormat = 'DD/MM/YYYY';
        return (
            <div className="content">
                <div className="row" style={{ marginBottom: "15px" }}> <h2 style={{ textAlign: "center", textTransform: "uppercase" }}>BÁO CÁO CHƯƠNG TRÌNH LOYALTY</h2> </div>
                <Tabs defaultActiveKey="1" activeKey={this.state.tabIndex} onChange={this.onTabActiveChange.bind(this)}>
                    <TabPane tab={<span> <Icon type="dashboard" /> Thống kê checkin </span>} key="1">
                        <Row>
                            <Col xs={24} md={11} lg={11} xl={8} >
                                Thời gian thống kê: <RangePicker style={{ width: "250px" }}
                                    onChange={this.onDateCheckinFilterChange.bind(this)}
                                    value={[this.state.checkinFilters.fromDate, this.state.checkinFilters.toDate]}
                                    format={dateFormat} />

                            </Col>
                            <Col xs={24} md={7} lg={7} xl={8} >
                                <Input placeholder='Nội dụng tìm kiếm'
                                    value={this.state.checkinFilters.search}
                                    onChange={(e) => {
                                        let { checkinFilters } = this.state;
                                        checkinFilters.search = e.target.value;
                                        this.setState({ ...this.state, checkinFilters })
                                    }}
                                />

                            </Col>
                            <Col xs={24} md={6} lg={6} xl={8} >
                                <Button type='primary' style={{ float: 'right', }}
                                    onClick={this.loadExportCheckin.bind(this)}
                                >Export Excel</Button>
                                <Button type='primary' style={{ float: 'right', marginRight: "15px" }}
                                    onClick={this.loadReportCheckin.bind(this)}
                                > Report </Button>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: "15px" }}>
                            <Table
                                rowKey="id"
                                showHeader={true}
                                size="small"
                                loading={this.state.loading}
                                //expandedRowRender={record => <p style={{ margin: 0 }}>{record.description}</p>}
                                dataSource={this.state.checkinData.data}
                                pagination={{ total: this.state.checkinData.total, position: 'both' }}
                                scroll={{ y: 450 }}
                            >
                                <Column title="Id" dataIndex="id" key="id" render={(text, record) => (text)}></Column>
                                <Column title="Họ tên" dataIndex="name" key="name" render={(text, record) => (text)}></Column>
                                <Column title="Số điện thoại" dataIndex="phone" key="phone" render={(text, record) => (text)}></Column>
                                <Column title="Ngày tham gia" dataIndex="createdDate" key="createdDate" render={(text, record) => (text)}></Column>
                                <Column title="Chi nhánh" dataIndex="mallName" key="mallName" render={(text, record) => (text)}></Column>

                            </Table>
                        </Row>
                    </TabPane>
                    <TabPane tab={<span> <Icon type="dashboard" /> Thống kê đổi quà </span>} key="2">
                        <Row>
                            <Col xs={24} md={11} lg={11} xl={8} >
                                Thời gian thống kê: <RangePicker style={{ width: "250px" }}
                                    onChange={this.onDateExchangeFilterChange.bind(this)}
                                    value={[this.state.checkinFilters.fromDate, this.state.exchangeFilters.toDate]}
                                    format={dateFormat} />

                            </Col>
                            <Col xs={24} md={7} lg={7} xl={8} >
                                <Input placeholder='Nội dụng tìm kiếm'
                                    value={this.state.exchangeFilters.search}
                                    onChange={(e) => {
                                        let { exchangeFilters } = this.state;
                                        exchangeFilters.search = e.target.value;
                                        this.setState({ ...this.state, exchangeFilters })
                                    }}
                                />

                            </Col>
                            <Col xs={24} md={6} lg={6} xl={8} >
                                <Button type='primary' style={{ float: 'right', }}
                                    onClick={this.loadExportExchange.bind(this)}
                                >Export Excel</Button>
                                <Button type='primary' style={{ float: 'right', marginRight: "15px" }}
                                    onClick={this.loadReportExchange.bind(this)}
                                > Report </Button>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: "15px" }}>
                            <Table
                                rowKey="id"
                                showHeader={true}
                                size="small"
                                loading={this.state.loading}
                                //expandedRowRender={record => <p style={{ margin: 0 }}>{record.description}</p>}
                                dataSource={this.state.exchangeData.data}
                                pagination={{ total: this.state.exchangeData.total, position: 'both' }}
                                scroll={{ y: 450 }}
                            >
                                <Column title="Id" dataIndex="id" key="id" render={(text, record) => (text)}></Column>
                                <Column title="Chi nhánh" dataIndex="mallName" key="mallName" render={(text, record) => (text)}></Column>
                                <Column title="Chương trình" dataIndex="campaignName" key="campaignName" render={(text, record) => (text)}></Column>
                                <Column title="Họ tên" dataIndex="name" key="name" render={(text, record) => (text)}></Column>
                                <Column title="Số điện thoại" dataIndex="phone" key="phone" render={(text, record) => (text)}></Column>
                                <Column title="Ngày đổi" dataIndex="createdDate" key="createdDate" render={(text, record) => (text)}></Column>
                                <Column title="Tem đổi" dataIndex="awardName" key="awardName" render={(text, record) => (text)}></Column>
                                <Column title="Lần đổi" dataIndex="groupExchange" key="groupExchange" render={(text, record) => (text)}></Column>

                            </Table>
                        </Row>
                    </TabPane>
                </Tabs>

            </div>
        );
    }

}