import { Button, Form, Icon, Input, InputNumber, Popconfirm, Radio, Switch, Table, Tabs, Tooltip, Transfer } from 'antd';
import { Row } from 'antd/es/grid';
import Column from 'antd/lib/table/Column';
import { TransferItem } from 'antd/lib/transfer';
import { log } from 'console';
import React from 'react';
import Highlighter from 'react-highlight-words';
import { toast } from 'react-toastify';
import { HttpStatus } from '~/common/enums/const';
import { Editor } from '~/components/Editor';
import campaignService from '~/services/campaign/campaign.service';
import { LoyaltyGameCampaignModel } from '~/services/loyalty-game/loyalty-game-campaign-model';
import { LoyaltyGameDetailAdminModel, LoyaltyGameFrame } from '~/services/loyalty-game/loyalty-game.model';
import loyaltyGameService from '~/services/loyalty-game/loyalty-game.service';
import { LoyaltyGameFrameComponent } from './loyalty-game-frame.component';
import { LoyaltyCampaignLinked } from '~/services/campaign/campaign.model';
import history from '~/common/utils/browser-history';


const renderRadio = (item) => (
    <Radio.Group value={item.checked}>
        <Radio value={true} />
    </Radio.Group>
);

const { TabPane } = Tabs;
export default class LoyaltyPage extends React.Component<{ history: any }, {}> {
    state = {
        loyaltyGameCode: "",
        isChange: false,
        tabIndex: "1",
        loading: true,
        lotaltyCampaignList: [] as LoyaltyGameCampaignModel[],
        listCampaignTargetKeys: [],
        gameDetail: new LoyaltyGameDetailAdminModel(),
        selectedKeys: [],
        targetSelectedKeys: []
    }

    componentWillMount() {
        let path = history.location.pathname.split('/');
        this.setState({ ...this.state, loyaltyGameCode: path[path.length - 1] }, () => {
            this.loadGameData();
        })
    }

    createNewFrame() {
        let state = this.state;
        let frame = new LoyaltyGameFrame()
        frame.loyaltyGameId = this.state.gameDetail.id
        state.gameDetail.loyaltyGameFrames.push(frame);
        this.setState({ ...state, isChange: true })
    }

    loadGameData() {
        if (!this.state.loyaltyGameCode) return;
        loyaltyGameService.getData(this.state.loyaltyGameCode).then(gameDetail => {
            if (!gameDetail.id) { 
                // toast("Chương trình không tồn tại"); 
                return;
            }
            this.setState({ ...this.state, gameDetail, loading: false, isChange: false });
            let listCampaignTargetKeys = gameDetail.linkedCampaigns.map(cp => cp.campaignId.toString());

            campaignService.getLoyaltyGameCampaign().then(lotaltyCampaignList => {
                this.setState({ ...this.state, lotaltyCampaignList, listCampaignTargetKeys });
            })
        })
    }

    onTabActiveChange(tabIndex) {
        this.setState({ ...this.state, tabIndex });
    };

    transFilter = (inputValue, option) => {
        return option.title.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;
    }

    handleSave() {
        let gameDetail = this.state.gameDetail;
        gameDetail.linkedCampaigns = [];
        this.state.listCampaignTargetKeys.map((item, index) => {
            gameDetail.linkedCampaigns.push({
                id: null,
                campaignId: Number(item),
                loyaltyGameId: gameDetail.id,
                sequence: index
            })
        })
        loyaltyGameService.update(gameDetail)
            .then((rs) => {
                if (rs.code == 200) toast.success("Lưu chương trình thành công")
            })
            .catch((e) => { toast.error("Có lỗi xảy ra, vui lòng thử lại") });
    }

    handleCampaignChange = (targetKeys: string[]) => {
        targetKeys = targetKeys.filter(item => !!item)
        console.log(targetKeys);

        this.setState({ ...this.state, listCampaignTargetKeys: this.reorderTargetKeys(targetKeys), isChange: true }, () => {
            console.log({ target: { ...this.state.listCampaignTargetKeys } });

        });

    };

    reorderTargetKeys = (targetKeys) => {
        const firstItem = targetKeys.shift();
        const reorderedKeys = [...targetKeys, firstItem];
        return reorderedKeys;
    };


    handleSelectChange = (sourceSelectedKeys: string[], targetSelectedKeys: string[]) => {
        // this.setState({...this.state, selectedKeys:[...sourceSelectedKeys, ...targetSelectedKeys ]})

        if (sourceSelectedKeys.length > 0) {
            this.setState({ ...this.state, selectedKeys: [sourceSelectedKeys.pop(), ...targetSelectedKeys], isChange: true })
        } else {
            this.setState({ ...this.state, selectedKeys: [...targetSelectedKeys], isChange: true })
        }


    };

    render() {
        const { state } = this;
        const gameName = state.gameDetail.name;
        const listCampaign = this.state.lotaltyCampaignList.map(item => { return { title: item.name, key: item.id.toString() } as TransferItem })
        const loyaltyGameFrames = this.state.gameDetail.loyaltyGameFrames;
        return (
            <div className="content">
                <div className="row"> <h2 style={{ textAlign: "center", textTransform: "uppercase" }}>Cấu hinh game {gameName}</h2> </div>
                <Row>
                    {this.state.isChange && <Button type="default" onClick={this.loadGameData.bind(this)}>Bỏ qua thay đổi</Button>}
                    <Popconfirm placement="left" title={"Xác nhận lựu thông tin?"} onConfirm={() => {
                        this.handleSave()
                    }} okText="Lưu" cancelText="Bỏ qua">
                        <Button type="primary" style={{ float: "right" }}>Lưu chương trình</Button>
                    </Popconfirm>

                </Row>
                <Tabs defaultActiveKey="2" activeKey={this.state.tabIndex} onChange={this.onTabActiveChange.bind(this)}>
                    <TabPane tab={<span> <Icon type="info-circle" /> Thông tin chung </span>} key="1">
                        <Row>
                            Kích hoạt: <Switch checked={state.gameDetail.status} onChange={(checked) => {
                                let { gameDetail } = this.state;
                                gameDetail.status = checked;
                                this.setState({ ...this.state, gameDetail })
                            }} />
                        </Row>
                        <Row>
                            Giới hạn số lượng tem đổi: <InputNumber value={this.state.gameDetail.exchangeNumberLimit} onChange={(value) => {
                                let { gameDetail } = this.state;
                                gameDetail.exchangeNumberLimit = value;
                                this.setState({ ...this.state, gameDetail })
                            }} />
                            {/* <Form.Item label="Giới hạn số lượng tem đổi: "> </Form.Item>*/}

                        </Row>
                        <Row>
                            <Form.Item label="Thông điệp khi dừng chương trình">
                                {/* {getFieldDecorator('description', {})(<TextArea rows={7} />)} */}
                                <Editor
                                    content={state.gameDetail.messageOnStop}
                                    onContentChanged={(content) => {
                                        let { gameDetail } = this.state;
                                        gameDetail.messageOnStop = content;
                                        this.setState({ ...state, gameDetail })
                                    }} height="300px" />
                            </Form.Item>
                        </Row>
                    </TabPane>
                    <TabPane tab={<span> <Icon type="reconciliation" /> Cấu hình frame </span>} key="2">
                        <Row>
                            <Button onClick={this.createNewFrame.bind(this)}>Thêm</Button>
                        </Row>
                        {loyaltyGameFrames && loyaltyGameFrames.map((item, index) =>
                            <LoyaltyGameFrameComponent key={index}
                                frame={item} setFrame={(item, index) => {
                                    let state = this.state;
                                    state.gameDetail.loyaltyGameFrames[index] = item;
                                    this.setState({ ...state, isChange: true })
                                }}
                                index={index}
                                deleteByIndex={(index) => {
                                    let state = this.state;
                                    delete state.gameDetail.loyaltyGameFrames[index];

                                    this.setState({ ...state, isChange: true })
                                }}
                            />
                        )}
                    </TabPane>
                    <TabPane tab={<span> <Icon type="reconciliation" /> Chuỗi chiến dịch </span>} key="3">
                        <div style={{ display: "flex", textAlign: "center", alignItems: "center" }}>
                            <Transfer style={{ flex: 1, width: "100%", height: "100%" }}
                                listStyle={{
                                    width: "45%",
                                    textAlign: "left",
                                    height: 400,
                                }}
                                lazy={false}
                                dataSource={listCampaign}
                                onSelectChange={this.handleSelectChange}
                                showSearch
                                filterOption={this.transFilter}
                                selectedKeys={this.state.selectedKeys}
                                targetKeys={this.state.listCampaignTargetKeys}
                                onChange={this.handleCampaignChange}
                                render={(item) => item.title}

                            />
                        </div>
                    </TabPane>
                </Tabs>
            </div>
        );
    }

}