import React, { Component, useEffect, useState } from 'react';
import { Select } from "antd";

const { Option } = Select;

export interface ProfileIdentifyFieldProps {
    formFieldOptions: any,
    value?: string,
    onChange?: (string) => void
}

export const ProfileIdentifyFieldComponent = React.forwardRef((props: ProfileIdentifyFieldProps, ref: any) => {
    let [field1, setField1] = useState("");
    let [op, setOp] = useState("|");
    let [field2, setField2] = useState("");
    let { value, formFieldOptions } = props;
    if (!value) value = "|";
    useEffect(() => {
        if (!value) value = "|";
        let operator;
        operator = value.indexOf("+") >= 0 ? "+" : "|";
        let arr = value.split(operator);
        if (operator != op) setOp(operator);
        if (arr[0] != field1) setField1(arr[0]);
        let v2 = arr.length > 1 ? arr[1] : "";
        if (v2 != field2) setField2(v2);
    }, [props.value])

    const handleChange = () => {
        let value = (field1 || "") + op + (field2 || "")
        props.onChange(value)
    }
    

    return <>
        <Select allowClear value={field1} style={{ width: "250px", maxWidth: "30%", display: "inline" }}
            onChange={(value) => { field1 = value; handleChange() }}
        >
            {formFieldOptions}
        </Select>
        <Select value={op} style={{ width: "100px", maxWidth: "30%", display: "inline", margin: "0 10px", textAlign: "center" }}
            onChange={(value) => { op = value; handleChange() }}
        >
            <Option style={{ textAlign: "center" }} key={1} value={"|"} >Hoặc</Option>
            <Option style={{ textAlign: "center" }} key={2} value={"+"} >Và</Option>
        </Select>
        <Select allowClear value={field2} style={{ width: "250px", maxWidth: "30%", display: "inline" }}
            onChange={(value) => { field2 = value; handleChange() }}
        >
            {formFieldOptions}
        </Select>
    </>
})
