import { apiService, BaseApiUrl, IApiResponse } from "../api/api.service";
import Authenticate from "../authenticate/authenticate";
import { LoyaltyGameDetailAdminModel } from "./loyalty-game.model";

class LoyaltyGameService {
    async getData(code: string): Promise<LoyaltyGameDetailAdminModel> {
        let result = await apiService.get("loyalty-game/admin/detail", { code });
        if (!apiService.generalProcessDataResponse(result)) return new LoyaltyGameDetailAdminModel();
        return result.data;
    }

    async update(adminDetail: LoyaltyGameDetailAdminModel) {
        let result = await apiService.post("loyalty-game/admin/save", { ...adminDetail });
        if (!apiService.generalProcessDataResponse(result)) return null;
        return result;
    }

    async getReport(searchCondition) {
        let result = await apiService.get("loyalty-game/report-checkin", searchCondition);
        if (!apiService.generalProcessDataResponse(result)) return null;
        return result;
    }

    async getReportExchange(searchCondition) {
        let result = await apiService.get("loyalty-game/report-exchange", searchCondition);
        if (!apiService.generalProcessDataResponse(result)) return null;
        return result;
    }

    async getExportReport(searchCondition) {
        let url = BaseApiUrl + "loyalty-game/export-checkin";
        if (searchCondition) {
            url += "?" + Object.keys(searchCondition).map(function (k) {
                return encodeURIComponent(k) + '=' + encodeURIComponent(searchCondition[k])
            }).join('&')
        }
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': "*",
                'x-api-key': 'e8839f6a-0c6e-11ea-8d71-362b9e155667',
                'Authorization': "Bearer " + Authenticate.getUserSessionToken()
            },
        })
            .then(response => response.blob())
            .then(blob => {
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = 'report-checkin.xlsx';
                link.click();
            })
            .catch(error => console.error(error));

    }
    async getExportExchange(searchCondition) {
        let url = BaseApiUrl + "loyalty-game/export-exchange";
        if (searchCondition) {
            url += "?" + Object.keys(searchCondition).map(function (k) {
                return encodeURIComponent(k) + '=' + encodeURIComponent(searchCondition[k])
            }).join('&')
        }
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': "*",
                'x-api-key': 'e8839f6a-0c6e-11ea-8d71-362b9e155667',
                'Authorization': "Bearer " + Authenticate.getUserSessionToken()
            },
        })
            .then(response => response.blob())
            .then(blob => {
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = 'report-exchange.xlsx';
                link.click();
            })
            .catch(error => console.error(error));

    }
}


export default new LoyaltyGameService();