
export class AppParams {
    id:number;
    group: string;
    code: string;
    name: string;
    value: string;

    constructor(){
        this.id = null;
        this.name = "";
        this.code= "";
        this.value= "";
        this.group = "";
    }

}