import { apiService, IApiResponse } from "../api/api.service";

class PrivacyPolicyService {
    async getData() {
        let result = await apiService.get("privacy-policy");
        if (!apiService.generalProcessDataResponse(result)) return [];
        return result.data;
    }

    async save(content: string) {
        let result = await apiService.post("privacy-policy", {content});
        if (!apiService.generalProcessDataResponse(result)) return [];
        return result.data;
    }
}


export default new PrivacyPolicyService();