import { Role } from './role';
import { Mall } from '../mall/mall.model';

export class UserSession {
    id: number;
    token: string;
    email: string;
    malls:Mall[];
    fullname: string;
    username: string;
    roles: Role[];
}