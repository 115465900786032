
export class JoinCondition {
    id:number;
    campaignId: number;
    content: string;
    

    constructor(campaignId: number){
        this.id = null;
        this.content = "";
        this.campaignId = campaignId;
    }
}