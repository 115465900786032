import { Button, Icon, Input, Popconfirm, Table, Tooltip } from 'antd';
import Column from 'antd/lib/table/Column';
import React from 'react';
import Highlighter from 'react-highlight-words';
import { User } from '~/services/user/user.model';
import deviceService from '~/services/device-info/device.service';
import moment from 'moment';

interface IActiveBtnProps {
    is_deleted: any;
    activeFn: any,
    inActiveFn: any,
    itemId: number

}


export default class DevicePage extends React.Component<{ history: any }, {}> {
    formRef: any;
    state = {
        tableLoading: true,
        data: [],
        
    }
    searchInput: Input;

    

    componentWillMount() {
        this.loadListData();

    }

    loadListData() {
        deviceService.getData().then(datas => {
            let data = datas.map(item => { item.key = item.deviceId; return item; })
            this.setState({ data, tableLoading: false });
        })
    }

    render() {

        const { state } = this;
        return (
            <div className="content">
                <div className="row"> <h2 style={{ textAlign: "center", textTransform: "uppercase" }}>Danh sách thiết bị</h2> </div>

                
                <br />
                <div style={{ overflowX: "scroll" }}>
                    <Table
                        showHeader={true}
                        size="small"
                        style={{ }}
                        loading={this.state.tableLoading}
                        //expandedRowRender={record => <p style={{ margin: 0 }}>{record.description}</p>}
                        dataSource={this.state.data}
                    >
                        <Column title="Id thiết bị" dataIndex="deviceId" key="deviceId" sortOrder="descend"  ></Column>
                        <Column title="Tên thiết bị" dataIndex="deviceName" key="deviceName" sortOrder="descend"  ></Column>
                        <Column title="Tên User" dataIndex="username" key="username" sortOrder="descend"  ></Column>
                        <Column title="Tên chi nhánh" dataIndex="brandName" key="brandName" sortOrder="descend"  ></Column>
                        <Column title="Version App" dataIndex="appVersion" key="appVersion" sortOrder="descend"  ></Column>
                        <Column title="Ngày update" dataIndex="updatedDate" key="updatedDate" sortOrder="descend" render={ (
                            text,record:any, index) => { 
                             return moment(record.updateDate).format("DD-MM-YYYY HH:mm")  } }  ></Column>
                        
                    </Table>
                </div>
            
            </div>
        );
    }
}
