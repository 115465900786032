import { Button, Card, Checkbox, Form, Icon, Input, Modal, Popover, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import React from 'react';
import { ROLES } from '~/common/enums/const';
import { User } from '~/services/user/user.model';
import { Mall } from '~/services/mall/mall.model';

const { Option } = Select;
export interface IUserFormModalProps extends FormComponentProps {
    visible: boolean;
    // closeCallback: () => void;
    onCancel: () => void;
    onCreate: (user: any) => void;
    user: User;
    malls: Mall[]
}

const permissionList = [
    { label: 'Admin CRM', code: 'admin', checked: false },
    { label: 'Record Data', code: 'client', checked: false }
];

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};

class UserFormModal extends React.Component<IUserFormModalProps> {
    formRef: any;
    state = {
        loading: false,
        user: new User(),
        permissionList: permissionList
    }
    componentWillReceiveProps(nextProps: Readonly<IUserFormModalProps>, nextContext: any): void {
        let permissions = nextProps.user.permissions.map(item => item.code);

        let permList = [
            { label: 'Admin CRM', code: 'admin', checked: false },
            { label: 'Record Data', code: 'client', checked: false }
        ];

        this.handlePermissionCheckedChange(permissions);

    }
    handlePermissionCheckedChange(codes: string[]) {
        let permList = [
            { label: 'Admin CRM', code: 'admin', checked: false },
            { label: 'Record Data', code: 'client', checked: false }
        ];
        codes.forEach(perm => {
            permList.find(item => item.code === perm).checked = true;
        })
        this.setState({ ...this.state, permissionList: permList }, () => {
            console.log({ permissionList: this.state.permissionList });

        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (err) return;
            values["permissions"] = this.state.permissionList.filter(item => item.checked).map(item => item.code);
            this.props.onCreate(values);
            //this.props.closeCallback();
        });
    };

    render() {
        let { permissionList: permList } = this.state;

        const { getFieldDecorator } = this.props.form;
        let isCreateNew = !(this.props.user && this.props.user.id);
        let fieldPasswordWidth = isCreateNew ? "100%" : "90%";
        let isManager = this.props.user.roles && this.props.user.roles.find(role => role.name === ROLES.Manager.name) != undefined;
        let title = (!isCreateNew ? "Cập nhật tài khoản" : "Tạo tài khoản").toUpperCase();
        return (
            <Modal width="800px" style={{ top: "15px" }} onOk={this.props.onCreate} footer={null} onCancel={this.props.onCancel} visible={this.props.visible}  >
                <div >
                    <Card title={title} bordered={true} style={{ margin: "20px" }}>
                        <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                            <Form.Item label="Tên tài khoản">
                                {getFieldDecorator('fullname', {
                                    rules: [
                                        {
                                            min: 6,
                                            message: 'Tên tài khoản tối thiểu 6 ký tự!',
                                        },
                                        {
                                            required: true,
                                            message: 'Nhập tên tài khoản!',
                                        },
                                    ],
                                })(<Input />)}
                            </Form.Item>
                            <Form.Item label="Tên đăng nhập">
                                {getFieldDecorator('username', {
                                    rules: [
                                        {
                                            min: 6,
                                            message: 'Tên đăng nhập tối thiểu 6 ký tự!',
                                        },
                                        {
                                            required: true,
                                            message: 'Nhập tên đăng nhập!',
                                        },
                                    ],
                                })(<Input autoComplete="false" />)}
                            </Form.Item>
                            <Form.Item label="E-mail">
                                {getFieldDecorator('email', {
                                    rules: [
                                        {
                                            type: 'email',
                                            message: 'Email không hợp lệ',
                                        },
                                        {
                                            required: true,
                                            message: 'Nhập E-mail',
                                        },
                                    ],
                                })(<Input />)}
                            </Form.Item>
                            <Form.Item label="Chi nhánh">
                                {getFieldDecorator('malls', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Chọn chi nhánh',
                                        },
                                    ],
                                })(<Select
                                    mode="multiple"
                                    style={{ width: '100%' }}
                                    placeholder="Chọn chi nhánh"
                                    onChange={(value) => { this.props.form.setFieldsValue({ malls: value }) }}
                                >
                                    {this.props.malls.map(mall => { return (<Option key={mall.id} value={mall.id.toString()}>{mall.name}</Option>) })}
                                </Select>)}
                            </Form.Item>
                            <Form.Item label="Số điện thoại">
                                {getFieldDecorator('phone', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Nhập số điện thoại',
                                        },
                                    ],
                                })(<Input />)}
                            </Form.Item>
                            <Form.Item label="Nhóm tài khoản">
                                {getFieldDecorator('isManager', {
                                    valuePropName: 'checked',
                                    initialValue: isManager,
                                })(<Checkbox>Admin</Checkbox>)}
                            </Form.Item>
                            <Form.Item label="Truy cập tài nguyên">
                                <Checkbox.Group
                                    onChange={this.handlePermissionCheckedChange.bind(this) as any} 
                                    
                                    value={permList.filter(item => item.checked).map(item => item.code)}
                                    options={permissionList.map(({ label, code }) => { return { label, value: code } })} >
                                    {/* {permList.map((item,index) =>
                                        <Checkbox onChange={this.handlePermissionCheckedChange.bind(this)}  key={v4()} checked={true}
                                            value={item.code} >{item.label}
                                        </Checkbox>)} */}
                                </Checkbox.Group>
                            </Form.Item>

                            <Form.Item label="Mật khẩu">
                                {getFieldDecorator('password', {
                                    rules: [
                                        {
                                            required: isCreateNew,
                                            message: "Vui lòng nhập mật khẩu"
                                        },
                                        {
                                            min: 6,
                                            message: 'Mật khẩu nhập tối thiểu 6 ký tự!',
                                        },
                                        {
                                            max: 100,
                                            message: 'Mật khẩu nhập tối đa 100 ký tự!',
                                        },
                                    ],
                                })(<Input type="password" autoComplete="new-password" style={{ width: fieldPasswordWidth, float: "left" }} />)}
                                {!isCreateNew ?
                                    <Popover content="Bạn chỉ nhập khi muốn đổi mật khẩu cho tài khoản này " title="Note">
                                        <Icon type="info-circle" style={{ width: "10%", color: "#03a9f4", paddingTop: "7px", float: "right" }} />
                                    </Popover> : null}
                            </Form.Item>
                            <Form.Item label="Xác nhận mật khẩu">
                                {getFieldDecorator('confirmPassword', {
                                    rules: [
                                        {
                                            required: isCreateNew,
                                            message: "Vui lòng xác nhận mật khẩu"
                                        }, {
                                            min: 6,
                                            message: 'Xác nhận mật khẩu nhập tối thiểu 6 ký tự!',
                                        },
                                        {
                                            max: 100,
                                            message: 'Xác nhận mật khẩu nhập tối đa 100 ký tự!',
                                        },
                                    ],
                                })(<Input type="password" style={{ width: fieldPasswordWidth, float: "left" }} />)}
                                {!isCreateNew ?
                                    <Popover content="Bạn chỉ nhập khi muốn đổi mật khẩu cho tài khoản này " title="Note">
                                        <Icon type="info-circle" style={{ width: "10%", color: "#03a9f4", paddingTop: "7px", float: "right" }} />
                                    </Popover> : null}
                            </Form.Item>
                            <Form.Item {...tailFormItemLayout}>
                                <Button type="primary" htmlType="submit">
                                    {title}
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </div >
            </Modal>
        );
    }
}

const UserForm = Form.create<IUserFormModalProps>({
    onFieldsChange(props, changedFields) {
        // props.onChange(changedFields);
    },
    mapPropsToFields(props) {
        const { user } = props;
        const result = {};
        Object.keys(user).forEach(key => {
            const field = Form.createFormField({
                value: user[key]
            })
            Object.assign(result, {
                [key]: field
            });
        })
        Object.assign(result, {
            "malls": Form.createFormField({ value: user.malls.map(mall => mall.id.toString()) })
        });

        // if (user.permissions) {
        //     Object.assign(result, {
        //         "permissions": Form.createFormField(user.permissions.map(perm => perm.code))
        //     });
        // }
        // else {
        //     Object.assign(result, {
        //         "permissions": Form.createFormField([])
        //     });
        // }
        return result;
    },
    onValuesChange(_, values) {
    }
})(UserFormModal);
export default UserForm;