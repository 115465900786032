import React from 'react';
import JoditEditor from "jodit-react";

import { BaseApiUrl } from "~/services";
import Authenticate from "~/services/authenticate/authenticate";

export const Editor = ({ content, onContentChanged, height }: { content: string, onContentChanged: (string) => void, height?: any }) => {
    onContentChanged(content);
    let joditConfig: any = {
        toolbarStickyOffset: 0, globalFullSize: true, innerHeight: "60vh",
        scrollY: true,
        allowResizeY: true,
        "buttons": ["bold", "italic", "underline", "strikethrough", "eraser",
        "|",
        {
          icon: "fa fa-code",
          name: "source",
          exec: function (editor) {
            editor.toggleMode();
          }
        },
        "|",
            "ul", "ol", "indent", "outdent", "left", "right", "center", "font", "fontsize", "paragraph", "classSpan", "brush", "superscript", "subscript", "image", "video"],
        uploader: {
            url: BaseApiUrl + 'file/uploads',
            format: 'form-data',
            headers: { "Authorization": Authenticate.getUserSessionToken() },
            isSuccess: function (resp) {
                return !resp.error;
            },
            getMessage: function (resp) {
                return resp.msg;
            },
            defaultHandlerSuccess: function (filesRes, resp) {
                for (const file of filesRes) {
                    this["selection"].insertImage(file.fullUrl, null, 250);
                }

            },
            error: function (e) {
                // this.e.fire('errorMessage', [e.getMessage(), 'error', 4000]);
            }
        }
    }
    if (height) joditConfig = { ...joditConfig, height }
    return (<div>
        <JoditEditor
            ref={null}
            value={content}
            config={{ ...joditConfig }}
            // tabIndex={1} // tabIndex of textarea
            onBlur={newContent => { console.log({newContent}); onContentChanged(newContent)}}
        // onBlur={newContent => { this.setState({ editContent: newContent }) }}
        />
    </div>);
}