import { Button, Card, Checkbox, Col, DatePicker, Divider, Form, Icon, Input, Modal, Popover, Radio, Row, Select, Switch, Table, Tabs, Tag, Tooltip, Transfer } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import TextArea from 'antd/lib/input/TextArea';
import Column from 'antd/lib/table/Column';
import React from 'react';
import { toast } from 'react-toastify';
import { idText } from 'typescript';
import { GAME_TYPES, HttpStatus, ROLES } from '~/common/enums/const';
import history from '~/common/utils/browser-history';
import { Editor } from '~/components/Editor';
import Authenticate from '~/services/authenticate/authenticate';
import { Campaign } from '~/services/campaign/campaign.model';
import campaignService from '~/services/campaign/campaign.service';
import { FormFieldSetting } from '~/services/form-field/formfield-setting.model';
import { FormField } from '~/services/form-field/formfield.model';
import formfieldService from '~/services/form-field/formfield.service';
import { JoinCondition } from '~/services/join-condition/join-condition.model';
import { Mall } from '~/services/mall/mall.model';
import { Award, AWardType } from '~/services/models/award';
import { GameSetting } from '~/services/models/game-setting';
import { PriceRange } from '~/services/models/price-range';
import { AwardTabContent, AwardTabContentModel } from './components/award-content-tab';
import { GameInfoTab } from './components/game-info-tab';
import { GameSettingTab } from './components/game-setting-tab';
import JoinConditionForm from './join-condition.modal';
import PriceRangeForm from './price-range.modal';
import { LoyaltyGameSettingTab } from './components/loyalty-game-setting-tab';
import { log } from 'console';
import { User } from '~/services/user/user.model';
import { CampaignResourceBackupTab } from './components/campaign-resource-backup-tab';
// import PriceRangeFormModal from './price-range.model.old';



const { RangePicker } = DatePicker;
const { TabPane } = Tabs;
const { Option } = Select;
export interface IFormModalProps extends FormComponentProps {
  visible: boolean;
  // closeCallback: () => void;
  onCreate: (campaign) => void;
  item: Campaign;
  users: User[],
  malls: Mall[],
  formFields: FormField[],
  onClose: (self: any) => void,
}


const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
    lg: { span: 4 },
    width: '100%',
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
    lg: { span: 20 },
  },
};


class CampaignFormModal extends React.Component<IFormModalProps> {
  state = {
    loading: false,
    item: new Campaign(),
    priceRangeItem: new PriceRange(),
    priceText: '',
    awardText: '',
    tabIndex: "1",
    showAwardModal: false,
    showPriceRangeModal: false,
    awardItem: new Award(),
    joinConditionItem: new JoinCondition(null),
    showJoinConditionModal: false
  }
  componentWillReceiveProps(nextProps: Readonly<IFormModalProps>, nextContext: any): void {
    this.contentEditor = nextProps.form.getFieldValue('description')
  }
  contentEditor = '';
  setContent(content) {
    console.log({ setContent: content })
    // this.props.form.setFieldsValue({description: content})
    this.contentEditor = content;
  }
  // componentWillMount() {
  //     let { item } = this.state;
  //     item = this.props.item;
  // }
  onTabActiveChange(tabIndex) {
    this.setState({ tabIndex });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      let isCreateNew = !(this.props.item && this.props.item.id);
      let action = (!isCreateNew ? "cập nhật chương trình" : "tạo chương trình");
      let message = [];
      if (err) {
        console.log(err);
        let message = err[Object.keys(err)[0]].errors[0].message;
        Modal.error({
          title: 'Thông tin chưa đầy đủ',
          content: 'Thông tin chung của chương trình chưa đầy đủ: ' + message,
        });
        return;
      }
      let saveCampaign = new Campaign();
      Object.assign(saveCampaign, this.props.item);
      if (this.props.item.priceRanges.length === 0) {
        message.push(<li key={1}>Bạn chưa nhập Giá trị hóa đơn</li>)
      }
      else {
        values["priceRanges"] = this.props.item.priceRanges;
      }


      values["joinConditions"] = this.props.item.joinConditions;
      values["code"] = this.props.item.code;

      if (this.props.item.awards.length === 0) {
        message.push(<li key={1}>Bạn chưa nhập Danh sách giải thưởng</li>)
      }
      else {
        values["awards"] = this.props.item.awards;
      }
      // values["inputFields"] = this.props.item.inputFields;
      // values["gameCollectProfile"] = this.props.item.gameCollectProfile;
      // values["gameSetting"] = this.props.item.gameSetting;
      values["description"] = this.contentEditor;

      // if (this.props.item.awardLimitByField) {
      //   let fieldIdentifyRequired = this.props.item.inputFields.filter(ff => ff.code == this.props.item.awardLimitByField);
      //   if (!fieldIdentifyRequired || fieldIdentifyRequired.length == 0 || !fieldIdentifyRequired[0].isRequire || !fieldIdentifyRequired[0].isShow) {
      //     let fieldName = FormFieldSetting.getNameByField(this.props.item.awardLimitByField)
      //     message.push(<li key={3}>Trường phân biệt khách hàng '{fieldName}' không được hiển thị hoặc không yêu cầu nhập  </li>)
      //   }

      // }

      if (this.props.form.getFieldValue("mallSelectedIds").length === 0) {
        message.push(<li key={3}>Bạn chưa chọn danh sách chi nhánh áp dụng</li>)
      }
      Object.assign(saveCampaign, values);

      let saveFn = this.saveCampaign.bind(this);
      if (message.length > 0) {
        Modal.confirm({
          onOk() {
            saveFn(saveCampaign);
          },
          title: 'Thông tin chưa đầy đủ, bạn có muốn ' + action + '?',
          content: <ul> {message.map(i => i)}</ul>,
        });
        return;
      }
      this.saveCampaign(saveCampaign);
    });
  };




  saveCampaign(formValues) {


    if (this.props.item.id) {
      formValues.id = this.props.item.id;
    }

    //formValues.priceRanges = this.props.item.priceRanges;
    formValues.fromDate = formValues.dateRange[0].startOf('day').toDate();
    formValues.toDate = formValues.dateRange[1].endOf('day').toDate()
    delete formValues.dateRange;

    formValues.malls = formValues.mallSelectedIds.map(id => {
      let mall = new Mall();
      mall.id = id;
      return mall;
    })
    // formValues.awardLimitByField = (formValues.awardLimitByField1 || "") + "," + (formValues.awardLimitByField2 || "")
    this.setState({ loading: true })
    campaignService.save(formValues).then(response => {
      if (response.code === HttpStatus.OK) {
        toast.success("Lưu chương trình thành công.");
        this.setState({ mallSelectedIds: [] })
        this.props.onCreate(response.data);
        return;
      }
      if (response.code === HttpStatus.Unauthorization) {
        toast.error("Phiên làm việc đã hết hạn, vui lòng đăng nhập lại");
        Authenticate.logout();
        history.push("/login");
        return;

      }
      if (response.code === HttpStatus.Forbidden) {
        toast.error("Bạn không có quyền thêm/chỉnh sửa trên chương trình");
        return;
      }
      toast.error("Có lỗi xảy ra, vui lòng thử lại");
    }).finally(() => {
      this.setState({ loading: false })
    })
  }

  onPriceInputChange(e) {
    this.setState({ priceText: e.target.value });
  }

  handleDeletePriceRanges(name) {
    let priceRanges = this.props.item.priceRanges.filter(item => item["name"] != name);
    this.props.item.priceRanges = [];
    Object.assign(this.props.item, {
      ...this.props.item, priceRanges
    });
    this.setState({})
  }

  handleDeleteJoinCondition(content) {
    let joinConditions = this.props.item.joinConditions.filter(item => item["content"] != content);
    this.props.item.joinConditions = [];
    Object.assign(this.props.item, {
      ...this.props.item, joinConditions
    });
    this.setState({})
  }

  // handleAddPriceRange() {
  //   if (this.state.priceText === "") {
  //     toast.info("Vui lòng nhập giá áp dụng");
  //     return;
  //   }

  //   let priceRanges: any = this.props.item.priceRanges;
  //   priceRanges.push({ name: this.state.priceText, value: this.state.priceText });
  //   Object.assign(this.props.item, {
  //     ...this.props.item, priceRanges
  //   });

  //   this.setState({ priceText: '' });
  // }

  onAwardChange(e) {
    this.setState({ awardText: e.target.value });
  }

  onAwardDelete(award: Award) {
    let item = this.props.item;
    let _this = this;
    Modal.confirm({
      title: `Bạn có muốn xóa giải thưởng: "${award.name}"?`,
      onOk() {
        let index = item.awards.findIndex(item => item.id == award.id);
        if (index >= 0) item.awards.splice(index, 1);
        _this.setState({})
      },
      onCancel() {
      },
    });
  }

  handleDeleteAward(name) {
    let awards: any[] = this.props.item.awards.filter(item => item["name"] != name);
    Object.assign(this.props.item, {
      ...this.props.item,
      awards
    })
    this.setState({});
  }

  handleAddAwardsKeyDown(e: any) {
    if (e.keyCode === 13) {
      this.handleAddAwards();
      e.preventDefault();
    }
  }

  handleAddAwards() {
    // if (this.state.awardText === "") {
    //     toast.info("Vui lòng nhập giải thưởng");
    //     return;
    // }

    // let awards: any = this.props.item.awards;
    // awards.push({ name: this.state.awardText, value: this.state.awardText });
    // delete this.props.item.awards;
    // Object.assign(this.props.item, {
    //     ...this.props.item,
    //     awards
    // })
    // this.setState({ awardText: '' });
    this.setState({ awardItem: new Award(), showAwardModal: true })
  }

  openModalAward = (award: Award) => {
    this.setState({ showAwardModal: true, awardItem: award });

  }

  onAwardSave(award: Award) {
    award.value = award.name;
    let { item } = this.props;
    if (!award.id) item.awards.push(award);
    else {
      let index = item.awards.findIndex(item => item.id == award.id);
      if (index >= 0) item.awards[index] = award;
    }
    this.setState({ showAwardModal: false })
  }

  onPriceRangeSave(priceRange: PriceRange) {
    ;
    let { item } = this.props;
    if (!priceRange.id) item.priceRanges.push(priceRange);
    else {
      let index = item.priceRanges.findIndex(item => item.id == priceRange.id);
      if (index >= 0) item.priceRanges[index] = priceRange;
    }
    this.setState({ showPriceRangeModal: false })
  }


  onJoinConditionSave(joinCondition: JoinCondition) {

    let { item } = this.props;
    if (!joinCondition.campaignId) {
      joinCondition.campaignId = item.id;
    }
    if (!joinCondition.id) item.joinConditions.push(joinCondition);
    else {
      let index = item.joinConditions.findIndex(item => item.id == joinCondition.id);
      if (index >= 0) item.joinConditions[index] = joinCondition;
    }
    this.setState({ showJoinConditionModal: false })
  }

  transFilter = (inputValue, option) => {
    return option.title.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;
  }

  resetTabIndex() {
    this.setState({ tabIndex: "1" });
  }

  // handleChangeUser = userSelectedIds => {
  //     this.props.form.setFieldsValue({ userSelectedIds: userSelectedIds });
  // };
  handleChangeMall = mallSelectedIds => {
    this.props.form.setFieldsValue({ mallSelectedIds: mallSelectedIds });
  };

  handleSettingFieldFormChange(fieldCode, value, field) {
    let inputFields = this.props.item.inputFields
    let formField = inputFields.find(formField => formField.code == fieldCode);
    if (formField) {
      if (field == "maxLength") formField[field] = Number(value);
      else formField[field] = value;
    }
    if (formField.isShow && formField.isRequire) {
      formField.name = formField.name.replace(/\*/g, "").trim() + " *"
    } else {
      formField.name = formField.name.replace(/\*/g, "").trim();
    }
    if (!formField.isShow) {
      formField.isRequire = false;
    }

    this.setState({});

  }

  handleAddPriceRange() {
    this.setState({ showPriceRangeModal: true, priceRangeItem: new PriceRange() });
  }

  handleAddJoinCondition() {
    this.setState({ showJoinConditionModal: true, joinConditionItem: new JoinCondition(this.state.item.id) });
  }


  openModalPriceRange = (priceRangeItem: PriceRange) => {
    this.setState({ showPriceRangeModal: true, priceRangeItem });

  }

  openModalJoinCondition = (joinCondition: JoinCondition) => {
    this.setState({ showJoinConditionModal: true, joinConditionItem: joinCondition });

  }

  render() {
    const { getFieldDecorator } = this.props.form;
    let inputFields = this.props.item.inputFields.sort(formfieldService.compareFormField);
    let isCreateNew = !(this.props.item && this.props.item.id);
    let title = (!isCreateNew ? "Cập nhật chương trình" : "Tạo chương trình").toUpperCase();
    // let isActive = this.props.item.status;
    let { priceRanges, awards, joinConditions } = this.props.item;
    // let formFieldOptions = this.props.formFields.map(ff => <Option key={ff.id} value={ff.code} >{ff.name}</Option>);
    let listMall = this.props.malls.map(({ name: title, id }) => { return { key: id.toString(), title } });
    let listMallIdSelected = this.props.form.getFieldValue('mallSelectedIds');

    let listUser = this.props.users
      .filter(user => user.roles.filter(role => role.name == ROLES.Manager.name).length == 0)
      .map(({ fullname: title, id }) => { return { key: id.toString(), title } });
    let listUserIdSelected = this.props.form.getFieldValue('userIds');
    if (listUserIdSelected && listUserIdSelected.length > 0) listUserIdSelected = listUserIdSelected.map(item => item.toString())
    let gameSetting = this.props.item.gameSetting || new GameSetting();
    let loyaltyGameExchangeEnable = this.props.item.loyaltyGameExchangeEnable || false;

    let isCampaignGame = this.props.form.getFieldValue('isGame');
    let isLoyaltyGame = this.props.form.getFieldValue('isLoyaltyGame');
    let isGameCollectProfile = this.props.form.getFieldValue('gameCollectProfile');
    this.props.formFields.forEach(ff => {
      let found = this.props.item.inputFields.find(ip => ip.code === ff.code)
      if (!found) this.props.item.inputFields.push(new FormFieldSetting(ff, this.props.item.id))
    })
    const dateFormat = 'DD/MM/YYYY';

    return (
      <Modal width="95%" style={{ maxWidth: "96%", top: "20px" }}
        onOk={this.props.onCreate} footer={null}
        onCancel={() => this.props.onClose(this)}
        visible={this.props.visible}  >
        <div>
          <Form {...formItemLayout} onSubmit={this.handleSubmit.bind(this)}>
            <Card title={
              <div style={{ margin: "0px" }}>{title}
                <Button style={{ float: "right" }} type="primary" loading={this.state.loading} htmlType="submit">
                  {title}
                </Button></div>} bordered={true} style={{ margin: "10px", minHeight: "600px" }}>
              <Tabs defaultActiveKey="2"
                onTabClick={() => {
                  console.log({ contentEditor: this.contentEditor })
                  this.props.form.setFieldsValue({ description: this.contentEditor })
                }}
                activeKey={this.state.tabIndex} onChange={this.onTabActiveChange.bind(this)}>
                <TabPane tab={<span> <Icon type="info-circle" /> Thông tin chung </span>} key="1">
                  <Form.Item label="Tên chương trình">
                    {getFieldDecorator('name', {
                      rules: [
                        {
                          required: true,
                          message: 'Nhập tên chương trình',
                        },
                      ],
                    })(<Input />)}
                  </Form.Item>
                  <Form.Item label="Thời gian">
                    {getFieldDecorator('dateRange', {
                      rules: [
                        {
                          required: true,
                          message: 'Nhập thời gian',
                        },
                      ],
                    })(<RangePicker format={dateFormat} />)}
                  </Form.Item>;
                  <Form.Item label="Public">
                    {getFieldDecorator('status')(<Switch checked={this.props.form.getFieldValue('status')} />)}
                  </Form.Item>
                  <Form.Item label="Game Loyalty">
                    {getFieldDecorator('isLoyaltyGame')(<Switch checked={this.props.form.getFieldValue('isLoyaltyGame')} />)}
                  </Form.Item>
                  <Row>
                    <Col span={12}>
                      <Form.Item label="Campaign Game" labelCol={{ sm: 12 }} wrapperCol={{ sm: 12 }} >
                        {getFieldDecorator('isGame')(<Switch checked={this.props.form.getFieldValue('isGame')} />)}
                      </Form.Item>
                    </Col>
                    <Col span={12}> {
                      isCampaignGame ? <Form.Item label="Cho phép PG Update" labelCol={{ sm: 12 }} wrapperCol={{ sm: 12 }}>
                        {getFieldDecorator('bgEdit')(<Switch checked={this.props.form.getFieldValue('bgEdit')} />)}
                      </Form.Item> : ''
                    }
                    </Col>

                    <Col span={12}> {
                      isCampaignGame ? <Form.Item label="Nhập thông tin KH" labelCol={{ sm: 12 }} wrapperCol={{ sm: 12 }}>
                        {getFieldDecorator('gameCollectProfile')(<Switch checked={this.props.form.getFieldValue('gameCollectProfile')} />)}
                      </Form.Item> : ''
                    }
                    </Col>

                    <Col span={12}> {
                      isCampaignGame ? <Form.Item label="Nhận quà trên game" labelCol={{ sm: 12 }} wrapperCol={{ sm: 12 }}>
                        {getFieldDecorator('gameAwardReceiveInGame')(<Switch checked={this.props.form.getFieldValue('gameAwardReceiveInGame')} />)}
                      </Form.Item> : ''
                    }
                    </Col>
                    <Col span={12}>
                      {
                        isCampaignGame ? <Form.Item label="Loại game" labelCol={{ sm: 12 }} wrapperCol={{ sm: 12 }}>
                          {getFieldDecorator('gameType', {
                            rules: [
                              {
                                required: true,
                                message: 'Chọn loại game',
                              },
                            ],
                          })(<Select
                            mode="default"
                            style={{}}
                            placeholder="Chọn loại game"
                            onChange={(value) => { this.props.form.setFieldsValue({ gameType: value }) }}
                          >
                            {GAME_TYPES.map(({ key, title }) => { return (<Option key={key} value={key}>{title}</Option>) })}
                          </Select>)}
                        </Form.Item> : ''
                      }
                    </Col>
                    <Col span={12} style={{ display: isCampaignGame ? "inherit" : "none" }}> <Form.Item label="Nhập thông tin KH khi nhận thưởng" labelCol={{ sm: 12 }} wrapperCol={{ sm: 12 }}>
                      {getFieldDecorator('gameCollectProfileOnReceive')(<Switch checked={this.props.form.getFieldValue('gameCollectProfileOnReceive')} />)}
                    </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item label="Thể lệ và Mô tả">
                    {getFieldDecorator('description', {})
                    (<Editor content={this.contentEditor} onContentChanged={this.setContent.bind(this)} height="300px" />)} 
                  </Form.Item>
                </TabPane>
                <TabPane tab={<span> <Icon type="reconciliation" /> Giá trị hóa đơn </span>} key="2">
                  <PriceRangeForm
                    onCreate={this.onPriceRangeSave.bind(this)}
                    item={this.state.priceRangeItem}
                    visible={this.state.showPriceRangeModal}
                    onClose={() => { this.setState({ showPriceRangeModal: false }) }}
                  />
                  <Row >
                    <Col span={6} style={{ textAlign: "right", paddingTop: "5px", paddingRight: "10px" }}>Thêm giá trị hóa đơn </Col>
                    {/* <Col span={10}><Input onKeyDown={this.handleAddPriceRangeKeyDown.bind(this)} value={this.state.priceText} onChange={this.onPriceInputChange.bind(this)} style={{ width: "100%" }}></Input> </Col> */}
                    <Col span={4}><Button style={{ marginLeft: "10px" }} type="primary" onClick={this.handleAddPriceRange.bind(this)}>Thêm</Button></Col>
                  </Row>
                  <Row style={{ paddingTop: "15px" }}>
                    <Col span={4} ></Col>
                    <Col span={20} >
                      {
                        priceRanges.map((price, index) => {
                          return (
                            <Tag closable
                              onClose={(e) => {
                                this.handleDeletePriceRanges(price["name"]);
                                e.preventDefault();
                              }} key={index}
                            >
                              <a
                                style={{ color: "#1890ff", fontWeight: "bolder" }}
                                onClick={() => this.openModalPriceRange(price)}
                              >
                                {price["name"]}
                              </a>
                            </Tag>
                          )
                        })}
                    </Col>
                  </Row>
                </TabPane>

                <TabPane tab={<span> <Icon type="reconciliation" /> Giải thưởng</span>} key="3">
                  <AwardTabContent
                    campaignForm={this.props.form}
                    object={
                      {
                        formFields: this.props.formFields,
                        awardLimitEnable: this.props.form.getFieldValue('awardLimitEnable'),
                        awardLimitByField: this.props.form.getFieldValue('awardLimitByField'),
                        awardLimitPerDay: this.props.form.getFieldValue('awardLimitPerDay'),
                        awardLimitOnCampaign: this.props.form.getFieldValue('awardLimitOnCampaign'),
                        awards: this.props.item.awards,
                        campaign: this.props.item
                      }
                    } />
                </TabPane>
                {isLoyaltyGame && <TabPane tab={<span> <Icon type="reconciliation" /> Chương trình liên kết </span>} key="11">
                  <LoyaltyGameSettingTab
                    campaignId={this.props.item.id}
                    loyaltyGameExchangeEnable={loyaltyGameExchangeEnable}
                    setLoyaltyGameExchangeEnable={(checked) => {
                      let { item } = this.props;
                      item.loyaltyGameExchangeEnable = checked;
                    }}
                  />
                </TabPane>}
                <TabPane tab={<span> <Icon type="shop" /> Danh sách chi nhánh </span>} key="4">
                  <div style={{ display: "flex", textAlign: "center", alignItems: "center" }}>
                    {/* <Form.Item style={{ display: "none" }}>
                      {getFieldDecorator('mallSelectedIds')(<Input type="hidden" />)}
                    </Form.Item> */}
                    {getFieldDecorator('mallSelectedIds', { initialValue: listMallIdSelected })(
                      <Transfer style={{ flex: 1, width: "100%", height: "100%" }}
                        listStyle={{
                          width: "45%",
                          textAlign: "left",
                          height: 400,
                        }}
                        lazy={false}
                        dataSource={listMall}
                        showSearch
                        filterOption={this.transFilter}
                        targetKeys={listMallIdSelected}
                        // onChange={this.handleChangeMall}
                        render={item => item.title}

                      />)}
                  </div>
                </TabPane>
                <TabPane tab={<span> <Icon type="filter" /> Tuỳ chỉnh chương trình </span>} key="5">
                  <div>
                    <Divider orientation="left">Tuỳ chỉnh thông tin nhập</Divider>
                    <Table
                      showHeader={true}
                      pagination={false}
                      size="small"
                      dataSource={inputFields}
                      key="code"
                      rowKey="code"
                    >
                      <Column title="Tên trường" width={"200px"} dataIndex="name" render={(text, record) => { return text; }} ></Column>
                      <Column title="Hiển thị" width={"100px"} dataIndex="isShow" render={(text, record: FormFieldSetting) => {
                        return <Checkbox
                          checked={record.isShow}
                          onChange={(e) => { this.handleSettingFieldFormChange(record.code, e.target.checked, "isShow") }} />;
                      }} ></Column>
                      <Column title="Yêu cầu nhập" width={"150px"} dataIndex="isRequire" render={(text, record: FormFieldSetting) => {
                        return <Checkbox disabled={!record.isShow}
                          checked={record.isRequire}
                          onChange={(e) => { this.handleSettingFieldFormChange(record.code, e.target.checked, "isRequire") }} />;
                      }} ></Column>
                      <Column title="Độ dài" width={"150px"} dataIndex="maxLength" render={(text, record: FormFieldSetting) => {
                        let formField = this.props.formFields.filter(item => item.code == record.code)[0];
                        return !formField || !formField.checkLength ? "" :
                          <Input type='number' style={{ display: record.isShow ? "inherit" : "none" }}
                            defaultValue={formField.maxLength}
                            value={record.maxLength}
                            onChange={(e) => { this.handleSettingFieldFormChange(record.code, e.target.value, "maxLength") }} />;
                      }} ></Column>
                      <Column />
                    </Table>
                  </div>
                </TabPane>
                <TabPane tab={<span> <Icon type="reconciliation" /> Điều kiện tham gia </span>} key="6">
                  <JoinConditionForm
                    onCreate={this.onJoinConditionSave.bind(this)}
                    item={this.state.joinConditionItem}
                    visible={this.state.showJoinConditionModal}
                    onClose={() => { this.setState({ showJoinConditionModal: false }) }}
                  />
                  <Row >
                    <Col span={6} style={{ textAlign: "right", paddingTop: "5px", paddingRight: "10px" }}>Thêm điều kiện tham gia </Col>
                    {/* <Col span={10}><Input onKeyDown={this.handleAddPriceRangeKeyDown.bind(this)} value={this.state.priceText} onChange={this.onPriceInputChange.bind(this)} style={{ width: "100%" }}></Input> </Col> */}
                    <Col span={4}><Button style={{ marginLeft: "10px" }} type="primary" onClick={this.handleAddJoinCondition.bind(this)}>Thêm</Button></Col>
                  </Row>
                  <Row style={{ paddingTop: "15px" }}>
                    <Col span={4} ></Col>
                    <Col span={20} >
                      {
                        joinConditions.map((item, index) => {
                          return (
                            <Tag closable
                              color="#009688"
                              style={{ fontSize: "12px", margin: "5px" }}
                              onClose={(e) => {
                                this.handleDeleteJoinCondition(item["content"]);
                                e.preventDefault();
                              }} key={index}
                            >
                              <a
                                style={{ color: "#FFF", lineHeight: "25px", fontWeight: "bolder" }}
                                onClick={() => this.openModalJoinCondition(item)}
                              >
                                {item["content"]}
                              </a>
                            </Tag>
                          )
                        })}
                    </Col>
                  </Row>
                </TabPane>
                {/* <TabPane style={{display: "none"}} tab={<span> <Icon type="user" /> Danh sách User </span>} key="7">
                  <div style={{ display: "flex", textAlign: "center", alignItems: "center" }}>
                    {getFieldDecorator('userIds')(
                      <Transfer style={{ flex: 1, width: "100%", height: "100%" }}
                        listStyle={{
                          width: "45%",
                          textAlign: "left",
                          height: 400,
                        }}
                        lazy={false}
                        dataSource={listUser}
                        showSearch
                        filterOption={this.transFilter}
                        targetKeys={listUserIdSelected}
                        // onChange={this.handleChangeMall}
                        render={item => item.title}

                      />)}
                  </div>
                </TabPane> */}
                <TabPane tab={<span> <Icon type="reconciliation" /> Cấu hình game </span>} disabled={!isCampaignGame} key="8">
                  <GameSettingTab
                    gameSetting={gameSetting}
                    setGameSetting={(gameSetting) => {
                      let { item } = this.props;
                      // console.log('new GameSetting', gameSetting);
                      item.gameSetting = { ...gameSetting };

                    }}
                  ></GameSettingTab>
                </TabPane>
                <TabPane tab={<span> <Icon type="reconciliation" /> Thông tin game </span>} disabled={!isCampaignGame} key="9">
                  <GameInfoTab
                    code={this.props.item.code}
                    name={this.props.item.name}
                    gameType={this.props.item.gameType}
                  ></GameInfoTab>
                </TabPane>
                <TabPane tab={<span> <Icon type="reconciliation" /> Backup resource </span>} key="12">
                  <CampaignResourceBackupTab
                    code={this.props.item.code}
                    id={this.props.item.id}
                    toDate={this.props.item.toDate}
                  ></CampaignResourceBackupTab>
                </TabPane>
              </Tabs>
            </Card>
          </Form>
        </div >
      </Modal >
    );
  }
}

const CampaignForm = Form.create<IFormModalProps>({
  onFieldsChange(props, changedFields) {
    // props.onChange(changedFields);
  },
  mapPropsToFields(props) {
    const { item } = props;
    const result = {};
    Object.keys(item).forEach(key => {
      const field = Form.createFormField({
        value: item[key]
      })
      Object.assign(result, {
        [key]: field
      })
    })
    return result;
  },
  onValuesChange(_, values) {
  }
})(CampaignFormModal);

export default CampaignForm;