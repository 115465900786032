import { Button, Card, Checkbox, DatePicker, Form, Input, Modal } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import React from 'react';
import { AppVersionSetting } from '~/services/version-setting/version-setting.model';


export interface IFormModalProps extends FormComponentProps {
    visible: boolean;
    // closeCallback: () => void;
    onCancel: () => void;
    onCreate: () => void;
    appVersionSetting: AppVersionSetting;
}

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};

class AppVersionSettingFormModal extends React.Component<IFormModalProps> {
    formRef: any;
    state = {
        loading: false,
        appVersionSetting: new AppVersionSetting()
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (err) return;
            this.props.onCreate();
            //this.props.closeCallback();
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        let isCreateNew = !(this.props.appVersionSetting && this.props.appVersionSetting.id);
        let title = (!isCreateNew ? "Cập nhật phiên bản" : "Tạo phiên bản").toUpperCase();
        return (
            <Modal width="800px" onOk={this.props.onCreate} footer={null} onCancel={this.props.onCancel} visible={this.props.visible}  >
                <div >
                    <Card title={title} bordered={true} style={{ margin: "20px" }}>
                        <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                            <Form.Item label="Tên phiên bản">
                                {getFieldDecorator('appVersionName', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Nhập tên phiên bản',
                                        },
                                    ],
                                })(<Input />)}
                            </Form.Item>
                            <Form.Item label="Số phiên bản">
                                {getFieldDecorator('appVersionNumber', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Nhập tên phiên bản',
                                        },
                                    ],
                                })(<Input />)}
                            </Form.Item>
                            <Form.Item label="Bắt buộc update">
                                {getFieldDecorator('forceUpdateFlag', { })(<Checkbox />)}
                            </Form.Item>
                            <Form.Item label="Nội dung phát hành">
                                {getFieldDecorator('releaseNote', {})(<Input autoComplete="off" />)}
                            </Form.Item>
                            <Form.Item label="Ngày phát hành">
                                {getFieldDecorator('releaseDate')(<DatePicker showTime format="DD-MM-YYYY HH:mm" />)}
                            </Form.Item>

                            <Form.Item {...tailFormItemLayout}>
                                <Button type="primary" htmlType="submit">
                                    {title}
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </div >
            </Modal>
        );
    }
}

const AppVersionSettingForm = Form.create<IFormModalProps>({
    onFieldsChange(props, changedFields) {
        // props.onChange(changedFields);
    },
    mapPropsToFields(props) {
        const { appVersionSetting } = props;
        const result = {};
        Object.keys(appVersionSetting).forEach(key => {
            const field = Form.createFormField({
                value: appVersionSetting[key]
            })
            Object.assign(result, {
                [key]: field
            })
        })
        return result;
    },
    onValuesChange(_, values) {
    }
})(AppVersionSettingFormModal);
export default AppVersionSettingForm;