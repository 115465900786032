import { Button, Card, Form, Input, Modal, Select, Icon } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import React from 'react';
import { toast } from 'react-toastify';
import userService from '~/services/user/user.service';
import { HttpStatus } from '~/common/enums/const';
import Authenticate from '~/services/authenticate/authenticate';
import history from '~/common/utils/browser-history';

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};

export interface IFormModalProps extends FormComponentProps {
    visible: boolean;
    onClose: () => void;
}

class ChangePassFormModal extends React.Component<IFormModalProps> {
    formRef: any;
    state = {
        loading: false
    }
    handleSubmit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (err) return;
            if(values.newPassword != values.confirmNewPassword){
                toast.error("Mật khẩu và xác nhận mật khẩu không giống nhau");
                return;
            }
            userService.changePassword(values).then(result => {
                if(result.code == HttpStatus.OK){
                    toast.success("Đổi mật khẩu thành công");
                    this.props.onClose();
                    setTimeout(()=>{
                        Authenticate.logout();
                        history.push("login");
                    },300)
                }
                else{
                    toast.info(result.message);
                }
            })
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        return (<Modal bodyStyle={{ padding: "5px" }} width="400px" footer={null} closable={false} visible={this.props.visible}  >
            <Card title="ĐĂNG NHẬP HỆ THỐNG" bordered={true}>
                <Form onSubmit={this.handleSubmit.bind(this)} className="login-form">
                    <Form.Item>
                        {getFieldDecorator('password', {
                            rules: [
                                { required: true, message: 'Vui lòng nhập mật khẩu' },
                                { min: 6, message: 'Mật khẩu tối thiểu 6 ký tự' },
                            ],
                        })(
                            <Input
                                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                type="password"
                                placeholder="Mật khẩu hiện tại"
                            />,
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('newPassword', {
                            rules: [
                                { required: true, message: 'Vui lòng nhập mật khẩu mới' },
                                { min: 6, message: 'Mật khẩu tối thiểu 6 ký tự' },
                            ],
                        })(
                            <Input
                                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                type="password"
                                placeholder="Mật khẩu mới"
                            />,
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('confirmNewPassword', {
                            rules: [
                                { required: true, message: 'Vui lòng nhập xác nhận mật khẩu' },
                                { min: 6, message: 'Mật khẩu tối thiểu 6 ký tự' },
                            ],
                        })(
                            <Input
                                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                type="password"
                                placeholder="Xát nhận mật khẩu"
                            />,
                        )}
                    </Form.Item>
                    <Form.Item style={{ textAlign: "center" }}>
                        <Button style={{ width: "40%", marginRight: "20%" }} type="default" onClick={this.props.onClose}
                            loading={this.state.loading}
                            className="login-form-button"> Hủy </Button>
                        <Button style={{ width: "40%" }} type="primary" htmlType="submit"
                            loading={this.state.loading}
                            className="login-form-button"> Đổi mật khẩu </Button>
                    </Form.Item>
                </Form>
            </Card>

        </Modal>)
    }
}


const ChangePassForm = Form.create<IFormModalProps>({
    name: "form"
})(ChangePassFormModal);
export default ChangePassForm;