import { Button, Icon, Input, Popconfirm, Table, Tooltip } from 'antd';
import Column from 'antd/lib/table/Column';
import React from 'react';
import Highlighter from 'react-highlight-words';
import { toast } from 'react-toastify';
import { HttpStatus } from '~/common/enums/const';
import { User } from '~/services/user/user.model';

import { AppParams } from '~/services/app-params/app-params.model';
import appParamsService from '~/services/app-params/app-params.service';
import AppParamsForm from './app-params.modal';

export default class AppParamsPage extends React.Component<{ history: any }, {}> {
    formRef: any;
    state = {
        tableLoading: true,
        data: [],
        formInfo: {
            item: new AppParams(),
            isModalShow: false
        },
        filteredInfo: {
            searchedColumn: '',
            searchText: '',
            name: [],
            description: []
        }
    }
    searchInput: Input;

    handleClearSearch() {
        this.setState({
            filteredInfo: {
                searchedColumn: '',
                searchText: '',
                name: [],
                description: []
            }
        });
    }

    openModalDetail(item) {
        this.setState({
            formInfo: {
                item: item,
                isModalShow: true

            }
        })
    }
    createItem() {
        this.setState({
            formInfo: {
                isModalShow: true,
                item: new User()
            }
        })
    }

    componentWillMount() {
        this.loadListData();

    }

    loadListData() {
        appParamsService.getData().then(datas => {
            let data = datas.map(item => { item.key = item.id; return item; })
            this.setState({ data, tableLoading: false });
        })
    }

    saveFormRef = (formRef: any) => {
        this.formRef = formRef;
    };

    onChange(pagination, filters, sorter, extra) {
        this.setState({ filteredInfo: filters })
    }

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    updateUserInList(user: User) {
        let users = this.state.data.map(item => {
            if (item.id === user.id) return user;
            return item;
        })
        users = users.map(item => { item.key = item.id; return item; });
        this.setState({ users });
    }

    handleSave() {
        this.formRef.validateFieldsAndScroll(async (err, values) => {
            let appParams = values;
            if (this.state.formInfo.item.id) appParams.id = this.state.formInfo.item.id;
            appParamsService.update(appParams).then(result => {
                if (result.code === HttpStatus.OK) {
                    this.loadListData();
                    if (appParams.id) {

                        toast.success("Cập nhật tham số thành công");
                    } else {
                        toast.success("Tạo tham số thành công");
                    }
                    setTimeout(() => {
                        this.setState({
                            formInfo: { item: new User(), isUserModalShow: false }
                        })
                    }, 200);
                }
                else {
                    toast.info(result.message);
                }
            }).catch(error => {

            })
        })

    }

    render() {
        const { state } = this;
        let filteredInfo = this.state.filteredInfo;
        return (
            <div className="content">
                <div className="row"> <h2 style={{ textAlign: "center", textTransform: "uppercase" }}>Danh sách tham số</h2> </div>
                <br />
                <div style={{ overflowX: "scroll" }}>
                    <Table
                        showHeader={true}
                        size="middle"
                        style={{ }}
                        onChange={this.onChange.bind(this)}
                        loading={this.state.tableLoading}
                        //expandedRowRender={record => <p style={{ margin: 0 }}>{record.description}</p>}
                        dataSource={this.state.data}
                    >
                        <Column title="ID" width={"50px"} dataIndex="id" key="id" sortOrder="descend" defaultSortOrder="descend" ></Column>
                        <Column title="Group" dataIndex="group" key="group" ></Column>
                        <Column title="Code" dataIndex="code" key="code" ></Column>
                        <Column title="Tên" dataIndex="name" key="name" ></Column>
                        <Column title="Giá trị" dataIndex="value" key="value" ></Column>

                        <Column title="" dataIndex="action" key="action" render={(text, record) => (
                            <div>
                                <Tooltip placement="top" title="Thông tin chi tiết">
                                    <Button style={{ marginRight: "10px" }} onClick={(e) => { this.openModalDetail(record) }} size="small" type="default"><Icon type="edit" /></Button>
                                </Tooltip>
                            </div>
                        )} >
                        </Column>
                    </Table>
                </div>
                <AppParamsForm
                    // wrappedComponentRef={this.saveFormRef}
                    ref={this.saveFormRef}
                    visible={this.state.formInfo.isModalShow}
                    onCancel={() =>
                        this.setState({
                            formInfo: { item: new User(), isUserModalShow: false }
                        })
                    }
                    onCreate={() => { this.handleSave() }}
                    appParams={this.state.formInfo.item}
                ></AppParamsForm>
            </div>
        );
    }



    getColumnSearchProps = (dataIndex, displayText = "") => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${displayText != "" ? displayText : dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
            </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
            </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text => (
            (this.state.filteredInfo.searchedColumn === dataIndex) ?
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.filteredInfo.searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
                : text
        ),
    });
}
