import { Col, Divider, Form, Switch } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import React, { useCallback, useEffect, useState } from 'react';
import { AreaSetting, GameSetting } from '~/services/models/game-setting';
import { SketchPicker, ChromePicker } from 'react-color';
import { ButtonColorPicker } from '~/components/button-color-picker';
import UploadFile from '~/components/upload-file';
import { Row } from 'antd/es/grid';

// import { ColorPickerComponent } from '@syncfusion/ej2-react-inputs';
export interface IGameSettingTabProps {
    gameSetting: GameSetting;
    setGameSetting: (gameSetting: GameSetting) => void
}
const formItemLayout = {
    labelCol: {
        // xs: { span: 24 },
        sm: { span: 12 },
        lg: { span: 12 },
    },
    wrapperCol: {
        // xs: { span: 24 },
        sm: { span: 12 },
        lg: { span: 12 },
    },
};

// gameBackground = "";
// resultBackground = "";
// gameBackgroundColor = "";
// resultBackgroundColor = "";

// areaSettings: AreaSetting[];
// }

export const GameSettingTab = (props: IGameSettingTabProps) => {

    let [gameSetting, setGameSetting] = useState(new GameSetting());
    // const [fullUrl, setFullUrl] = useState('');
    const mergeAreaSettings = (gameSetting: GameSetting): GameSetting => {
        let maxItem = 7;
        if (!gameSetting.areaSettings) gameSetting.areaSettings = [];
        if (gameSetting.areaSettings.length == maxItem) return gameSetting;

        let areaSetting = [];
        for (let i = 0; i < maxItem; i++) {
            let area = gameSetting.areaSettings.find(item => item.index == i);
            if (!area) {
                area = new AreaSetting(i);
            }
            areaSetting.push(area);
        }
        gameSetting.areaSettings = areaSetting;
        return gameSetting;
    }

    let objectClone = (object) => {
        return Object.assign({}, object);
    }

    useEffect(() => {
        let gameSetting = props.gameSetting;
        gameSetting = mergeAreaSettings(gameSetting)
        setGameSetting(gameSetting);
        props.setGameSetting(gameSetting);
    }, [props.gameSetting]);

    const gameSettingUI = useCallback(() => {

        return <div>
            <Row>
                <Col span={8}>Màu nền màn hình game: </Col>
                <Col span={16}>
                    <ButtonColorPicker
                        color={gameSetting.gameBackgroundColor || '#FFF'}
                        onColorChanged={newColor => {
                            gameSetting = mergeAreaSettings(gameSetting);
                            gameSetting.gameBackgroundColor = newColor;
                            // console.log("gameSetting22", gameSetting);
                            setGameSetting(gameSetting);
                            props.setGameSetting(gameSetting);
                        }} />
                </Col>
            </Row>
            <Row>
                <Col span={8}>Màu nền màn hình kết quả: </Col>
                <Col span={16}>
                    <ButtonColorPicker
                        color={gameSetting.resultBackgroundColor || '#FFF'}
                        onColorChanged={newColor => {
                            gameSetting = mergeAreaSettings(gameSetting);
                            gameSetting.resultBackgroundColor = newColor;
                            setGameSetting(gameSetting);
                            props.setGameSetting(gameSetting);
                        }} />
                </Col>
            </Row>
            <Row>
                <Col span={8}>Màu nền màn hình nhập liệu: </Col>
                <Col span={16}>
                    <ButtonColorPicker
                        color={gameSetting.formRegisterBackgroundColor || '#FFF'}
                        onColorChanged={newColor => {
                            gameSetting = mergeAreaSettings(gameSetting);
                            gameSetting.formRegisterBackgroundColor = newColor;
                            setGameSetting(gameSetting);
                            props.setGameSetting(gameSetting);
                        }} />
                </Col>
            </Row>
            <Row>
                <Col span={8}>Màu chữ thông tin trúng thưởng: </Col>
                <Col span={16}>
                    <ButtonColorPicker
                        color={gameSetting.resultInfoTextColor || '#FFF'}
                        onColorChanged={newColor => {
                            gameSetting = mergeAreaSettings(gameSetting);
                            gameSetting.resultInfoTextColor = newColor;
                            setGameSetting(gameSetting);
                            props.setGameSetting(gameSetting);
                        }} />
                </Col>
            </Row>

            <Row>
                <Col span={6}>
                    <p>Hình nền màn hình thể lệ</p>
                    <UploadFile showUploadList={false} multiple={false} uploadCallback={(fullUrl) => {
                        gameSetting = mergeAreaSettings(gameSetting);
                        gameSetting.welcomeBackground = fullUrl;
                        setGameSetting(objectClone(gameSetting));
                        props.setGameSetting(gameSetting);
                    }} ></UploadFile>
                    <img src={gameSetting.welcomeBackground} alt="" width={100} height={100} />
                </Col>
                <Col span={6}>
                    <p>Hình nền màn hình nhập liệu</p>
                    <UploadFile showUploadList={false} multiple={false} uploadCallback={(fullUrl) => {
                        gameSetting = mergeAreaSettings(gameSetting);
                        gameSetting.formRegisterBackground = fullUrl;
                        setGameSetting(objectClone(gameSetting));
                        props.setGameSetting(gameSetting);
                    }} ></UploadFile>
                    <img src={gameSetting.formRegisterBackground} alt="" width={100} height={100} />
                </Col>
                <Col span={6}>
                    <p>Hình nền màn hình game</p>
                    <UploadFile showUploadList={false} multiple={false} uploadCallback={(fullUrl) => {
                        gameSetting = mergeAreaSettings(gameSetting);
                        gameSetting.gameBackground = fullUrl;
                        setGameSetting(objectClone(gameSetting));
                        props.setGameSetting(gameSetting);
                    }} ></UploadFile>
                    <img src={gameSetting.gameBackground} alt="" width={100} height={100} />
                </Col>
                <Col span={6}>
                    <p>Hình nền màn hình game</p>
                    <UploadFile showUploadList={false} multiple={false} uploadCallback={(fullUrl) => {
                        gameSetting = mergeAreaSettings(gameSetting);
                        gameSetting.resultBackground = fullUrl;
                        setGameSetting(objectClone(gameSetting));
                        props.setGameSetting(gameSetting);
                    }} ></UploadFile>
                    <img src={gameSetting.resultBackground} alt="" width={100} height={100} />
                </Col>

            </Row >
        </div>
    }, [props.gameSetting, gameSetting]);

    const gameDisplaySettingUI = useCallback(() => {

        return <div>
            <Row>
                <Col span={12}>
                    <label> Hiển thị popup Điều kiện tham gia: 
                        <Switch checked={gameSetting.showConditionPopup} onChange={(checked) => {
                            gameSetting.showConditionPopup = checked;
                            setGameSetting(objectClone(gameSetting));
                            props.setGameSetting(objectClone(gameSetting));
                        }}  />
                </label>

                </Col>
                <Col span={12}>
                    <label> Hiện thị thông  tin trúng thưởng: 
                        <Switch checked={gameSetting.showResultInfo} onChange={(checked) => {
                            gameSetting.showResultInfo = checked;
                            setGameSetting(objectClone(gameSetting));
                            props.setGameSetting(objectClone(gameSetting));
                        }}  />
                </label>

                </Col>
            </Row>

        </div>
    }, [props.gameSetting, gameSetting]);

    const changeAreaSetting = (index: number, field: string, value: any) => {
        gameSetting.areaSettings[index][field] = value;
        console.log(index, field, value, gameSetting.areaSettings[index]);
        setGameSetting(objectClone(gameSetting));
    }

    const renderAreaSetting = useCallback(() => {
        let areaItem = gameSetting.areaSettings.map(area =>
            <Col key={"col" + area.code} span={8} style={{ border: "solid 1px #ccc", padding: "10px" }}>
                <span key={"span" + area.code}> {area.name} </span> <br />
                <Switch key={"sw" + area.code} checkedChildren="Sử dụng" unCheckedChildren="Không sử dụng" checked={area.enable}
                    onChange={(checked) => {
                        changeAreaSetting(area.index, 'enable', checked);
                    }} />
                <div style={{ padding: "10px 0px" }}>
                    <UploadFile key={"upl" + area.code} showUploadList={false} multiple={false} uploadCallback={(fullUrl) => {
                        changeAreaSetting(area.index, 'image', fullUrl);
                    }} ></UploadFile>
                </div>
                <img key={"img" + area.code} src={area.enable ? area.image : ''} alt="" width={100} height={100} />

            </Col>);
        return <Row>{areaItem} </Row>
    }, [gameSetting])

    return (<div style={{ minHeight: "400px" }}>
        <Divider orientation="left">Tùy chỉnh hiển thị</Divider>
        {gameDisplaySettingUI()}
        <Divider orientation="left">Màn hình game</Divider>
        {gameSettingUI()}
        <Divider orientation="left">Các vị trí click</Divider>
        {renderAreaSetting()}
    </div >)
}
