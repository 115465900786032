import { Button, Icon, Input, Popconfirm, Table, Tag, Tooltip } from 'antd';
import Column from 'antd/lib/table/Column';
import React from 'react';
import Highlighter from 'react-highlight-words';
import { toast } from 'react-toastify';
import { HttpStatus, ROLES } from '~/common/enums/const';
import { User } from '~/services/user/user.model';
import userService from '~/services/user/user.service';
import mallService from '~/services/mall/mall.service';

import UserForm from './user.modal';
import cloneDeep from 'lodash/cloneDeep';

interface IActiveBtnProps {
    isActive: any;
    activeFn: Function;
    inActiveFn: Function;
    userId: any;

}

function ActiveBtn(props: IActiveBtnProps) {

    if (props.isActive) {
        return <Tooltip placement="leftTop" title="Khóa tài khoản">
            <Popconfirm title="Bạn có chắc chắn muốn khóa tài khoản?" onConfirm={() => { props.inActiveFn(props.userId) }}>
                <Button data-user-id={props.userId} style={{ cursor: "pointer" }} size="small" type="danger"><Icon style={{ cursor: "pointer" }} type="lock" /></Button>
            </Popconfirm>
        </Tooltip>
    }
    else {
        return <Tooltip placement="leftTop" title="Mở khóa tài khoản">
            <Popconfirm title="Bạn có chắc chắn muốn mở khóa tài khoản?" onConfirm={() => { props.activeFn(props.userId) }}>
                <Button style={{ cursor: "pointer" }} size="small" type="primary"><Icon type="unlock" /></Button>
            </Popconfirm>
        </Tooltip>
    }
}


export default class UserPage extends React.Component<{ history: any }, {}> {
    formRef: any;
    state = {
        tableLoading: true,
        users: [],
        malls: [],
        userFormInfo: {
            userEdit: new User(),
            isUserModalShow: false
        },
        filteredInfo: {
            searchedColumn: '',
            searchText: '',
            status:[],
            username:[],
            fullname:[],
            phone:[],
            email:[],
            roles:[]
        }
    }
    searchInput: Input;

    handleClearSearch() {
        this.setState({filteredInfo:{
            searchedColumn: '',
            searchText: '',
            status:[],
            username:'',
            fullname:'',
            phone:'',
            email:'',
            roles:[]
        }});
    }

    inactiveUser(userId) {
        let index = this.state.users.findIndex((item) => item.id === userId);
        if (index >= 0) {
            let users = this.state.users;
            let user = users[index];
            userService.inActive(user.id).then(result => {
                if (!result) { toast.info("Khóa tài khoàn không thành công, vui lòng thử lại") }
                user.isActive = false;
                users[index] = user;
                this.setState({ users });
            })

        }
    }

    activeUser(userId: number) {
        let index = this.state.users.findIndex((item) => item.id === userId);
        if (index >= 0) {
            let users = this.state.users;
            let user = users[index];
            userService.active(user.id).then(result => {
                if (!result) { toast.info("Mở khóa tài khoàn không thành công, vui lòng thử lại") }
                user.isActive = true;
                users[index] = user;
                this.setState({ users });
            })
                .catch(error => {
                })
        }
    }

    openUserDetail(user: User) {
        userService.getUserPermission(user.id).then(perms => {
            user.permissions = perms as any;
            this.setState({
                userFormInfo: {
                    userEdit: user,
                    isUserModalShow: true
    
                }
            })
        })
        
    }
    createNewUser() {
        this.setState({
            userFormInfo: {
                isUserModalShow: true,
                userEdit: new User()
            }
        })
    }

    componentWillMount() {
        this.loadListUser();
        this.loadListMall();

    }

    loadListUser() {
        userService.getAllUser().then(users => {
            users = users.map(item => { item.key = item.id; return item; })
            this.setState({ users, tableLoading: false });
        })
    }
    loadListMall() {
        mallService.getData().then(malls => {
            malls = malls.map(item => { item.key = item.id; return item; })
            this.setState({ malls, tableLoading: false });
        })
    }

    saveFormRef = (formRef: any) => {
        this.formRef = formRef;
    };

    onChange(pagination, filters, sorter, extra) {
        this.setState({ filteredInfo: filters })
    }

    getColumnSearchProps = (dataIndex, displayText = "") => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${displayText !== "" ? displayText : dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
            </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
            </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text => (
            (this.state.filteredInfo.searchedColumn === dataIndex) ?
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.filteredInfo.searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
                : text
        ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    updateUserInList(user: User) {
        let users = this.state.users.map(item => {
            if (item.id === user.id) return user;
            return item;
        })
        users = users.map(item => { item.key = item.id; return item; });
        this.setState({ users });
    }

    handleSaveUser(userInfo) {
        let user = cloneDeep(userInfo);
        if (user.password !== user.confirmPassword) {
            toast.error("Xác thực mật khẩu không đúng");
            return;
        }

        user.roles = [ROLES.Operation];
        if (this.state.userFormInfo.userEdit.id) user.id = this.state.userFormInfo.userEdit.id;
        if (user.isManager) user.roles = [ROLES.Manager];
        user.malls = this.state.malls.filter(mall => userInfo.malls.indexOf(mall.id.toString()) >= 0);
        userService.save(user).then(result => {
            if (result.code === HttpStatus.OK) {
                if (user.id) {
                    user.isActive = this.state.userFormInfo.userEdit.isActive;
                    this.updateUserInList(user);
                    toast.success("Cập nhật tài khoản thành công");
                } else {
                    this.loadListUser();
                    toast.success("Tạo tài khoản thành công");
                }
                this.setState({
                    userFormInfo: { userEdit: new User(), isUserModalShow: false }
                })
            }
            else {
                toast.info(result.message);
            }


        }).catch(error => {

        })

    }

    render() {

        const { state } = this;
        let filteredInfo = this.state.filteredInfo;
        return (
            <div className="content">
                <div className="row"> <h2 style={{ textAlign: "center", textTransform:"uppercase" }}>Danh sách tài khoản</h2> </div>
                <div className="row">
                    <Button onClick={(e) => { this.createNewUser(); }} type="primary"> <Icon type="user-add" />Thêm tài khoản </Button>
                    <Button style={{float:"right"}} onClick={(e) => { this.handleClearSearch(); }} type="primary"> <Icon type="close-square" /> Xóa điều kiện filter </Button>
                </div>
                <br />
                <div style={{ width: "100%", overflowX: "scroll" }}>
                    <Table
                        showHeader={true}
                        size="small"
                        style={{ minWidth: "1000px" }}
                        onChange={this.onChange.bind(this)}
                        loading={this.state.tableLoading}
                        expandedRowRender={record => record["malls"].map(mall => {
                            return (<Tag key={mall.id} >{mall.name}</Tag>);
                        })}
                        dataSource={this.state.users}
                    >
                        <Column title="ID" width={"50px"} dataIndex="id" key="id" sortOrder="descend" defaultSortOrder="descend" ></Column>
                        <Column title="Họ tên" dataIndex="fullname" key="fullname" filteredValue={filteredInfo.fullname} {...this.getColumnSearchProps('fullname', "Họ tên")}></Column>
                        <Column title="Tên đăng nhập" dataIndex="username" filteredValue={filteredInfo.username} key="username" {...this.getColumnSearchProps('username', "Tên đăng nhập")} ></Column>
                        <Column title="Số điện thoại" dataIndex="phone" key="phone" filteredValue={filteredInfo.phone} {...this.getColumnSearchProps('phone', "Số điện thoại")}></Column>
                        <Column title="Email" dataIndex="email" key="email" filteredValue={filteredInfo.email} {...this.getColumnSearchProps('email', "E-mail")}></Column>
                        <Column title="Trạng thái" dataIndex="isActive" key="isActive"
                            filters={[{ text: "Active", value: "Active" }, { text: "InActive", value: "InActive" },]}
                            filterMultiple={false}
                            filteredValue={filteredInfo.status}
                            onFilter={(value, record) => {
                                if (value === "Active") return record["isActive"];
                                return !record["isActive"];
                            }}
                            render={(text, record) => (<div>
                                {record["isActive"] && record["isActive"] ? <span style={{ color: "blue" }}>Active</span> : <span style={{ color: "red" }}>InActive</span>}
                            </div>)} ></Column>
                        <Column title="Nhóm người dùng" dataIndex="roles" key="roles"
                            filters={[{ text: "Admin", value: "manager" }, { text: "Operation", value: "operation" }]}
                            filteredValue={filteredInfo.roles}
                            onFilter={(value, record) => {
                                return record["roles"].find(item => item.name === value) !== undefined;
                            }}
                            render={(text, record) => (<div>
                                {record["roles"].map(element => {
                                    let color = "#87d068";
                                    if (element.name === ROLES.Manager.name) color = "#108ee9";
                                    //return element.name;
                                    return (<Tag key={element.id} color={color}>{element.name === ROLES.Manager.name ? "Admin": element.name}</Tag>);
                                })}
                            </div>)} ></Column>
                        <Column title="" dataIndex="action" key="action" render={(text, record) => (
                            <div>
                                <Tooltip placement="top" title="Chi tiết tài khoản">
                                    <Button style={{ marginRight: "10px" }} onClick={(e) => { this.openUserDetail(record as any) }} size="small" type="default"><Icon type="edit" /></Button>
                                </Tooltip>

                                <ActiveBtn
                                    isActive={record["isActive"]}
                                    activeFn={this.activeUser.bind(this)}
                                    inActiveFn={this.inactiveUser.bind(this)}
                                    userId={record["id"]} />
                            </div>
                        )} >
                        </Column>
                    </Table>
                </div>
                <UserForm
                    // wrappedComponentRef={this.saveFormRef}
                    ref={this.saveFormRef}
                    visible={this.state.userFormInfo.isUserModalShow}
                    onCancel={() =>
                        this.setState({
                            userFormInfo: { userEdit: new User(), isUserModalShow: false }
                        })
                    }
                    malls={this.state.malls}
                    onCreate={(user) => { this.handleSaveUser(user) }}
                    user={this.state.userFormInfo.userEdit}
                ></UserForm>
            </div>
        );
    }
}
