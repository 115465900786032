import { Button, Card, Icon, Input } from 'antd';
import React from 'react';
import privacyPolicyService from '~/services/privacy-policy/privacy-policy.service';
import PrivacyPolicyFormModal from './privacy-policy.modal';

interface IActiveBtnProps {
    is_deleted: any;
    activeFn: any,
    inActiveFn: any,
    itemId: number

}


export default class PrivacyPolicyPage extends React.Component<{ history: any }, {}> {
    formRef: any;
    state = {
        modalShow: false,
        content: '',

    }
    searchInput: Input;



    componentWillMount() {
        this.loadData();

    }

    loadData() {
        privacyPolicyService.getData().then(data => {
            this.setState({ content: data.content });
        })
    }

    closeModal() {
        this.setState({ modalShow: false })
    }

    privacyUpdated(content) {
        this.setState({ content, modalShow: false })
        this.closeModal()
    }

    showEditPrivacyForm() {
        this.setState({ modalShow: true })
    }

    render() {
        const { state } = this;
        return (
            <div className="content">
                <Card title={"CHÍNH SÁCH BẢO MẬT"} bordered={true}
                    extra={<Button onClick={(e) => { this.showEditPrivacyForm(); }} type="primary"> <Icon type="edit" /> Cập nhập chính sách </Button>}>
                    <div className="row" > <div dangerouslySetInnerHTML={{ __html: state.content }}></div> </div>
                </Card>
                {/* <div className="row"> <h2 style={{ textAlign: "center", textTransform: "uppercase" }}>CHÍNH SÁCH BẢO MẬT</h2> </div> */}
                {/* <div className="row">
                    <Button onClick={(e) => { this.showEditPrivacyForm(); }} type="primary"> <Icon type="user-add" /> Cập nhập chính sách </Button>
                </div> */}

                <PrivacyPolicyFormModal
                    // wrappedComponentRef={this.saveFormRef}
                    ref={this.formRef}
                    visible={this.state.modalShow}
                    onCancel={() => this.setState({ modalShow: false })}
                    onCreated={(data) => this.privacyUpdated(data.content) }

                    content={this.state.content}
                ></PrivacyPolicyFormModal>
            </div >

        );
    }
}
