import './index.css';

import { Button, Card, Col, Form, Icon, Input, Row } from 'antd';
import React from 'react';
import { toast } from 'react-toastify';
import { HttpStatus } from '~/common/enums/const';
import Authenticate from '~/services/authenticate/authenticate';
import { UserSession } from '~/services/models/user-session';

class NormalLoginForm extends React.Component<{ form: any, history: any }, { loading: boolean }> {

    state = {
        loading: false
    }
    // constructor() {
    //     this.state.loading = false; //({ loading: false });
    // }
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (err) return;
            try {
                this.setState({ loading: true });
                let data = await Authenticate.login(values);
                this.setState({ loading: false });
                if (data.code === HttpStatus.OK) {
                    let userSession = new UserSession();
                    Object.assign(userSession, data.data);
                    Authenticate.storeUserSession(data.data);
                    Authenticate.getUserSession();

                    setTimeout(() => {
                        Authenticate.getUserSession();
                        this.props.history.push("/");
                        window.location.reload();
                    }, 200)
                }
                else {
                    toast.info(data.message);
                }
                // if (!userlogin) {
                //     toast.error("Thông tin đang nhập không đúng, vui lòng thử lại");
                //     this.setState({ loading: false });
                //     return;
                // }


            } catch (error) {
                console.log(' login error', error);
                toast.error("Có lỗi xảy ra, vui lòng thử lại");
                this.setState({ loading: false });
            }

        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div >
                <Row type="flex" justify="center" gutter={{ lg: 32, xxl: 32 }}
                    style={{ position: "absolute", width: "100%", height: "100%", display: "flex", alignContent: "center", alignItems: "center" }} >
                    <Col xs={{ span: 20 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }} xxl={{ span: 6 }} >
                        <Card title="ĐĂNG NHẬP HỆ THỐNG" bordered={true}>
                            <Form onSubmit={this.handleSubmit} className="login-form">
                                <Form.Item>
                                    {getFieldDecorator('username', {
                                        rules: [{ required: true, message: 'Please input your username!' }],
                                    })(
                                        <Input
                                            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            placeholder="Username"
                                        />,
                                    )}
                                </Form.Item>
                                <Form.Item>
                                    {getFieldDecorator('password', {
                                        rules: [{ required: true, message: 'Please input your Password!' }],
                                    })(
                                        <Input
                                            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            type="password"
                                            placeholder="Password"
                                        />,
                                    )}
                                </Form.Item>
                                <Form.Item style={{ textAlign: "center" }}>
                                    <Button style={{ width: "50%" }} type="primary" htmlType="submit"
                                        loading={this.state.loading}
                                        className="login-form-button"> Log in </Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </div >

        );
    }
}

const LoginPage = Form.create({ name: 'normal_login' })(NormalLoginForm);
export default LoginPage;