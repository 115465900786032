import { Card, DatePicker, Form, Modal, Tabs, Select, Icon, Popover, Row, Col, Button, Input, Radio, Switch, Divider, Table, Checkbox } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { InputNumber } from 'antd';

import React, { useEffect, useState } from 'react';
import { Award, AWardType } from '~/services/models/award';
import Column from 'antd/lib/table/Column';
import { Campaign } from '~/services/campaign/campaign.model';
import { AwardMallSetting } from '~/services/models/award-mall-setting';
import { Mall } from '~/services/mall/mall.model';
import useGlobalContext from '~/services/global-context';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import UploadFile from '~/components/upload-file';
import minigameAwardReportService from '~/services/minigame/minigame-award-report.service';
import { MiniGameAwardReport } from '~/services/minigame/minigame-award-report.model';
import QRCode from 'react-qr-code';
import { AwardQrImage } from './award-qr-image';



export interface IFormModalProps extends FormComponentProps {
    visible: boolean;
    // closeCallback: () => void;
    onCreate: (Award) => void;
    item: Award;
    setItem: (item: Award) => void;
    campaign: Campaign;
    mallSelectedIds: string[],
    campaignForm: WrappedFormUtils<any>,
    onClose: (self: any) => void,
}


const formItemLayout = {
    labelCol: {
        // xs: { span: 24 },
        sm: { span: 8 },
        lg: { span: 8 },
    },
    wrapperCol: {
        // xs: { span: 24 },
        sm: { span: 16 },
        lg: { span: 16 },
    },
};

const AwardFormModal = (props: IFormModalProps) => {
    const [awardReports, setAwardReports] = useState<MiniGameAwardReport[]>([]);
    const [awardItem, setAwardItem] = useState(new Award());
    const { malls, setMalls } = useGlobalContext();
    const [isLoading, setIsLoading] = useState(false);
    // const [awardItem, setAwardItem] = useState(new Award());
    const handleSubmit = () => {
        props.form.validateFields(async (err, values) => {
            let newItem: Award = new Award;
            Object.assign(newItem, props.form.getFieldsValue(Object.getOwnPropertyNames(awardItem)));
            newItem.settings = awardItem.settings;
            newItem.awardLimitOnCampaign = !newItem.awardLimitPerDay;
            newItem.image = awardItem.image;
            props.onCreate(newItem);
        })

    };
    const refeshAwardItem = async () => {
        let mallSelectedIds = props.mallSelectedIds || []
        let newItem: Award = new Award;
        Object.assign(newItem, props.form.getFieldsValue(Object.getOwnPropertyNames(awardItem)));

        let mallsSelected = malls.filter(item => mallSelectedIds.includes(item.id.toString()))
        if (!newItem.settings) newItem.settings = [];
        mallsSelected.forEach(mall => {
            let wardMall = newItem.settings.find(i => i.mallId == mall.id);
            if (!wardMall) {
                wardMall = new AwardMallSetting();
                wardMall.awardId = newItem.id;
                wardMall.mallId = mall.id;
                newItem.settings.push(wardMall);
            }
        });
        // Remove record mall not in campaign
        newItem.settings = newItem.settings.filter(wm => mallsSelected.findIndex(m => m.id == wm.mallId) >= 0);
        if (newItem.id) {
            let awardReports = await minigameAwardReportService.getData(newItem.id);
            setAwardReports(awardReports);
        }
        setAwardItem(newItem);
    }

    const settingChange = (e: React.ChangeEvent<HTMLInputElement>, am: AwardMallSetting, field: string) => {
        let index = awardItem.settings.findIndex(item => item.mallId == am.mallId);
        if (index > -1) {
            awardItem.settings[index][field] = Number(e.target.value);
            setAwardItem({ ...awardItem });
        }

    }

    useEffect(() => {
        refeshAwardItem();
    }, [props.item, props.mallSelectedIds]);

    const { getFieldDecorator } = props.form;

    let showLimitField = 'inherit';
    let isGameCampaign = props.campaignForm.getFieldValue("isGame");
    let modalTitle = (!awardItem.id ? "Thêm" : "Cập nhật") + " giải thưởng";
    return (
        <Modal width="80%" style={{ maxWidth: "90%", top: "20px" }}
            onOk={props.onCreate} footer={null}
            onCancel={() => props.onClose(null)}
            visible={props.visible}  >
            <div >
                <Form {...formItemLayout} onSubmit={handleSubmit}  >
                    <Card title={
                        <div>{modalTitle} <Button style={{ float: "right" }} type="primary" onClick={handleSubmit} >{modalTitle}</Button> </div>
                    }>
                        <Row>
                            <Col span={18}>
                                {/* awardLimitNumber
                            awardLimitPerDay
                            awardLimitOnCampaign */}
                                <Form.Item style={{ marginBottom: "5px" }} label="Tên giải thưởng">
                                    {getFieldDecorator('name', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Tên giải thưởng',
                                            },
                                        ],
                                    })(<Input />)}
                                </Form.Item>
                                <Form.Item style={{ marginBottom: "5px", display: showLimitField }} label="Loại giải thưởng:">
                                    {getFieldDecorator('type')(
                                        <Radio.Group>
                                            <Radio value={AWardType.VOUCHER}>Voucher</Radio>
                                            <Radio value={AWardType.ITEM}>Hiện vật</Radio>
                                        </Radio.Group>)}
                                </Form.Item>
                                <Form.Item style={{ marginBottom: "5px", display: showLimitField }} label="Tem chương trinh loyalty:">
                                    {getFieldDecorator('isLoyaltyStamp')(
                                        <Checkbox checked={props.form.getFieldValue('isLoyaltyStamp')} />
                                    )}
                                </Form.Item>
                                <Form.Item style={{ marginBottom: "5px" }} label="Tiền tố" >
                                    {getFieldDecorator('prefix', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Tiền tố',
                                            },
                                            {

                                            }
                                        ],
                                    })(<Input />)}
                                </Form.Item>
                                <Form.Item label="Trạng thái hoạt động">
                                    {getFieldDecorator('status')(<Switch checked={props.form.getFieldValue('status')} />)}
                                </Form.Item>
                                <Form.Item style={{ marginBottom: "5px" }} label="Giới hạn trên campaign">
                                    {getFieldDecorator('awardLimitNumberInCampaign', {})(
                                        <InputNumber min={0} />
                                    )}
                                    <Popover content="0 - Không giới hạn cho quà tặng.Chỉ mới áp dụng cho game" title="Note">
                                        <Icon type="info-circle" style={{ width: "10%", color: "#03a9f4", paddingTop: "7px", float: "right" }} />
                                    </Popover>
                                </Form.Item>
                                <Card>
                                    <Row>
                                        <Col span={12}>
                                            <Form.Item style={{ marginBottom: "5px" }} label="Giới hạn(theo KH)">
                                                {getFieldDecorator('awardLimitNumber', {})(
                                                    <InputNumber min={0} max={100} />
                                                )}
                                                <Popover content="0 - Không giới hạn cho quà tặng" title="Note">
                                                    <Icon type="info-circle" style={{ width: "10%", color: "#03a9f4", paddingTop: "7px", float: "right" }} />
                                                </Popover>
                                            </Form.Item>
                                            <Form.Item style={{ marginBottom: "5px", display: showLimitField }} label="Giới hạn trên:">
                                                {getFieldDecorator('awardLimitPerDay')(
                                                    <Radio.Group>
                                                        <Radio value={true}>Ngày</Radio>
                                                        <Radio value={false}>Chương trình</Radio>
                                                    </Radio.Group>)}
                                            </Form.Item>
                                        </Col>

                                    </Row>
                                </Card>
                            </Col>
                            <Col span={6} style={{ padding: "10px", }}>
                                <Card title={"Hình thưởng"}>
                                    <UploadFile showUploadList={false} multiple={false} uploadCallback={(fullUrl) => {
                                        awardItem.image = fullUrl;
                                        setAwardItem({ ...awardItem })
                                    }} ></UploadFile>
                                    <img src={awardItem.image} alt="" width={100} height={100} />
                                </Card>
                                <Card>
                                    <AwardQrImage code={props.campaign.code + "-" + awardItem.id} name={awardItem.name} />
                                </Card>

                            </Col>
                        </Row>
                        <Row style={{ paddingTop: "15px", display: isGameCampaign ? 'inherit' : 'none' }}>
                            <Divider orientation="left">Setting theo cửa hàng</Divider>
                            <Table
                                showHeader={true}
                                pagination={false}
                                size="small"
                                dataSource={awardItem.settings}
                                key="mallId"
                                rowKey={(r, i) => 'row' + i.toString()}
                                scroll={{ x: true }}
                            >
                                <Column key={1} title="Cửa hàng" width={"200px"} dataIndex="name" render={(text, am: AwardMallSetting) => {
                                    return malls.find(m => m.id == am.mallId).name;
                                }} ></Column>
                                <Column key={2} align='center' title="Tổng quà hiển thị/CT" width={"100px"} dataIndex="type" render={(text, am: AwardMallSetting) => {
                                    let report = awardReports.find(item => item.mallId == am.mallId);
                                    return report ? report.totalCampaign || 0 : 0;
                                }} ></Column>
                                <Column key={3} align='center' title="Tổng quà hiển thị/ngày" width={"100px"} dataIndex="type" render={(text, am: AwardMallSetting) => {
                                    let report = awardReports.find(item => item.mallId == am.mallId);
                                    return report ? report.totalDay || 0 : 0;
                                }} ></Column>
                                <Column key={21} align='center' title="Tổng quà đã phát/CT" width={"100px"} dataIndex="type" render={(text, am: AwardMallSetting) => {
                                    let report = awardReports.find(item => item.mallId == am.mallId);
                                    return report ? report.totalCampaignReceived || 0 : 0;
                                }} ></Column>
                                <Column key={31} align='center' title="Tổng quà đã phát/ngày" width={"100px"} dataIndex="type" render={(text, am: AwardMallSetting) => {
                                    let report = awardReports.find(item => item.mallId == am.mallId);
                                    return report ? report.totalDayReceived || 0 : 0;
                                }} ></Column>
                                <Column key={4} align='center' title="KPI tổng chương trình" width={"100px"} dataIndex="type" render={(text, am: AwardMallSetting) => {
                                    return <Input type='number' min={0} value={am.total} onChange={(e) => { settingChange(e, am, 'total') }} ></Input>
                                }} ></Column>
                                <Column key={6} align='center' title="KPI mỗi ngày" width={"100px"} dataIndex="type" render={(text, am: AwardMallSetting) => {
                                    return <Input type='number' min={0} value={am.totalPerDay} onChange={(e) => { settingChange(e, am, 'totalPerDay') }} ></Input>
                                }} ></Column>
                                <Column key={5} align='center' title="Tỷ lệ trúng(%)" width={"100px"} dataIndex="type" render={(text, am: AwardMallSetting) => {
                                    return <Input type='number' min={0} value={am.rate} onChange={(e) => { settingChange(e, am, 'rate') }}></Input>
                                }} ></Column>


                            </Table>
                        </Row>
                    </Card>
                </Form>
            </div >
        </Modal >
    );
}

const AwardForm = Form.create<IFormModalProps>({
    onFieldsChange() {
        // props.onChange(changedFields);
    },
    mapPropsToFields(props) {
        const { item } = props;
        if (item.status !== false) item.status = true;
        const result = {};
        Object.keys(item).forEach(key => {
            const field = Form.createFormField({
                value: item[key]
            })
            Object.assign(result, {
                [key]: field
            })
        })
        return result;
    },
    onValuesChange(_) {
    }
})(AwardFormModal);

export default AwardForm;